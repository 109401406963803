import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from '../../AxiosConfig'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useSwipeable } from 'react-swipeable'
import CityChips from './Comps/CityChips'
import CreateProfileModal from '../layout/Sidebar/CreateProfileModal'
import {
  hideFindViewOffcanvas,
  setListFiltered,
  setFilteredPosition
} from '../../features/offcanvas/FindViewSlice'

// CATEGORY SVG
import Hospital from '../images/icon/hospital.png'
import Education from '../images/icon/educational.png'
import Industry from '../images/icon/industry.png'
import Professional from '../images/icon/profesional.png'
import ProductShowcase from '../images/icon/productShowcase.png'
import LocationRadius from '../images/icon/within5km.png'
import LocationCity from '../images/icon/city.png'
import LocationNearby from '../images/icon/nearby.png'
import ListCities from '../images/icon/listofcity.png'
import Bookmarked from '../images/icon/bookmark.png'
import NewlyAdded from '../images/icon/newlyAdded.png'
import Suggestions from '../images/icon/suggestion.png'
import Featured from '../images/icon/featured.png'
import {
  showMyBusinessModal,
  setCreateBusinessData
} from '../../features/offcanvas/MyBusinessSlice'
import { showEditBusinessViewModal } from '../../features/offcanvas/EditBusinessSlice'
import { setQrOffCanvas } from '../../features/offcanvas/QrSlice'
import { useLocationHook } from '../../hooks/useLocationHook'
import { FILTERLIST } from '../../config'
import { useHandleScrollEnd } from '../../hooks/useHandleScrollEnd'
import { useAxios } from '../../hooks/useAxios'
const Find = ({
  hideFindViewOffcanvas,
  showMyBusinessModal,
  showEditBusinessViewModal,
  setCreateBusinessData,
  setQrOffCanvas,
  List,
  pageIndex,
  role,
  selectedFilter,
  ListFiltered,
  setListFiltered,
  ListFilteredPosition,
  setFilteredPosition
}) => {
  const [screenWidth, setScreenWidth] = useState(null)
  const [location, setLocation] = useState('')
  const [searchText, setSearchText] = useState('')
  const [listMyBusiness, setListMyBusiness] = useState([])
  const marqueeRef = useRef(null)

  const [LoadingMore, setLoadingMore] = useState(false)

  const { AxiosCall, Loading } = useAxios()
  const { ElementRef } = useHandleScrollEnd({
    onScrollToEnd: ScrollEnd,
    waitForNextScroll: 2000
  })

  async function ScrollEnd() {
    console.log('Scroll to end')
    setLoadingMore(true)
    const NewData = await AxiosCall({
      method: 'get',
      url: `businesses?pageSize=15&pageIndex=${ListFilteredPosition}`
    })
    console.log(NewData)
    if (NewData) {
      setListFiltered([...ListFiltered, ...NewData.items])
      setFilteredPosition(ListFilteredPosition + 1)
    }
    setLoadingMore(false)
  }

  const { getUserLocation } = useLocationHook()
  let navigate = useNavigate()
  useEffect(() => {
    setScreenWidth(window.screen.width)
  }, [])

  const colors = [
    '#faedcb',
    '#c9e4de',
    '#c6def1',
    '#dbcdf0',
    '#f2c6de',
    '#f7d9c4',
    '#ffe4c4',
    '#a9eadc',
    '#d1c0ff',
    '#ffdab9',
    '#b0e0e6',
    '#f0e68c',
    '#afeeee',
    '#ffb6c1',
    '#c0c0c0',
    '#87ceeb'
  ]
  const handlers = useSwipeable({
    onSwipedLeft: () => {
      if (marqueeRef.current) {
        marqueeRef.current.scrollLeft += 50
      }
    },
    onSwipedRight: () => {
      if (marqueeRef.current) {
        marqueeRef.current.scrollLeft -= 50
      }
    }
  })
  const handleDataFilter = filterType => {
    navigate('/nanoWebsites?filterType=' + filterType)
    hideFindViewOffcanvas()
  }
  useEffect(() => {
    getUserLocation()
    // const fetchLocation = async () => {
    //   if ('geolocation' in navigator) {
    //     try {
    //       const position = await new Promise((resolve, reject) => {
    //         navigator.geolocation.getCurrentPosition(resolve, reject)
    //       })

    //       const { latitude, longitude } = position.coords

    //       const response = await fetch(
    //         `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    //       )
    //       const data = await response.json()

    //       const city =
    //         data.address.city ||
    //         data.address.town ||
    //         data.address.village ||
    //         data.address.hamlet ||
    //         ''
    //       const country = data.address.country || ''
    //       setLocation(city + ',' + country)
    //     } catch (error) {
    //       console.error('Error getting location:', error.message)
    //     }
    //   } else {
    //     console.error('Geolocation is not supported by this browser.')
    //   }
    // }

    // // Call the function to fetch the location when the component mounts
    // fetchLocation()
  }, [])

  const filterCards = filterType => {
    hideFindViewOffcanvas()
    // axios.post('/behaviour', {behaviourData: cardLogs}).then(res=>{
    //   if (res.data.message === 'Success') {
    //     console.log(res.data)
    //     setCardLogs({cardLogs: []})
    //   } else {
    //     console.log('Something went wrong')
    //   }
    // })
  }

  const handleMyBusiness = (actionType, data) => {
    if (actionType === 'add') {
      showEditBusinessViewModal({
        editBusinessOffcanvasVisible: true,
        actionType: actionType
      })
    } else {
      showMyBusinessModal({ myBusinessModalVisible: true })
      setCreateBusinessData({ businessData: data })
    }
    hideFindViewOffcanvas()
  }

  useEffect(() => {
    axios.get('/businesses/getMyBusinesses').then(res => {
      if (res.data.message === 'Success') {
        setListMyBusiness(res.data.data)
      } else {
        console.log('Something went wrong')
      }
    })
  }, [])

  useEffect(() => {
    if (selectedFilter.filter === FILTERLIST.FILTER6.filter) {
      setListFiltered(List)
      setFilteredPosition(pageIndex)
    } else {
      setFilteredPosition(0)
      setListFiltered([])
    }
  }, [selectedFilter])

  return (
    <div class={screenWidth < 700 ? '' : 'p-0'}>
      <div className="container">
        <div class="tab-content mt-0">
          <div
            class="tab-pane fade show active"
            id="businesses"
            role="tabpanel"
          >
            <div className="row categoriesButtonSection mt-0 w-100">
              <div class="section" style={{ padding: '0px 0px 8px 10px' }}>
                <div class="listview-title ps-1 pb-0 mb-1 pe-0 fw-bold">
                  <div>My Businesses</div>
                  <div
                    class="d-flex align-items-center"
                    onClick={() => {
                      // handleMyBusiness('add')
                      // hideFindViewOffcanvas()
                      console.log(role)
                      if (role !== 1) {
                        setQrOffCanvas(true)
                      } else {
                        showEditBusinessViewModal({
                          editBusinessOffcanvasVisible: true,
                          actionType: 'verify'
                        })
                      }
                      hideFindViewOffcanvas()
                    }}
                  >
                    <span
                      class="badge bg-success fs-10"
                      style={{ height: '18px' }}
                    >
                      <ion-icon name="add-outline" class="fs-6"></ion-icon>{' '}
                      Create Business
                    </span>
                  </div>
                </div>
                <div class="mb-5">
                  <Splide
                    options={{
                      type: 'slide',
                      rewind: false,
                      gap: 10,
                      // padding: '4rem',
                      padding: { left: 5, right: 20 },
                      padding: { left: '0.5rem', right: '2rem' },
                      autoplay: false,
                      interval: 3000,
                      pagination: false,
                      arrows: false,
                      breakpoints: {
                        700: {
                          perPage: 3,
                          perMove: 1
                        },
                        1200: {
                          perPage: 3,
                          perMove: 1
                        },
                        1600: {
                          perPage: 3,
                          perMove: 1
                        },
                        2000: {
                          perPage: 3,
                          perMove: 1
                        }
                      }
                    }}
                  >
                    {listMyBusiness && (
                      <>
                        {listMyBusiness.map((v, k) => {
                          return (
                            <SplideSlide>
                              <a
                                href="javascript:void(0)"
                                onClick={() => {
                                  handleMyBusiness('update', v)
                                }}
                              >
                                <img
                                  src={
                                    v.BusinessLogo
                                      ? v.BusinessLogo
                                      : 'assets/img/sample/avatar/avatar1.jpg'
                                  }
                                  alt="alt"
                                  class="imaged w-100 rounded"
                                  style={{
                                    border: 'solid',
                                    aspectRatio: '1',
                                    objectFit: 'contain'
                                  }}
                                />
                                <span
                                  class="two-line-limit fw-bold text-nowrap d-flex justify-content-center"
                                  style={{
                                    fontSize: '10px',
                                    marginTop: '2px',
                                    lineHeight: '15px',
                                    color: '#707070'
                                  }}
                                >
                                  {v.BusinessName}
                                </span>
                              </a>
                            </SplideSlide>
                          )
                        })}
                      </>
                    )}
                    <SplideSlide>
                      <a
                        href="javascript:void(0)"
                        onClick={() => {
                          console.log(role)
                          if (role !== 1) {
                            setQrOffCanvas(true)
                          } else {
                            showEditBusinessViewModal({
                              editBusinessOffcanvasVisible: true,
                              actionType: 'verify'
                            })
                          }
                          hideFindViewOffcanvas()
                        }}
                      >
                        <div
                          class="imaged w-100 border-success rounded d-flex align-items-center justify-content-center"
                          style={{ border: 'solid', aspectRatio: '1' }}
                        >
                          <ion-icon
                            name="add-outline"
                            class="text-success"
                            style={{ fontSize: '32px' }}
                          ></ion-icon>
                        </div>
                        <span
                          class="two-line-limit fw-bold text-nowrap d-flex justify-content-center"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Create Business
                        </span>
                      </a>
                    </SplideSlide>
                  </Splide>
                </div>
                {/* <div class="mb-5">
                  <Splide
                    options={{
                      type: 'slide',
                      rewind: true,
                      gap: 10,
                      perMove: 1,
                      padding: { left: 5, right: 20 },
                      padding: { left: '0.5rem', right: '2.5rem' },
                      autoplay: false,
                      easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                      pagination: false,
                      arrows: false,
                      // direction: 'rtl',
                      breakpoints: {
                        700: {
                          perPage: 3
                        },
                        1200: {
                          perPage: 3
                        },
                        1600: {
                          perPage: 3
                        },
                        2000: {
                          perPage: 3
                        }
                      }
                    }}
                  >
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: 'auto'
                        }}
                        onClick={() => {
                          filterCards('healthcare')
                        }}
                      >
                        <img
                          src={Hospital}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Healthcare
                        </span>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: 'auto'
                        }}
                        onClick={() => {
                          filterCards('educational')
                        }}
                      >
                        <img
                          src={Education}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Education
                        </span>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: '80px'
                        }}
                        onClick={() => {
                          filterCards('industry')
                        }}
                      >
                        <img
                          src={Industry}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Manufacturing
                        </span>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: '80px'
                        }}
                        onClick={() => {
                          filterCards('professional')
                        }}
                      >
                        <img
                          src={Professional}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Professional
                        </span>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: '80px'
                        }}
                        onClick={() => {
                          filterCards('productShowcase')
                        }}
                      >
                        <img
                          src={ProductShowcase}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Retails
                        </span>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
                <div class="mb-5">
                  <Splide
                    options={{
                      type: 'slide',
                      rewind: true,
                      gap: 10,
                      perMove: 1,
                      padding: { left: 5, right: 20 },
                      padding: { left: '0.5rem', right: '2.5rem' },
                      autoplay: false,
                      interval: 1500,
                      speed: 1500, // Adjust the transition speed (in milliseconds)
                      easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                      pagination: false,
                      arrows: false,
                      direction: 'ltr',
                      breakpoints: {
                        700: {
                          perPage: 3
                        },
                        1200: {
                          perPage: 3
                        },
                        1600: {
                          perPage: 3
                        },
                        2000: {
                          perPage: 3
                        }
                      }
                    }}
                  >
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: 'auto'
                        }}
                      >
                        <img
                          src={LocationRadius}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Within 5kms
                        </span>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: 'auto'
                        }}
                      >
                        <img
                          src={LocationCity}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Within City
                        </span>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: 'auto'
                        }}
                      >
                        <img
                          src={LocationNearby}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Popular
                        </span>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: 'auto'
                        }}
                      >
                        <img
                          src={ListCities}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          List of Cities
                        </span>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
                <div class="mb-5">
                  <Splide
                    options={{
                      type: 'slide',
                      rewind: true,
                      gap: 10,
                      perMove: 1,
                      padding: { left: 5, right: 20 },
                      padding: { left: '0.5rem', right: '2.5rem' },
                      autoplay: false,
                      interval: 1500,
                      speed: 1500, // Adjust the transition speed (in milliseconds)
                      easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                      pagination: false,
                      arrows: false,
                      direction: 'ltr',
                      breakpoints: {
                        700: {
                          perPage: 3
                        },
                        1200: {
                          perPage: 3
                        },
                        1600: {
                          perPage: 3
                        },
                        2000: {
                          perPage: 3
                        }
                      }
                    }}
                  >
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: 'auto'
                        }}
                      >
                        <img
                          src={Bookmarked}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Bookmarked
                        </span>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: 'auto'
                        }}
                      >
                        <img
                          src={NewlyAdded}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Newly Added
                        </span>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: 'auto'
                        }}
                      >
                        <img
                          src={Suggestions}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Suggestions
                        </span>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div
                        class="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                        style={{
                          height: '90px',
                          width: 'auto'
                        }}
                      >
                        <img
                          src={Featured}
                          alt="avatar"
                          class="m-auto"
                          width="50px"
                        />
                        <span
                          class="two-line-limit fw-bold text-nowrap"
                          style={{
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          Featured
                        </span>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div> */}
                <div className="mb-5 selectedList">
                  <div class="mt-2 h6 ps-2">{selectedFilter.text}</div>
                  <ul class="listview image-listview" ref={ElementRef}>
                    {ListFiltered?.map((itm, i) => {
                      return (
                        <li>
                          <a href="#" class="item">
                            <img
                              src={
                                itm.BusinessLogo
                                  ? itm.BusinessLogo
                                  : 'assets/img/sample/avatar/avatar1.jpg'
                              }
                              alt="image"
                              class="image"
                            />
                            <div class="in">
                              <div>
                                <header>{itm.Location}</header>
                                {itm.BusinessName}
                                <footer className="text-truncate">
                                  {itm.Description}
                                </footer>
                              </div>
                            </div>
                          </a>
                        </li>
                      )
                    })}
                    {LoadingMore && (
                      <li className="d-flex justify-content-center align-items-center">
                        <div class="loaderBars"></div>
                      </li>
                    )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="cities"
            role="tabpanel"
            onClick={() => {
              handleDataFilter('cities')
            }}
          >
            <div className="row categoriesButtonSection">
              <div class="section full">
                <div class="listview-title">Maharashtra</div>
                <div class="mb-2">
                  <Splide
                    options={{
                      type: 'loop',
                      gap: 2,
                      arrows: false,
                      trimSpace: false,
                      autoWidth: true,
                      pagination: false,
                      grid: {
                        rows: 2,
                        cols: 2
                      },
                      rewind: true,
                      perMove: 1,
                      padding: '3.5rem',
                      autoplay: false,
                      interval: 1500,
                      speed: 1500, // Adjust the transition speed (in milliseconds)
                      easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                      breakpoints: {
                        700: {
                          perPage: 3
                        },
                        1200: {
                          perPage: 4
                        },
                        1600: {
                          perPage: 4
                        },
                        2000: {
                          perPage: 4
                        }
                      }
                    }}
                  >
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Mumbai
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Pune
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Nagpur
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Nashik
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Aurangabar
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Thane
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
                <div class="listview-title">Uttar Pradesh</div>
                <div class="mb-2">
                  <Splide
                    options={{
                      type: 'loop',
                      gap: 2,
                      arrows: false,
                      trimSpace: false,
                      autoWidth: true,
                      pagination: false,
                      grid: {
                        rows: 2,
                        cols: 2
                      },
                      rewind: true,
                      perMove: 1,
                      padding: '3.5rem',
                      autoplay: false,
                      interval: 1500,
                      speed: 1500, // Adjust the transition speed (in milliseconds)
                      easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                      breakpoints: {
                        700: {
                          perPage: 3
                        },
                        1200: {
                          perPage: 4
                        },
                        1600: {
                          perPage: 4
                        },
                        2000: {
                          perPage: 4
                        }
                      }
                    }}
                  >
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Lucknow
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Kanpur
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Varanasi
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Agra
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Allahabad
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Ghaziabad
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
                <div class="listview-title">Tamil Nadu</div>
                <div class="mb-2">
                  <Splide
                    options={{
                      type: 'loop',
                      gap: 2,
                      arrows: false,
                      trimSpace: false,
                      autoWidth: true,
                      pagination: false,
                      grid: {
                        rows: 2,
                        cols: 2
                      },
                      rewind: true,
                      perMove: 1,
                      padding: '3.5rem',
                      autoplay: false,
                      interval: 1500,
                      speed: 1500, // Adjust the transition speed (in milliseconds)
                      easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                      breakpoints: {
                        700: {
                          perPage: 3
                        },
                        1200: {
                          perPage: 4
                        },
                        1600: {
                          perPage: 4
                        },
                        2000: {
                          perPage: 4
                        }
                      }
                    }}
                  >
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Chennai
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Coimbatore
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Madurai
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Salem
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Erode
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Trichy
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
                <div class="listview-title">West Bengal</div>
                <div class="mb-2">
                  <Splide
                    options={{
                      type: 'loop',
                      gap: 2,
                      arrows: false,
                      trimSpace: false,
                      autoWidth: true,
                      pagination: false,
                      grid: {
                        rows: 2,
                        cols: 2
                      },
                      rewind: true,
                      perMove: 1,
                      padding: '3.5rem',
                      autoplay: false,
                      interval: 1500,
                      speed: 1500, // Adjust the transition speed (in milliseconds)
                      easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                      breakpoints: {
                        700: {
                          perPage: 3
                        },
                        1200: {
                          perPage: 4
                        },
                        1600: {
                          perPage: 4
                        },
                        2000: {
                          perPage: 4
                        }
                      }
                    }}
                  >
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Kolkata
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Howrah
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Durgapur
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Asansol
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Siliguri
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Bardhaman
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
                <div class="listview-title">Karnataka</div>
                <div class="mb-2">
                  <Splide
                    options={{
                      type: 'loop',
                      gap: 2,
                      arrows: false,
                      trimSpace: false,
                      autoWidth: true,
                      pagination: false,
                      grid: {
                        rows: 2,
                        cols: 2
                      },
                      rewind: true,
                      perMove: 1,
                      padding: '3.5rem',
                      autoplay: false,
                      interval: 1500,
                      speed: 1500, // Adjust the transition speed (in milliseconds)
                      easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                      breakpoints: {
                        700: {
                          perPage: 3
                        },
                        1200: {
                          perPage: 4
                        },
                        1600: {
                          perPage: 4
                        },
                        2000: {
                          perPage: 4
                        }
                      }
                    }}
                  >
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Bengaluru
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Mysore
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Mangalore
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Hubballi
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                    <SplideSlide>
                      <div class="d-flex flex-column">
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Belagavi
                            </span>
                          </Link>
                        </CityChips>
                        <CityChips>
                          <Link to="#" className="d-flex align-items-center">
                            <span className="chip-delete ms-1">
                              <ion-icon
                                name="location-outline"
                                class="fs-6"
                              ></ion-icon>
                            </span>
                            <span
                              className="chip-label ps-0"
                              style={{ fontSize: '0.9rem' }}
                            >
                              Gilbarga
                            </span>
                          </Link>
                        </CityChips>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
              </div>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="offerings"
            role="tabpanel"
            onClick={() => {
              handleDataFilter('offerings')
            }}
          >
            <div
              class="pb-3"
              // style={{ marginLeft: '-8px', marginRight: '-8px' }}
            >
              <div class="listview-title">Offerings</div>
              <Splide
                options={{
                  type: 'loop',
                  rewind: true,
                  perMove: 1,
                  perPage: 2,
                  gap: 10,
                  padding: '2.5rem',
                  autoplay: true,
                  interval: 3000,
                  pagination: false,
                  arrows: false,
                  breakpoints: {
                    700: {
                      perPage: 2
                    },
                    1200: {
                      perPage: 3
                    },
                    1600: {
                      perPage: 3
                    },
                    2000: {
                      perPage: 3
                    }
                  }
                }}
              >
                <SplideSlide>
                  <div class="card bg-transparent shadow-none">
                    <img
                      src="assets/img/sample/photo/d1.jpg"
                      class="rounded"
                      alt="image"
                    />
                    <div class="card-body pt-2 ps-0">
                      <h3 class="mb-0 fw-normal">Progressive web app ready</h3>
                    </div>
                  </div>
                </SplideSlide>
              </Splide>
            </div>
          </div>
        </div>
      </div>
      <CreateProfileModal></CreateProfileModal>
    </div>
  )
}

const mapStateToProps = ({ findView, auth }) => {
  const { selectedFilter, ListFiltered, ListFilteredPosition } = findView
  const { role } = auth
  return {
    selectedFilter,
    ListFiltered,
    role,
    ListFilteredPosition
  }
}

const mapDispatchToProps = {
  hideFindViewOffcanvas,
  showMyBusinessModal,
  setQrOffCanvas,
  showEditBusinessViewModal,
  setCreateBusinessData,
  setListFiltered,
  setFilteredPosition
}

export default connect(mapStateToProps, mapDispatchToProps)(Find)
