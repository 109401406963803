import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    editBusinessOffcanvasVisible: false,
    actionType: '',
    businessContentAction: null, // edit/add
    businessContentPosition: null, // 0/1/2...
}

export const EditBusinessViewSlice = createSlice({
  name: 'editBusiness',
  initialState,
  reducers: {
    showEditBusinessViewModal: (state, action) => {
      const {
        editBusinessOffcanvasVisible,
        actionType
      } = action.payload
      state.editBusinessOffcanvasVisible = editBusinessOffcanvasVisible
      state.actionType = actionType
    },
    hideEditBusinessViewModal: (state, action) => {
      state.editBusinessOffcanvasVisible = false
    },
    setBusinessContentActionPosition : (state, action) => {
      const { businessContentPosition, businessContentAction } = action.payload;
      state.businessContentAction = businessContentAction;
      state.businessContentPosition = businessContentPosition;
    }
  }
})

export const { showEditBusinessViewModal, hideEditBusinessViewModal, setBusinessContentActionPosition } = EditBusinessViewSlice.actions;


export default EditBusinessViewSlice.reducer
