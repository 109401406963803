import React, { useState, useRef, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from "react-redux"
import QuickMeet from '../../Pages/QuickMeet'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { hideQuickMeetModal } from '../../../features/offcanvas/QuickMeetModalSlice'
import NanoWebsite from '../../Pages/NanoWebsite'

const QuickMeetModal = ({
    quickMeetModalVisible,
    hideQuickMeetModal
}) => {
    const splideRef = useRef(null)

    const tempData = [{
      businessLogo: 'https://allpos.com/images/allpos-logo-short.svg',
      businessName: 'allpos',
      businessCategory: 'Gadgets & Accessories'
    },{
      businessLogo: 'https://webweb.ams3.cdn.digitaloceanspaces.com/data/logo/1682057484_94d3e3b5016d6acb562e.jpg',
      businessName: 'Shra Snacks',
      businessCategory: 'Food Products'
    },
    {
      businessLogo: 'https://webweb.ams3.cdn.digitaloceanspaces.com/data/theenglishschoolpanvel.webweb.ai.in/1670579483_a2006b7783a98ab819a3.png',
      businessName: 'The English School Panvel',
      businessCategory: 'School'
    },
    {
      businessLogo: 'https://webweb.ams3.cdn.digitaloceanspaces.com/data/logo/1674107063_f5f7e7322405c490ed18.png',
      businessName: 'Sathe Scheme',
      businessCategory: 'Real Estate'
    },
    {
      businessLogo: 'https://webweb.ams3.cdn.digitaloceanspaces.com/data/ruchienergy2.webweb.ai.in/1687257667_72cda5adaabe0c48bb1e.png',
      businessName: 'Ruchi Energy',
      businessCategory: 'Manufacturing'
    },
    {
      businessLogo: 'https://webweb.ams3.cdn.digitaloceanspaces.com/data/readitorium.webweb.ai.in/1669289481_077f53169480dc43b2ea.png',
      businessName: 'Readitorium',
      businessCategory: 'Educational'
    },
    {
      businessLogo: 'https://webweb.ams3.cdn.digitaloceanspaces.com/data/logo/1673870307_4a8ba35fb3cc59d0a6cb.png',
      businessName: 'Pathwardhan Hospital',
      businessCategory: 'Hospital'
    }]
  return (
    <Modal show={quickMeetModalVisible} fullscreen={true} style={{transition: '0.3s'}}>
      <Modal.Body className="p-0 position-relative" style={{backgroundImage: 'linear-gradient(to bottom right, #3e3152, #ff5733)'}}>
        <button class="btn btn-text btn-sm position-absolute pe-0" onClick={() => {hideQuickMeetModal()}} style={{top: 15, left: 0, zIndex: 1}}>
            <ion-icon name="chevron-back-outline" class="text-white fs-1"></ion-icon>
        </button>
        <button class="btn btn-text btn-sm position-absolute pe-0" onClick={() => {hideQuickMeetModal()}} style={{top: 15, right: 0, zIndex: 1}}>
            <ion-icon name="bookmark-outline" class="text-white fs-1"></ion-icon>
        </button>
      {/* <button onClick={addSlide}>Add Splide</button> */}
        
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = ({ quickMeet }) => {
    const {
        quickMeetModalVisible
    } = quickMeet
    return {
        quickMeetModalVisible
    }
}
  
const mapDispatchToProps = {
    hideQuickMeetModal
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickMeetModal)
