import React from 'react'
import {
  setUserCurrentLocation,
  setLocationAccess
} from '../features/offcanvas/MyBusinessSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import axios from 'axios'

export function useLocationHook() {
  const dispatch = useDispatch()
  const {
    userCurrentLocation,
    userCurrentCountry,
    locationAccess,
    userCurrentLat,
    userCurrentLong
  } = useSelector(state => state.myBusiness)

  async function fetchCurrentLocationDetailsUsingLatLong() {
    dispatch(setLocationAccess('LOADING'))
    if ('geolocation' in navigator) {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject)
        })

        const { latitude, longitude } = position.coords

        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
        )
        const data = await response.json()

        const city =
          data.address.city ||
          data.address.town ||
          data.address.village ||
          data.address.hamlet ||
          data.address.county ||
          ''
        const country = data.address.country || ''
        // setUserCurrentLocation(
        //     {   "userCurrentLocation" : city,
        //         "userCurrentCountry" : country
        //     })
        dispatch(setLocationAccess('GEO SUCCESS'))
        return {
          userCurrentLocation: city,
          userCurrentCountry: country,
          userCurrentLat: latitude,
          userCurrentLong: longitude
        }
      } catch (error) {
        console.error('Error getting location:', error.message)
        dispatch(setLocationAccess('GEO FAILED'))
        return false
      }
    } else {
      console.error('Geolocation is not supported by this browser.')
      dispatch(setLocationAccess('GEO FAILED'))
      return false
    }
  }

  async function fetchCurrentLocationDetailsUsingIP() {
    dispatch(setLocationAccess('LOADING'))
    const IP = await axios.get('https://api.ipify.org?format=json')
    if (IP.data.ip) {
      const response = await fetch(`https://ip-api.com/json/${IP.data.ip}`)
      const data = await response.json()
      // setUserCurrentLocation(
      //     {   "userCurrentLocation" : data.city,
      //         "userCurrentCountry" : data.country
      //     })
      console.log(data)
      dispatch(setLocationAccess('IP SUCCESS'))
      return {
        userCurrentLocation: data.city,
        userCurrentCountry: data.country,
        userCurrentLat: data.lat,
        userCurrentLong: data.lon
      }
    } else {
      dispatch(setLocationAccess('IP FAILED'))
      return false
    }
  }

  async function getUserLocation() {
    if (
      userCurrentCountry &&
      userCurrentLocation &&
      (locationAccess === 'GEO SUCCESS' || locationAccess === 'LOADING')
    ) {
      return {
        userCurrentLocation,
        userCurrentCountry,
        userCurrentLat,
        userCurrentLong
      }
    }
    console.log(locationAccess, userCurrentLocation, userCurrentCountry)

    const IPData = await fetchCurrentLocationDetailsUsingIP()
    dispatch(setUserCurrentLocation(IPData))
    const LatLongData = await fetchCurrentLocationDetailsUsingLatLong()
    dispatch(setUserCurrentLocation(LatLongData))
    if (LatLongData) {
      return { ...LatLongData }
    }

    return { ...IPData }
  }

  return { userCurrentLocation, userCurrentCountry, getUserLocation }
}
