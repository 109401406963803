import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    loginPopupVisible: false
}

export const loginPopupSlice = createSlice({
  name: 'loginPopup',
  initialState,
  reducers: {
    showLoginPopup: (state, action) => {
      console.log(state, action)
      const {
        loginPopupVisible
      } = action.payload
      state.loginPopupVisible = loginPopupVisible
    },
    hideLoginPopup: (state, action) => {
      state.loginPopupVisible = false
    }
  }
})

export const { showLoginPopup, hideLoginPopup } = loginPopupSlice.actions;


export default loginPopupSlice.reducer
