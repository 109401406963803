import React, { useState, useEffect } from 'react';
import { ImageCompressor } from 'image-compressor';
import axios from '../AxiosConfig';

export function useBusinessImageUpload() {

    const [ImageLink, setImageLink] = useState(null);
    const [ImageLoader, setImageLoader] = useState(false);

    function proceedCompressedImage(compressedSrc) {
        setImageLoader(true);
        const base64String = compressedSrc.split(',')[1]

        const byteCharacters = atob(base64String)
        const byteNumbers = new Array(byteCharacters.length)
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i)
        }
        const byteArray = new Uint8Array(byteNumbers)

        const blob = new Blob([byteArray], { type: 'image/png' })

        const formData = new FormData()
        formData.append('inputFile', blob)
        axios
            .post(
                'https://api.allpos.in/applications/upload/businessImage',
                formData,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            )
            .then(res => {
                if (res.data.data) {
                    setImageLink(res.data.data);
                }
                setImageLoader(false);
            })
            .catch(err => {
                console.log(err);
                setImageLoader(false);
            })
    }

    // useEffect(() => {
    //     if (imagePath) {
    //         const fileUrl = URL.createObjectURL(imagePath);
    //         const imageCompressor = new ImageCompressor()
    //         const compressorSettings = {
    //             toWidth: 500,
    //             toHeight: 500,
    //             mimeType: 'image/png',
    //             mode: 'strict',
    //             quality: 0.6,
    //             speed: 'low'
    //         }
    //         imageCompressor.run(fileUrl, compressorSettings, proceedCompressedImage)
    //     }
    // }, [imagePath])


    return { proceedCompressedImage, ImageLink, setImageLink, ImageLoader }
}