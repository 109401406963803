import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    quickMeetModalVisible: false
}

export const appToastSlice = createSlice({
  name: 'quickMeet',
  initialState,
  reducers: {
    showQuickMeetModal: (state, action) => {
      console.log(state, action)
      const {
        quickMeetModalVisible
      } = action.payload
      state.quickMeetModalVisible = quickMeetModalVisible
    },
    hideQuickMeetModal: (state, action) => {
      state.quickMeetModalVisible = false
    }
  }
})

export const { showQuickMeetModal, hideQuickMeetModal } = appToastSlice.actions;


export default appToastSlice.reducer
