import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '../../AxiosConfig'
import config from '../../config'
import { useState } from 'react'

export const chatConversation = createAsyncThunk(
  'auth/chatConversation',
  async (params) => {
    const { ID } = params
    const response = await axios.get(config.apiUrl + '/chats/userBuisnessConversations/'+ ID)
    const { data } = response
    return data
  }
)

export const getConvoID = createAsyncThunk(
  'conversationID',
  async (params) => {
    const response = await axios.post(config.apiUrl + '/chats/getUserBuisnessConversationId', params)
    const { data } = response.data;
    console.log("Seen");
    console.log(data);
    return data
  }
)

export const sendMessage = createAsyncThunk(
  'sendMessage',
  async (params) => {
    const response = await axios.put(config.apiUrl + '/chats/SendMessage', params)
    const { data } = response.data;
    console.log(response.type);
    return {data, params}
  }
)
// async (data, token) => {
//   const response = await axios.post(config.apiUrl + '/chats/SendMessage', data);
//   console.log(response);
// }

const initialState = {
    chatViewOffcanvasVisible: false,
    chatThreadViewOffcanvasVisible: false,
    chatViewId: null,
    chatConveration: null,
    ChatBuisnessData: null,
    Message: '',
    isSend:'waiting',
    SentMessage: null,
    chatActionSheetVisible: false
}

export const ChatViewSlice = createSlice({
  name: 'chatView',
  initialState,
  reducers: {
    showChatViewOffcanvas: (state, action) => {
      const {
        chatViewOffcanvasVisible
      } = action.payload
      state.chatViewOffcanvasVisible = chatViewOffcanvasVisible
    },
    hideChatViewOffcanvas: (state, action) => {
      state.chatViewOffcanvasVisible = false
    },
    showChatThreadViewOffcanvas: (state, action) => {
      const {
        chatThreadViewOffcanvasVisible
      } = action.payload
      if(chatThreadViewOffcanvasVisible) {
        state.chatViewOffcanvasVisible = false;
      }
      state.chatThreadViewOffcanvasVisible = chatThreadViewOffcanvasVisible
    },
    hideChatThreadViewOffcanvas: (state, action) => {
      state.chatThreadViewOffcanvasVisible = false
      state.chatViewId = null
      state.ChatBuisnessData = null
      state.chatConveration = null
    },
    setChatViewId: (state, action) => {
      // if(action.payload) {
      //   state.chatViewOffcanvasVisible = true;
      // }
      state.chatViewId = action.payload
    },
    setChatBuisnessData: (state, action) => {
      state.ChatBuisnessData = action.payload
    },
    setMessage: (state, action) => {
      state.Message = action.payload;
    },
    setSentMessage:(state,action)=>{
      state.SentMessage=action.payload;
    },
    showChatActionSheet: (state, action) => {
      const {
        chatActionSheetVisible
      } = action.payload
      state.chatActionSheetVisible = chatActionSheetVisible
    },
    hideChatActionSheet: (state, action) => {
      state.chatActionSheetVisible = false
    }
  },
  extraReducers: {
    [chatConversation.fulfilled]: (state, action) => {
      const {message, data}  = action.payload;
      state.chatConveration = data;
      state.SentMessage = null;
    },
    [sendMessage.fulfilled]: (state, action) => {
      const {params, data}  = action.payload;
      state.Message = '';
      state.SentMessage = params.Msg
    },
    // [getConvoID.fulfilled]: (state, action) => {
    //   // const { data }  = action.payload;
    //   // console.log(message)
    //   // state.chatConveration = data
    //   console.log(action.payload);
    //   state.chatThreadViewOffcanvasVisible = true;
    //   state.chatViewId = action.payload;
    //   // chatConversation(data);
    // },
  }
})

export const { showChatViewOffcanvas, hideChatViewOffcanvas, showChatThreadViewOffcanvas, hideChatThreadViewOffcanvas, setChatViewId, setChatBuisnessData, setMessage,setSentMessage, showChatActionSheet, hideChatActionSheet } = ChatViewSlice.actions;


export default ChatViewSlice.reducer
