import { useState } from 'react'
import { useAxios } from './useAxios'

export default function useVerifyMobile() {
  const { AxiosCall } = useAxios()

  const [Loading, setLoading] = useState(false)
  const [OTPError, setOTPError] = useState(null)

  async function sendOTP(mobileNumber) {
    setLoading(true)
    const mobileSent = await AxiosCall({
      method: 'get',
      url: 'users/login?mobile=' + mobileNumber
    })
    console.log(mobileSent)
    setLoading(false)
    if (mobileSent === 'OTP Sent') {
      return true
    } else if (mobileSent === 'User not found') {
      return 'No User'
    }
    return false
  }

  async function verifyOTP(mobileNumber, otp) {
    setLoading(true)
    setOTPError(null)
    try {
      const response = await AxiosCall({
        method: 'get',
        url: `users/login?mobile=${mobileNumber}&otp=${otp}`
      })
      console.log('Response from server:', response)
      setLoading(false)

      if (response === 'Incorrect OTP') {
        setOTPError('Enter valid OTP')
        return false
      }

      return response
    } catch (error) {
      console.error('Error verifying OTP:', error)
      setLoading(false)
      setOTPError('An error occurred. Please try again.')
      return false
    }
  }

  async function registerUser(FullName, Phone, Profile = 'Consumer') {
    setLoading(true)
    const data = {
      username: FullName,
      mobileNumber: Phone,
      profile: Profile
    }
    const registered = await AxiosCall({
      method: 'post',
      url: 'users/register',
      sendData: data,
      Authorization: false
    })
    console.log(registered)
    setLoading(false)
    if (registered) {
      return registered
    }

    return false
  }

  return { sendOTP, verifyOTP, registerUser, Loading, OTPError }
}
