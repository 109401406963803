import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    detailViewModalVisible: false,
    linkUrl: ''
}

export const DetailViewSlice = createSlice({
  name: 'detailView',
  initialState,
  reducers: {
    showDetailViewModal: (state, action) => {
      console.log(state, action)
      const {
        detailViewModalVisible,
        linkUrl
      } = action.payload
      state.detailViewModalVisible = detailViewModalVisible
      state.linkUrl = linkUrl
    },
    hideDetailViewModal: (state, action) => {
      state.detailViewModalVisible = false
      state.linkUrl = ''
    }
  }
})

export const { showDetailViewModal, hideDetailViewModal } = DetailViewSlice.actions;


export default DetailViewSlice.reducer
