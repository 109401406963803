import React from 'react'
import { connect } from 'react-redux'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { hideChatActionSheet } from '../../../features/offcanvas/ChatViewSlice'

const ChatThreadViewOffcanvas = ({
    chatActionSheetVisible,
    hideChatActionSheet
}) => {
  return (
    <>
      <Offcanvas
        show={chatActionSheetVisible}
        placement={"bottom"}
        backdrop={true}
        onHide={() => {hideChatActionSheet()}}
        style={{ transition: '0.2s ease-in-out', height: 'auto', zIndex: 9999 }}
      >
        <div className="action-sheet inset" tabindex="-1" id="actionSheetAdd">
            <Offcanvas.Header>
                <h5 class="offcanvas-title">Share</h5>
                <div class="d-flex align-items-center" onClick={() => {hideChatActionSheet()}}><ion-icon name="close-outline" class="fs-1"></ion-icon></div>
            </Offcanvas.Header>
            <Offcanvas.Body className="p-0">
                <ul class="action-button-list">
                    {/* <li>
                        <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                            <span>
                                <ion-icon name="camera-outline"></ion-icon>
                                Take a photo
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                            <span>
                                <ion-icon name="videocam-outline"></ion-icon>
                                Video
                            </span>
                        </a>
                    </li> */}
                    <li>
                        <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                            <span>
                                <ion-icon name="image-outline"></ion-icon>
                                Upload from Gallery
                            </span>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                            <span>
                                <ion-icon name="document-outline"></ion-icon>
                                Documents
                            </span>
                        </a>
                    </li>
                    {/* <li>
                        <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                            <span>
                                <ion-icon name="musical-notes-outline"></ion-icon>
                                Sound file
                            </span>
                        </a>
                    </li> */}
                </ul>
            </Offcanvas.Body>
        </div>
      </Offcanvas>
    </>
  )
}

const mapStateToProps = ({ chatView }) => {
    const {
        chatActionSheetVisible
    } = chatView
  return {
    chatActionSheetVisible
  }
}

const mapDispatchToProps = {
    hideChatActionSheet
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatThreadViewOffcanvas)
