import React, { useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Offcanvas from 'react-bootstrap/Offcanvas'
import UserChatView from '../../Pages/Comps/UserChatView'
import { hideChatThreadViewOffcanvas, chatConversation } from '../../../features/offcanvas/ChatViewSlice'

const ChatThreadViewOffcanvas = ({
  chatThreadViewOffcanvasVisible,
  hideChatThreadViewOffcanvas,
  chatViewId,
  chatConversation,
  chatConveration
}) => {
  const touchStartX = useRef(null)
  const touchEndX = useRef(null)

  const handleTouchStart = e => {
    touchStartX.current = e.touches[0].clientX
  }

  const handleTouchEnd = e => {
    touchEndX.current = e.changedTouches[0].clientX
    handleSwipe()
  }

  const handleSwipe = () => {
    const swipeDistance = touchStartX.current - touchEndX.current
    const swipeThreshold = 50 // Adjust as needed

    if (swipeDistance > swipeThreshold) {
      console.log('Swiped left')
    } else if (swipeDistance < -swipeThreshold) {
      console.log('Swiped right')
      hideChatThreadViewOffcanvas()
    }
  }

  useEffect(() => {
    let getChats;
    if(chatViewId) {
        getChats = setInterval(() => {
            chatConversation({ID: chatViewId});
        },  20000);
    }
    return () => {
        clearInterval(getChats);
    }
  }, [chatViewId])

  // useEffect(() => {
  //   let getMessages;
  //   if(chatViewId) {
  //     getMessages = setInterval(() => {
  //       chatConversation(chatViewId)
  //     }, 2000);
  //   } else {
  //     clearInterval(getMessages);
  //   }

  //   return () => clearInterval(getMessages)

  // }, [chatViewId])
  return (
    <>
      <Offcanvas
        show={chatThreadViewOffcanvasVisible}
        placement="end"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onHide={() => {hideChatThreadViewOffcanvas()}}
        backdrop={true}
        style={{ transition: '0.2s ease-in-out' }}
        className={'offcanvas-end'}
      >
        {/* <div class="profilebox-end bg-primary">
          <div class="chatHeader bg-primary" style={{padding: '5px'}}>
                <a href="javascript:void(0)" className='btn btn-outline-primary outline-none border-0 btn-sm px-0 d-flex align-items-center justify-content-center text-light' onClick={() => {hideChatThreadViewOffcanvas()}}>
                    <ion-icon name="chevron-back-outline"></ion-icon>
                </a>
                <img src={chatConveration?.BusinessData?.BusinessLogo} onError={(e) => {e.target.src = "assets/img/apps/whatsapp.svg" }} alt="image" className="image" />
                <div className="in">
                    <div>
                        {chatConveration?.BusinessData?.BusinessName}
                    </div>
                </div>
            </div>
        </div> */}
        <div class="profileBox-end bg-primary">
            <div class="chatHeader bg-primary" style={{height: '50px'}}>
                <div className="in w-100 d-flex">
                  <a href="javascript:void(0)" className='text-light' onClick={() => {hideChatThreadViewOffcanvas()}}>
                      <ion-icon name="chevron-back-outline" class="fs-3"></ion-icon>
                  </a>
                  <img src={chatConveration?.BusinessData?.BusinessLogo} onError={(e) => {e.target.src = "assets/img/apps/whatsapp.svg" }} alt="image" className="image me-1" style={{maxWidth: '60px'}} />
                  <div class="text-nowrap" style={{width: '80%', overflow: 'hidden'}}>
                      {chatConveration?.BusinessData?.BusinessName}
                  </div>
                </div>
            </div>
        </div>
        <UserChatView></UserChatView>
      </Offcanvas>
    </>
  )
}

const mapStateToProps = ({ chatView }) => {
  const { chatThreadViewOffcanvasVisible, chatViewId, chatConveration } = chatView
  return {
    chatThreadViewOffcanvasVisible,
    chatViewId,
    chatConveration
  }
}

const mapDispatchToProps = {
  hideChatThreadViewOffcanvas,
  chatConversation
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChatThreadViewOffcanvas)
