import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    confirmationModalVisible: false,
    deleteItemID: null
}

export const ConfirmationViewSlice = createSlice({
  name: 'confirmation',
  initialState,
  reducers: {
    showConfirmationViewModal: (state, action) => {
      const {
        confirmationModalVisible,
        deleteItemID
      } = action.payload
      state.confirmationModalVisible = confirmationModalVisible
      state.deleteItemID = deleteItemID
    },
    hideConfirmationViewModal: (state, action) => {
      state.confirmationModalVisible = false
    }
  }
})

export const { showConfirmationViewModal, hideConfirmationViewModal } = ConfirmationViewSlice.actions;


export default ConfirmationViewSlice.reducer
