import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from '../../AxiosConfig'
import { connect } from 'react-redux'
import Loading from '../images/icon/loading-animate-webweb.app.svg'
import { setNanoWebsiteData } from '../../features/nanoWebsite/nanoWebsiteSlice'

const Load = ({
  setNanoWebsiteData
}) => {
    let navigate = useNavigate()
    const prevState = localStorage.getItem('prevState')
  return (
    <div id="appCapsule" className="pt-5">
      <div className="error-page" style={{ padding: '0px', maxWidth: 'initial' }}>
        <div className="icon-box text-danger">
          <img
            src={Loading}
            alt="Brand"
            width="100%"
            style={{ maxWidth: '550px' }}
          />
        </div>
        <button className="btn btn-light btn-lg rounded mb-1" disabled>
          <span
            className="spinner-grow spinner-grow-sm me-05"
            role="status"
            aria-hidden="true"
          ></span>
          Loading...
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = () => {
  return {
  }
}

const mapDispatchToProps = {
  setNanoWebsiteData
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Load)
