import React, { useState } from 'react'

function Businesses() {
    const [showSearch, setShowSearch] = useState(false);
    return (
        <div id="appCapsule" className='container'>
            <div className="section mt-3">

                {
                    showSearch &&
                    <form className="search-form">
                        <div className="form-group searchbox">
                            <input type="text" className="form-control" placeholder="Search Categories..." />
                            <i className="input-icon">
                                <ion-icon name="search-outline"></ion-icon>
                            </i>
                        </div>
                    </form>
                }

                <div className='my-3 d-flex justify-content-between align-items-end'>
                    <div>
                        {/* <div className="listview-title p-0">POPULAR</div> */}
                        <div className="h4 text-black fw-bold mb-0">Businesses</div>
                    </div>

                    <button type="button" class="btn btn-icon btn-sm btn-link" onClick={() => setShowSearch(prev => !prev)}>
                        {
                            showSearch ?
                                <ion-icon name="close-outline"></ion-icon>
                                :
                                <ion-icon name="search-outline"></ion-icon>
                        }
                    </button>
                    {/* <button type="button" className="btn btn-outline-primary btn-sm shadowed">View All</button> */}
                </div>

                <ul className="listview image-listview bg-transparent border-0">
                    <li>
                        <a href="#" className="item ps-0 pe-4">
                            <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <header>Categoey</header>
                                        <span>Business Name</span>
                                        <footer className='text-muted'>Location</footer>
                                    </div>
                                    <span className="text-muted fs-10">View Business</span>
                                </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="item ps-0 pe-4">
                            <img src="assets/img/sample/avatar/avatar7.jpg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <header>Categoey</header>
                                        <span>Business Name</span>
                                        <footer className='text-muted'>Location</footer>
                                    </div>
                                    <span className="text-muted fs-10">View Business</span>
                                </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="item ps-0 pe-4">
                            <img src="assets/img/sample/avatar/avatar5.jpg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <header>Categoey</header>
                                        <span>Business Name</span>
                                        <footer className='text-muted'>Location</footer>
                                    </div>
                                    <span className="text-muted fs-10">View Business</span>
                                </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="item ps-0 pe-4">
                            <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <header>Categoey</header>
                                        <span>Business Name</span>
                                        <footer className='text-muted'>Location</footer>
                                    </div>
                                    <span className="text-muted fs-10">View Business</span>
                                </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="item ps-0 pe-4">
                            <img src="assets/img/sample/avatar/avatar7.jpg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <header>Categoey</header>
                                        <span>Business Name</span>
                                        <footer className='text-muted'>Location</footer>
                                    </div>
                                    <span className="text-muted fs-10">View Business</span>
                                </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="item ps-0 pe-4">
                            <img src="assets/img/sample/avatar/avatar5.jpg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <header>Categoey</header>
                                        <span>Business Name</span>
                                        <footer className='text-muted'>Location</footer>
                                    </div>
                                    <span className="text-muted fs-10">View Business</span>
                                </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="item ps-0 pe-4">
                            <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <header>Categoey</header>
                                        <span>Business Name</span>
                                        <footer className='text-muted'>Location</footer>
                                    </div>
                                    <span className="text-muted fs-10">View Business</span>
                                </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="item ps-0 pe-4">
                            <img src="assets/img/sample/avatar/avatar7.jpg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <header>Categoey</header>
                                        <span>Business Name</span>
                                        <footer className='text-muted'>Location</footer>
                                    </div>
                                    <span className="text-muted fs-10">View Business</span>
                                </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" className="item ps-0 pe-4">
                            <img src="assets/img/sample/avatar/avatar5.jpg" alt="image" className="image" />
                                <div className="in">
                                    <div>
                                        <header>Categoey</header>
                                        <span>Business Name</span>
                                        <footer className='text-muted'>Location</footer>
                                    </div>
                                    <span className="text-muted fs-10">View Business</span>
                                </div>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default Businesses