import React, { useEffect, useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { hideDetailViewModal } from '../../features/offcanvas/DetailViewSlice'
import { getConvoID, setChatViewId, chatConversation, showChatThreadViewOffcanvas, sendMessage } from '../../features/offcanvas/ChatViewSlice'

const MoreDetails = ({ 
  hideDetailViewModal, 
  linkUrl, 
  USERID, 
  businessID,
  getConvoID,
  setChatViewId,
  chatConversation,
  showChatThreadViewOffcanvas,
  sendMessage,
  chatConveration
 }) => {
  const [newLinkText, setNewLinkText] = useState('')
  const [formData, setFormData] = useState({});
  const handleDetailViewClose = () => {
    hideDetailViewModal()
  }
  useEffect(() => {
    if (linkUrl != 'form' && linkUrl != undefined && linkUrl != null && linkUrl != '') {
      const tempLinkText1 = linkUrl.split('//')[1]
      if (tempLinkText1.split('/')[0].includes('www')) {
        setNewLinkText(tempLinkText1.split('/')[0])
      } else {
        setNewLinkText('www.' + tempLinkText1.split('/')[0])
      }
    } else {
      setNewLinkText('Enquiry Now')
    }
  }, [linkUrl])
  const openChatThreadView = async () => {
    const chatData = {
      "UserID": USERID,
	    "BusinessID": businessID
    }
    hideDetailViewModal()
    showChatThreadViewOffcanvas({chatThreadViewOffcanvasVisible: true})
    const chatID  = await getConvoID(chatData);
    if(chatID.payload){
      setChatViewId(chatID.payload);
      chatConversation({ID: chatID.payload});
    }
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFormSubmission = (e) => {
    e.preventDefault()
    sendMessage({
        "ID": chatConveration.ID,
        "Msg" : JSON.stringify(formData),
        "To_BusinessID" : chatConveration.To_BusinessID,
        "Type": "Table"
    })
    setFormData({})
    hideDetailViewModal()
    showChatThreadViewOffcanvas({chatThreadViewOffcanvasVisible: true})
  }
  return (
    <>
      <div className="safe-area-custom-pt row d-flex align-items-center bg-dark">
        <div className="w-25 d-flex justify-content-start align-items-center">
          <a
            href="javascript:void(0)"
            className="d-flex align-items-center"
            onClick={handleDetailViewClose}
          >
            <ion-icon
              name="chevron-back-outline"
              class="fs-1 text-light"
            ></ion-icon>
          </a>
        </div>
        <div className="w-50 dropdown text-center">
          <h5 class="mb-0 text-light">{newLinkText}</h5>
        </div>
        <div
          className="w-25 d-flex justify-content-end text-light fs-4 align-items-start position-relative"
          style={{ paddingBottom: '4px' }}
        >
          <div class="dropdown">
            <button
              class="btn btn-transparent border-0 dropdown-toggle p-0"
              type="button"
              data-bs-toggle="dropdown"
            >
              <ion-icon
                name="ellipsis-vertical-outline"
                class="fs-1 text-light me-0"
              ></ion-icon>
            </button>
            <div class="dropdown-menu p-0">
              <a class="dropdown-item" href="#">
                <ion-icon name="alert-circle-outline"></ion-icon>
                &nbsp;Report
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          style={{
            marginLeft: '-8px',
            marginRight: '-8px',
            height: '100vh',
            overflow: 'hidden',
            background: 'gainsboro'
          }}
        >
          {(linkUrl != 'form' && linkUrl != undefined && linkUrl != null && linkUrl != '') &&
            <iframe
              src={linkUrl ? linkUrl : 'https://webweb.ai'}
              width="100%"
              height="100%"
            />
          }
          {(linkUrl == 'form') &&
            <div class="d-flex justify-content-center align-items-center" style={{height: '80vh'}}>
              <div class="section p-3 w-100">
                <div class="card">
                  <div class="card-body">
                    <form>
                      <div class="form-group boxed">
                          <div class="input-wrapper">
                              <label class="form-label" for="name">Name</label>
                              <input type="text" class="form-control" id="name" name="Name" value={formData.name} onChange={handleInputChange} placeholder="Enter your name" autocomplete="off" required />
                              <i class="clear-input">
                                  <ion-icon name="close-circle"></ion-icon>
                              </i>
                          </div>
                      </div>
                      <div class="form-group boxed">
                          <div class="input-wrapper">
                              <label class="form-label" for="phone">Phone</label>
                              <input type="number" class="form-control" id="phone" name="Phone" value={formData.phone} onChange={handleInputChange} placeholder="Enter your phone" autocomplete="off" required />
                              <i class="clear-input">
                                  <ion-icon name="close-circle"></ion-icon>
                              </i>
                          </div>
                      </div>
                      <div class="form-group boxed">
                          <div class="input-wrapper">
                              <label class="form-label" for="location">Location</label>
                              <input type="text" class="form-control" id="location" name="Location" value={formData.location} onChange={handleInputChange} placeholder="Enter your location" autocomplete="off" required />
                              <i class="clear-input">
                                  <ion-icon name="close-circle"></ion-icon>
                              </i>
                          </div>
                      </div>
                      <div class="form-group boxed">
                          <div class="input-wrapper">
                              <label class="form-label" for="address">Address</label>
                              <textarea id="address" rows="4" name="Address" value={formData.address} onChange={handleInputChange} class="form-control" required></textarea>
                              <i class="clear-input">
                                  <ion-icon name="close-circle"></ion-icon>
                              </i>
                          </div>
                      </div>
                      {(linkUrl == 'form') &&
                        <div class="fab-button text bottom-center" style={{zIndex: 999}}>
                            <button class="fab" onClick={(e) => {handleFormSubmission(e)}} style={{ maxHeight: '40px' }}>
                              <ion-icon name="checkmark-circle-outline" class="fs-6"></ion-icon>
                              Submit
                            </button>
                        </div>
                      }
                    </form>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
        {(linkUrl != 'form') &&
          <div class="fab-button text bottom-center" style={{zIndex: 999}}>
              <a href="javascript:void(0)" onClick={() => {openChatThreadView()}} class="fab" style={{ maxHeight: '40px' }}>
                <ion-icon name="chatbubbles-outline" class="fs-6"></ion-icon>
                Message
              </a>
          </div>
        }
        <div class="btn border-0 h-auto" onClick={handleDetailViewClose} style={{position: 'fixed', bottom: '80px', zIndex: 999, background: '#000000ab', width: '40px', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px'}}>
          <a href="javascript:void(0)" class="fab" style={{ maxHeight: '40px' }}>
            <ion-icon name="chevron-back-outline" class="fs-3 me-0 text-light"></ion-icon>
            <span class="fs-10 text-light">Back</span>
          </a>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = ({ detailView, auth, data, chatView }) => {
  const { linkUrl } = detailView
  const { USERID } = auth
  const { businessID } = data
  const { chatConveration } = chatView
  return {
    linkUrl,
    USERID,
    businessID,
    chatConveration
  }
}

const mapDispatchToProps = {
  hideDetailViewModal,
  getConvoID,
  setChatViewId,
  chatConversation,
  showChatThreadViewOffcanvas,
  sendMessage
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreDetails)
