import React, { useState, useEffect } from 'react'
import { Link, useSearchParams, useNavigate, useLocation } from 'react-router-dom'
import { connect } from 'react-redux'
import { logout } from '../../../features/auth/authSlice'
import LoginPopupModal from '../Sidebar/LoginPopupModal'

const Header = () => {
  const currentStateName = window.location.href
  const [searchInput, showSearchInput] = useState(false)
  const [bgColor, setBgColor] = useState('bg-primary')
  const [searchParams, setSearchParams] = useSearchParams({'chatId': ''})
  const chatId = searchParams.get('chatId')
  const [searchText, setSearchText] = useState('')
  let navigate = useNavigate()
  const urlSearch = window.location.pathname.split('/')
  const queryString = window.location.search
  useEffect(() => {
      localStorage.setItem('prevState', urlSearch[1] + queryString)
  }, [urlSearch])
  useEffect(() => {
    if (currentStateName.includes('moreDetails')) {
      setBgColor('bg-dark')
    }
  }, [currentStateName])
  const search = useLocation().search
  const queryStringValue = new URLSearchParams(search).get('q')
  useEffect(() => {
    if (queryStringValue) {
      setSearchText(queryStringValue)
    }
  }, [queryStringValue])
  const handleSearch = (searchText) => {
    navigate('/search?q=' + searchText)
    setSearchText(searchText)
  }
  return (
    <div>
      <div className={`appHeader ${bgColor}`}>
        {!currentStateName.includes('/manage') &&
          !currentStateName.includes('/MessagesView') &&
          !currentStateName.includes('/find') &&
          !currentStateName.includes('/userProfile') &&
          !currentStateName.includes('/moreDetails') &&
          !currentStateName.includes('/search') && (
            <div className="row w-100 d-flex align-items-center">
              <div className="w-25 d-flex justify-content-start align-items-center">
                <Link to="/userProfile" className="d-flex align-items-center">
                  <ion-icon
                    name="person-circle-outline"
                    class="text-white"
                    style={{ fontSize: '2.5rem' }}
                  ></ion-icon>
                </Link>
              </div>
              <div className="w-50 dropdown text-center"></div>
              <div
                className="w-25 d-flex justify-content-end text-secondary fs-4 align-items-start position-relative"
                style={{ paddingBottom: '4px' }}
              >
                <Link to="/MessagesView" class="item">
                  <div class="col d-flex justify-content-center align-items-center flex-column">
                    <ion-icon
                      name="chatbubbles-outline"
                      class="fs-1 text-light"
                    ></ion-icon>
                    {/* <strong class="fs-10 text-light" style={{height: '15px'}}>Message</strong> */}
                  </div>
                </Link>
              </div>
            </div>
          )}
        {currentStateName.includes('/manage') && (
          <div className="row w-100 d-flex align-items-center">
            <div className="w-25 d-flex justify-content-start align-items-center">
              <Link to="/home" className="d-flex align-items-center">
                <ion-icon
                  name="chevron-back-outline"
                  class="fs-1 text-white"
                ></ion-icon>
              </Link>
            </div>
            <div className="w-50 dropdown text-center">
              <h5 class="mb-0 text-white">kkenterprise@real-state</h5>
            </div>
            <div
              className="w-25 d-flex justify-content-end text-secondary fs-4 align-items-start position-relative"
              style={{ paddingBottom: '4px' }}
            >
              <div class="cursor-pointer fs-10 d-flex flex-column justify-content-center align-items-center">
                <ion-icon
                  name="call-outline"
                  class="fs-4 text-white"
                ></ion-icon>
              </div>
            </div>
          </div>
        )}
        {currentStateName.includes('/MessagesView') && (
          <>
            {searchInput && (
              <div
                className="wide-block pt-1 pb-1 searchSection"
                style={{ position: 'absolute', left: 0, right: '-18px' }}
              >
                <form className="search-form" style={{ width: '90%' }}>
                  <div className="form-group searchbox not-empty">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search Conversations"
                    />
                    <i className="input-icon">
                      <ion-icon
                        name="search-outline"
                        role="img"
                        className="md hydrated"
                        aria-label="search outline"
                      ></ion-icon>
                    </i>
                  </div>
                </form>
                <div
                  class="d-flex justify-content-center"
                  style={{
                    position: 'absolute',
                    top: '12px',
                    right: '20px',
                    zIndex: 10,
                    width: '10%'
                  }}
                  onClick={() => {
                    showSearchInput(false)
                  }}
                >
                  <ion-icon
                    name="close-outline"
                    class="text-dark fs-2"
                  ></ion-icon>
                </div>
              </div>
            )}
            {searchInput === false && (
              <div className="row w-100 d-flex align-items-center">
                <div className="w-25 d-flex justify-content-start align-items-center">
                  <Link to={(chatId === '') ? '/find' :'/MessagesView'} className="d-flex align-items-center">
                    <ion-icon
                      name="chevron-back-outline"
                      class="fs-1 text-white"
                    ></ion-icon>
                  </Link>
                </div>
                <div className="w-50 dropdown text-center">
                  <h5 class="mb-0 text-light">
                    {(chatId === '') ? 
                      'Messages'
                    :
                      'Juan Esteban Sarmiento'
                    }
                  </h5>
                </div>
                <div
                  className="w-25 d-flex justify-content-end text-secondary fs-4 align-items-start position-relative"
                  style={{ paddingBottom: '4px' }}
                >
                  <div
                    class="cursor-pointer fs-10 d-flex flex-column justify-content-center align-items-center"
                    onClick={() => {
                      showSearchInput(true)
                    }}
                  >
                    <ion-icon
                      name="search-outline"
                      class="fs-4 text-white"
                    ></ion-icon>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {currentStateName.includes('/find') && (
          <div className="row w-100 d-flex align-items-center">
            <div className="w-25 d-flex justify-content-start align-items-center">
              <Link to="/userProfile" className="d-flex align-items-center">
                <ion-icon
                  name="person-circle-outline"
                  class="text-white"
                  style={{ fontSize: '2.5rem' }}
                ></ion-icon>
              </Link>
            </div>
            <div className="w-50 dropdown text-center"></div>
            <div
              className="w-25 d-flex justify-content-end text-secondary fs-4 align-items-start position-relative"
              style={{ paddingBottom: '4px' }}
            >
              <Link to="/MessagesView" class="item">
                <div class="col d-flex justify-content-center align-items-center flex-column">
                  <ion-icon
                    name="chatbubbles-outline"
                    class="fs-1 text-light"
                  ></ion-icon>
                  {/* <strong class="fs-10 text-light" style={{height: '15px'}}>Message</strong> */}
                </div>
              </Link>
            </div>
          </div>
        )}
        {currentStateName.includes('/userProfile') && (
          <div className="row w-100 d-flex align-items-center">
            <div className="w-25 d-flex justify-content-start align-items-center">
              <Link to="/find" className="d-flex align-items-center">
                <ion-icon
                  name="chevron-back-outline"
                  class="text-white fs-1"
                ></ion-icon>
              </Link>
            </div>
            <div className="w-50 dropdown text-center">
              <h5 class="mb-0 text-light">Profile</h5>
            </div>
            <div
              className="w-25 d-flex justify-content-end text-secondary fs-4 align-items-start position-relative"
            >
              <ion-icon name="log-out-outline" class="text-white fs-1"></ion-icon>
            </div>
          </div>
        )}
        {currentStateName.includes('/moreDetails') && (
          <div className="row w-100 d-flex align-items-center">
            <div className="w-25 d-flex justify-content-start align-items-center">
              <Link to="/nanoWebsites" className="d-flex align-items-center">
                <ion-icon
                  name="chevron-back-outline"
                  class="fs-1 text-light"
                ></ion-icon>
              </Link>
            </div>
            <div className="w-50 dropdown text-center">
              <h5 class="mb-0 text-light">kkenterprise@real-state</h5>
            </div>
            <div
              className="w-25 d-flex justify-content-end text-light fs-4 align-items-start position-relative"
              style={{ paddingBottom: '4px' }}
            >
              {/* <div class="cursor-pointer fs-10 d-flex flex-column justify-content-center align-items-center">
                <ion-icon
                  name="call-outline"
                  class="fs-4 text-white"
                ></ion-icon>
              </div> */}
              <div class="dropdown">
                <button
                  class="btn btn-transparent border-0 dropdown-toggle p-0"
                  type="button"
                  data-bs-toggle="dropdown"
                >
                  <ion-icon
                    name="ellipsis-vertical-outline"
                    class="fs-1 text-light me-0"
                  ></ion-icon>
                </button>
                <div class="dropdown-menu p-0">
                  <a class="dropdown-item" href="#">
                    <ion-icon name="alert-circle-outline"></ion-icon>
                    &nbsp;Report
                  </a>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentStateName.includes('/search') && (
          <div className="row w-100">
            <Link to="/find" className="d-flex align-items-center p-0" style={{width: '10%'}}>
              <ion-icon
                name="chevron-back-outline"
                class="text-white fs-1 ms-1"
              ></ion-icon>
            </Link>
            <form class="search-form" style={{width: '90%'}}>
                <div class="form-group searchbox">
                    <input type="text" class="form-control ps-1" value={searchText} placeholder="Search..." onChange={(e) => {(handleSearch(e.target.value))}} />
                    <i class="input-icon" style={{right: 0, left: 'auto'}}>
                        <ion-icon name="search-outline"></ion-icon>
                    </i>
                </div>
            </form>
          </div>
        )}
      </div>
      <LoginPopupModal></LoginPopupModal>
    </div>
  )
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = {
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
