import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import axios from '../../../AxiosConfig'
import { hideCityListModal } from '../../../features/offcanvas/CityListSlice'

const MyBusinessModal = ({
    cityListModalVisible,
    hideCityListModal
}) => {
    useEffect(() => {
        axios.get('/cities').then(res => {
            if (res.data.message === 'Success') {
                // console.log(res.data)
            }
        })
    }, [])
    return (
        <div>
            <Modal show={cityListModalVisible} style={{transition: '0.2s ease-in-out', zIndex: 9999}} fullscreen onHide={() => {hideCityListModal()}}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 class="fw-bold mb-0">Select Location</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body class="modal-body p-0">
                    <div class="section full">
                        <div class="listview-title sticky-title" style={{top: 0}}>
                            <strong>A</strong>
                        </div>
                        <ul class="listview simple-listview">
                            <li>Aberdeen</li>
                            <li>Abiko</li>
                            <li>Abilene</li>
                            <li>Akashi</li>
                            <li>Akita</li>
                            <li>Albuquerque</li>
                            <li>Austin</li>
                            <li>Augsburg</li>
                            <li>Athens</li>
                        </ul>

                        <div class="listview-title sticky-title" style={{top: 0}}>
                            <strong>B</strong>
                            <a href="#" class="text-small text-primary">change</a>
                        </div>
                        <ul class="listview simple-listview">
                            <li>Barrie</li>
                            <li>Beaumont</li>
                            <li>Belfast</li>
                            <li>Berlin</li>
                            <li>Birmingham</li>
                        </ul>

                        <div class="listview-title sticky-title" style={{top: 0}}>
                            <strong>C</strong>
                            <span class="text-small">6 items</span>
                        </div>
                        <ul class="listview simple-listview">
                            <li>Caen</li>
                            <li>Cairns</li>
                            <li>Chigasaki</li>
                            <li>Clarksville</li>
                        </ul>

                        <div class="listview-title sticky-title" style={{top: 0}}>
                            <strong>D</strong>
                            <span class="text-small">Usable for every listview</span>
                        </div>
                        <ul class="listview image-listview">
                            <li>
                                <a href="#" class="item">
                                    <div class="icon-box bg-primary">
                                        <ion-icon name="image-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        Photos
                                        <span class="badge badge-danger">10</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="item">
                                    <div class="icon-box bg-secondary">
                                        <ion-icon name="videocam-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Videos</div>
                                        <span class="text-muted">None</span>
                                    </div>
                                </a>
                            </li>
                            <li>
                                <a href="#" class="item">
                                    <div class="icon-box bg-danger">
                                        <ion-icon name="musical-notes-outline"></ion-icon>
                                    </div>
                                    <div class="in">
                                        <div>Music</div>
                                    </div>
                                </a>
                            </li>
                        </ul>

                        <div class="listview-title sticky-title" style={{top: 0}}>
                            <strong>E</strong>
                        </div>
                        <ul class="listview simple-listview">
                            <li>Eastbourne</li>
                            <li>Edinburgh</li>
                            <li>Edmonton</li>
                            <li>Eindhoven</li>
                            <li>Elche</li>
                            <li>Engels</li>
                            <li>Espoo</li>
                            <li>Etawah</li>
                        </ul>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ cityList }) => {
    const {
        cityListModalVisible
    } = cityList
    return {
        cityListModalVisible
    }
  }
  
  const mapDispatchToProps = {
    hideCityListModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(MyBusinessModal)