import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    cardID: null,
    itemID: null,
    businessID: null
}

export const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    editItemData: (state, action) => {
      const {
        cardID,
        itemID
      } = action.payload
      state.cardID = cardID
      state.itemID = itemID
    },
    setBusinessID: (state, action) => {
      const {
        businessID
      } = action.payload
      state.businessID = businessID
    }
  }
})

export const { editItemData, setBusinessID } = dataSlice.actions;


export default dataSlice.reducer
