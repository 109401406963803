import React, { useState, useEffect, useRef } from 'react'
import QrScanner from 'qr-scanner'
import './QrScanner.css'
import { connect } from 'react-redux'
import {
  setQrOffCanvas,
  setCustomerName,
  setUpiUrl,
  setUpiID
} from '../../../features/offcanvas/QrSlice'
import axios from '../../../AxiosConfig'
import { showEditBusinessViewModal } from '../../../features/offcanvas/EditBusinessSlice'
import { extractUpiId } from '../../../features/utilities/upiUtils'
const QRScannerComponent = ({
  setQrOffCanvas,
  QrOffCanvas,
  showEditBusinessViewModal,
  setCustomerName,
  customerName,
  setUpiUrl,
  setUpiID,
  userCurrentLat,
  userCurrentLong,
  role
}) => {
  const [errorMessage, setErrorMessage] = useState('')
  const [hasScanned, setHasScanned] = useState(false) // Flag to track if a scan has been processed

  async function getQRdeatils(data) {
    const qrinfo = await axios.post(`/api/payments/validate/vpa`, data)
    return qrinfo
  }
  useEffect(() => {
    const video = document.getElementById('qr-video')
    const handleScan = async result => {
      if (result && !hasScanned) {
        const dataString = result.data

        console.log(dataString)
        if (typeof dataString === 'string' && dataString.startsWith('upi')) {
          const extractedUpiId = extractUpiId(dataString)
          setUpiID(extractedUpiId)
          setUpiUrl(dataString)
          setTimeout(() => {
            setErrorMessage('')
          }, 3000)
          // setErrorMessage('')
          axios
            .post(`/api/payments/validate/vpa`, {
              upiString: dataString
            })
            .then(res => {
              console.log(res.data.message)
              if (res.data.message === 'VPA already registered') {
                setErrorMessage('Bussiness Already Exists')
              } else {
                if (res) {
                  setCustomerName(res.data.customer_name)
                  setQrOffCanvas(false)
                  setHasScanned(true) // Set the flag to true after a successful scan
                  showEditBusinessViewModal({
                    editBusinessOffcanvasVisible: true,
                    actionType: 'update'
                  })
                }
              }
            })
            .catch(error => {
              // Handle error
              console.error('Error:', error)
            })
        } else {
          setErrorMessage('Invalid QR code. Please scan a UPI QR code.')
          setTimeout(() => {
            setErrorMessage('')
          }, 3000)
          console.log('error')
        }
      }
    }

    const scanner = new QrScanner(video, result => handleScan(result), {
      onDecodeError: error => {
        // Handle decode error if needed
        console.log(error)
      },
      highlightScanRegion: false,
      highlightCodeOutline: false
    })

    function stopScan() {
      scanner.stop()
    }

    const startScan = async () => {
      try {
        // Request permission for accessing the camera
        const cameraStream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: { facingMode: 'environment' }
        })
        video.srcObject = cameraStream
        scanner.start()
      } catch (error) {
        console.error('Error accessing camera:', error)
        setErrorMessage(
          'Failed to access the camera. Please enable camera access in your device settings.'
        )
      }
    }

    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          position => {
            const coords = {
              latitude: position.coords.latitude,
              longitude: position.coords.longitude
            }
            const longLat = []
            longLat.push(coords.latitude)
            longLat.push(coords.longitude)
            console.log(longLat)
          },
          error => {
            console.error('Error getting location:', error)
            setErrorMessage(
              'Failed to get location. Please enable location access in your device settings.'
            )
            setTimeout(() => {
              setErrorMessage('')
              getLocation()
            }, 3000)
          }
        )
      } else {
        setErrorMessage('Geolocation is not supported by this browser.')
      }
    }

    startScan()
    getLocation()

    return () => {
      scanner.stop()
    }
  }, [hasScanned]) // Include hasScanned in the dependency array

  return (
    <div className="videoContainer">
      <video id="qr-video"></video>
      <div className="frame">
        <p>Scan your UPI QR Code to create business.</p>
      </div>
      <div className="div1">
        {errorMessage && (
          <div className="errorMsg">
            <ion-icon
              className="errorIcon"
              name="alert-circle-outline"
            ></ion-icon>
            <h3>{errorMessage}</h3>
          </div>
        )}
        {/* <h3 style={{ color: 'black' }}>{result}</h3> */}
      </div>
    </div>
  )
}

const mapStateToProps = ({ myBusiness, auth }) => {
  const { userCurrentLat, userCurrentLong } = myBusiness
  const { role } = auth
  return {
    userCurrentLat,
    userCurrentLong,
    role
  }
}

const mapDispatchToProps = {
  showEditBusinessViewModal,
  setCustomerName,
  setUpiUrl,
  setUpiID
}

export default connect(mapStateToProps, mapDispatchToProps)(QRScannerComponent)
