import React, { useRef } from 'react';
import './UsersChatList.css';
import { connect } from 'react-redux'
import { showChatThreadViewOffcanvas, hideChatViewOffcanvas } from '../../../features/offcanvas/ChatViewSlice';

function UsersChatsList({ 
    showChatThreadViewOffcanvas,
    hideChatViewOffcanvas
 }) {
    const viewChatThread = () => {
        showChatThreadViewOffcanvas({chatThreadViewOffcanvasVisible: true})
    }
    const touchStartX = useRef(null)
  const touchEndX = useRef(null)

  const handleTouchStart = e => {
    touchStartX.current = e.touches[0].clientX
  }

  const handleTouchEnd = e => {
    touchEndX.current = e.changedTouches[0].clientX
    handleSwipe()
  }

  const handleSwipe = () => {
    const swipeDistance = touchStartX.current - touchEndX.current
    const swipeThreshold = 50 // Adjust as needed

    if (swipeDistance > swipeThreshold) {
      console.log('Swiped left')
    } else if (swipeDistance < -swipeThreshold) {
      console.log('Swiped right')
      hideChatViewOffcanvas()
    }
  }
    return (
        <div onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
            {/* <div className='UsersChatsListView' onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                <ul className="listview image-listview">
                    <li>
                        <a href="javascript:void(0)" onClick={viewChatThread} className="item active">
                            <img src="assets/img/apps/whatsapp.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Business Name
                                    <header>Category 
                                        <span className='timeAgo'>
                                            <ion-icon name="location-outline"></ion-icon>
                                            Location
                                        </span>
                                    </header>
                                </div>
                            </div>
                            <span class="badge badge-success">52</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={viewChatThread} className="item" aria-current="false">
                            <img src="assets/img/apps/instagram.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Business Name
                                    <header>Category 
                                        <span className='timeAgo'>
                                            <ion-icon name="location-outline"></ion-icon>
                                            Location
                                        </span>
                                    </header>
                                </div>
                            </div>
                            <span class="badge badge-success">10</span>

                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={viewChatThread} className="item">
                            <img src="assets/img/apps/facebook.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Business Name
                                    <header>Category 
                                        <span className='timeAgo'>
                                            <ion-icon name="location-outline"></ion-icon>
                                            Location
                                        </span>
                                    </header>
                                </div>
                            </div>
                            <span class="badge badge-success">2</span>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={viewChatThread} className="item">
                            <img src="assets/img/webweb.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Business Name
                                    <header>Category 
                                        <span className='timeAgo'>
                                            <ion-icon name="location-outline"></ion-icon>
                                            Location
                                        </span>
                                    </header>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={viewChatThread} className="item">
                            <img src="assets/img/apps/whatsapp.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Business Name
                                    <header>Category 
                                        <span className='timeAgo'>
                                            <ion-icon name="location-outline"></ion-icon>
                                            Location
                                        </span>
                                    </header>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={viewChatThread} className="item">
                            <img src="assets/img/apps/instagram.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Business Name
                                    <header>Category 
                                        <span className='timeAgo'>
                                            <ion-icon name="location-outline"></ion-icon>
                                            Location
                                        </span>
                                    </header>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={viewChatThread} className="item">
                            <img src="assets/img/apps/facebook.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Business Name
                                    <header>Category 
                                        <span className='timeAgo'>
                                            <ion-icon name="location-outline"></ion-icon>
                                            Location
                                        </span>
                                    </header>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={viewChatThread} className="item">
                            <img src="assets/img/webweb.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Business Name
                                    <header>Category 
                                        <span className='timeAgo'>
                                            <ion-icon name="location-outline"></ion-icon>
                                            Location
                                        </span>
                                    </header>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={viewChatThread} className="item">
                            <img src="assets/img/apps/whatsapp.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Business Name
                                    <header>Category 
                                        <span className='timeAgo'>
                                            <ion-icon name="location-outline"></ion-icon>
                                            Location
                                        </span>
                                    </header>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={viewChatThread} className="item">
                            <img src="assets/img/apps/instagram.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Business Name
                                    <header>Category 
                                        <span className='timeAgo'>
                                            <ion-icon name="location-outline"></ion-icon>
                                            Location
                                        </span>
                                    </header>
                                </div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="javascript:void(0)" onClick={viewChatThread} className="item">
                            <img src="assets/img/apps/facebook.svg" alt="image" className="image" />
                            <div className="in">
                                <div>
                                    Business Name
                                    <header>Category 
                                        <span className='timeAgo'>
                                            <ion-icon name="location-outline"></ion-icon>
                                            Location
                                        </span>
                                    </header>
                                </div>
                            </div>
                        </a>
                    </li>
                </ul>
            </div> */}
            <h2 class="d-flex justify-content-center align-items-center text-danger mt-2">Coming Soon</h2>
        </div>
    )
}

const mapStateToProps = () => {
    return {
    }
}
  
const mapDispatchToProps = {
    showChatThreadViewOffcanvas,
    hideChatViewOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersChatsList)