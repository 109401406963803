import React, { useState, useEffect } from 'react'
import './useColorPicker.css'

export function useColorPicker({
    DefaultColors = ["#1e74fd", "#34c759", "#ec4433", "#9adbf5", "#6a5acd", "#9dc183", "#86608e", "#daa3b8", "#d3d3d3", "#6b8e23", "#a8695a", "#e2725b", "#708090", "#c4c3d0", "#ffbf00", "#fa8072"],
    CurrentColor,
    DynamicColors = [],
    onColorChanged,
    onFinalColorSelected,
    onCancelled,
    hideCancelButton = false,
    hideDoneButton = false
}) {
    const [ShowPicker, setShowPicker] = useState(false);
    const [selectedColor, setSelectedColor] = useState(CurrentColor);
    const [AllColors, setAllColors] = useState(DefaultColors);
    const [PrevColor, SetPrevColor] = useState(null);

    useEffect(() => {
      if(PrevColor === null) {
        SetPrevColor(CurrentColor);
      }
    }, [CurrentColor])

    useEffect(() => {
        if(DynamicColors?.length > 0) {
            const newColors = [...DefaultColors,...DynamicColors];
            setAllColors(newColors);
        }
    }, [DynamicColors]);

    const colorChange = (color) => {
        setSelectedColor(color);
        if(onColorChanged) onColorChanged(color);
    }

    const updateFinalColor = () => {
        if(onFinalColorSelected) onFinalColorSelected(selectedColor);
        setShowPicker(false);
    }

    const cancelColor = () => {
        setShowPicker(false);
        if(onCancelled) {
            onCancelled(PrevColor)
            SetPrevColor(null);
        }
    }

    const ColorPickerContainer = () => {
        return (
            <>
            {
                ShowPicker &&
                <>
                    <div
                        style={{
                            zIndex: 10,
                            position: 'absolute',
                            top: 11,
                            left: 10
                        }}
                        onClick={() => {
                            setShowPicker(false)
                        }}
                    >
                        <ion-icon
                            name="chevron-back-outline"
                            class="text-light fs-1"
                        ></ion-icon>
                    </div>
                    <div
                        class="d-flex justify-content-center align-items-center"
                        style={{
                            background: 'rgba(0,0,0,0)',
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            zIndex: 11
                        }}
                    >
                        <div
                            class="card w-custom-desktop"
                            style={{ background: 'rgb(255,255,255)' }}
                        >
                            <div class="card-body">
                                <div class="container">
                                    <div class="row">
                                        {
                                            AllColors.map((color, i) => {
                                                return (
                                                    <div key={i} class="col-2 col-md-2 mb-1 d-flex justify-content-center">
                                                        <div
                                                            className={`d-flex justify-content-center align-items-center`}
                                                            onClick={() => {
                                                                colorChange(color)
                                                            }}
                                                            style={{ height: '40px', width: '40px', backgroundColor: color }}
                                                        >
                                                            {CurrentColor === color && (
                                                                <ion-icon
                                                                    name="checkmark-outline"
                                                                    class="text-light fs-3"
                                                                ></ion-icon>
                                                            )}
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                    </div>
                                    {
                                        (!hideCancelButton || !hideDoneButton) &&
                                        <div class="row">
                                            <div class="col-12 col-md-12 d-flex justify-content-center">
                                                {
                                                    !hideCancelButton &&
                                                    <button
                                                        class="btn btn-outline-secondary mt-1 me-1"
                                                        onClick={() => {
                                                            cancelColor()
                                                        }}
                                                    >
                                                        Cancel
                                                    </button>
                                                }
                                                {
                                                    !hideDoneButton &&
                                                    <button
                                                        class="btn btn-success mt-1"
                                                        onClick={() => {
                                                            updateFinalColor()
                                                        }}
                                                    >
                                                        Done
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
            </>
        )
    }
    return { ColorPickerContainer, selectedColor, ShowPicker, setShowPicker }
}