import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import jwt_decode from 'jwt-decode'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Find from '../../Pages/Find'
import { hideFindViewOffcanvas } from '../../../features/offcanvas/FindViewSlice'
import { logout } from '../../../features/auth/authSlice'
import { setQrOffCanvas } from '../../../features/offcanvas/QrSlice'
import { useLocationHook } from '../../../hooks/useLocationHook'
// import QRPage from '../../Pages/QrPage';
const FindViewOffcanvas = ({
  findViewOffcanvasVisible,
  hideFindViewOffcanvas,
  setQrOffCanvas,
  logout,
  List,
  pageIndex
}) => {
  // console.log(findViewOffcanvasVisible)
  const { getUserLocation, userCurrentLocation, userCurrentCountry } =
    useLocationHook()
  // const [location, setLocation] = useState('')
  const [userName, setUserName] = useState('')
  // const [showQRPage, setShowQRPage] = useState(false);
  const token = localStorage.getItem('token')
  const tokenData = token ? jwt_decode(token) : false
  useEffect(() => {
    if (findViewOffcanvasVisible) {
      getUserLocation()
    }
    // const fetchLocation = async () => {
    //   if ('geolocation' in navigator) {
    //     try {
    //       const position = await new Promise((resolve, reject) => {
    //         navigator.geolocation.getCurrentPosition(resolve, reject)
    //       })

    //       const { latitude, longitude } = position.coords

    //       const response = await fetch(
    //         `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    //       )
    //       const data = await response.json()

    //       const city =
    //         data.address.city ||
    //         data.address.town ||
    //         data.address.village ||
    //         data.address.hamlet ||
    //         ''
    //       const country = data.address.country || ''
    //       setLocation(city + ',' + country)
    //       console.log(data)
    //     } catch (error) {
    //       console.error('Error getting location:', error.message)
    //     }
    //   } else {
    //     console.error('Geolocation is not supported by this browser.')
    //   }
    // }
    // fetchLocation()
  }, [findViewOffcanvasVisible])
  // const handleBackdropClick = (e) => {
  //   if (e.target.classList.contains('offcanvas-backdrop')) {
  //       hideFindViewOffcanvas();
  //   }
  // };

  useEffect(() => {
    if (tokenData != null && tokenData != undefined && tokenData != '') {
      setUserName(tokenData.userInfo.FullName)
    }
  }, [tokenData])
  const handleLogout = () => {
    hideFindViewOffcanvas()
    logout()
  }
  // const handleIconClick = () => {
  //   setShowQRPage(true);
  //   hideFindViewOffcanvas();
  //   console.log("open");
  // };
  return (
    <div>
      <Offcanvas
        show={findViewOffcanvasVisible}
        onHide={() => {
          hideFindViewOffcanvas()
        }}
        style={{ transition: '0.2s ease-in-out' }}
      >
        <div class="profileBox bg-primary d-flex justify-content-between">
          <div class="d-flex">
            <div class="image-wrapper me-1 pt-1 pb-1">
              <ion-icon
                name="person-circle-outline"
                class="rounded"
                style={{ fontSize: '40px' }}
              ></ion-icon>
              {/* <img src="assets/img/sample/avatar/avatar1.jpg" alt="image" class="imaged rounded" /> */}
            </div>
            <div class="in pt-1 pb-1">
              <strong>{userName}</strong>
              <div class="text-muted">
                {userCurrentLocation && userCurrentLocation !== '' && (
                  <>
                    <ion-icon name="location"></ion-icon>
                    {userCurrentLocation}, {userCurrentCountry}
                  </>
                )}
              </div>
            </div>
          </div>
          <div style={{ cursor: 'pointer' }}>
            <ion-icon name="qr-code-outline" class="fs-2"></ion-icon>
          </div>
        </div>

        <div
          style={{
            marginTop: '60px',
            height: '100vh',
            overflowY: 'scroll',
            overflowX: 'hidden'
          }}
        >
          <Find List={List} pageIndex={pageIndex}></Find>
        </div>
        <div class="sidebar-buttons">
          <a
            href="https://wa.me/8898683883"
            target="_blank"
            class="button flex-column"
          >
            <ion-icon name="headset-outline"></ion-icon>
            <span class="fs-10">Support</span>
          </a>
          <a
            href="javascript:void(0)"
            onClick={() => {
              handleLogout()
            }}
            class="button flex-column"
          >
            <ion-icon name="log-out-outline"></ion-icon>
            <span class="fs-10">Logout</span>
          </a>
        </div>
      </Offcanvas>
    </div>
  )
}

const mapStateToProps = ({ findView }) => {
  const { findViewOffcanvasVisible } = findView
  return {
    findViewOffcanvasVisible
  }
}

const mapDispatchToProps = {
  hideFindViewOffcanvas,
  setQrOffCanvas,
  logout
}

export default connect(mapStateToProps, mapDispatchToProps)(FindViewOffcanvas)
