import React from 'react'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { Splide, SplideSlide } from '@splidejs/react-splide'

function LoaderCard({ button = '#a9965e' }) {
    return (
        <div class="card border w-100 splide__slide is-active w-custom-desktop" style={{ marginTop: '-30px' }}>
            <div class="card-body">
                <div class="d-flex justify-content-center align-items-center placeholder-glow">
                    <span class="placeholder imaged w-100" style={{ height: '64px' }}></span>
                </div>
                <div class="d-flex justify-content-center flex-column align-items-center placeholder-glow">
                    <h2 class="placeholder h5 fw-bold text-center mb-0 mt-2" style={{ width: '220px' }}>
                        BusinessName
                    </h2>
                    <h3 class="placeholder h6 text-muted text-center mb-0 mt-1" style={{ height: '10', width: '70px' }}>
                        C
                    </h3>
                    <h3 class="placeholder h6 text-muted fw-bold text-center mb-0 mt-1 w-100">
                        Description
                    </h3>
                    <h3 class="placeholder h6 text-muted fw-bold text-center mb-0 mt-1 w-100">
                        Description
                    </h3>
                </div>
                <div
                    class="pb-3 mt-2 placeholder-glow"
                    style={{ marginLeft: '-8px', marginRight: '-8px' }}
                >
                    <Splide
                        options={{
                            type: 'loop',
                            rewind: true,
                            gap: 10,
                            padding: '3rem',
                            autoplay: true,
                            interval: 3000,
                            pagination: false,
                            arrows: true,
                            breakpoints: {
                                700: {
                                    perPage: 1,
                                    perMove: 1
                                },
                                1200: {
                                    perPage: 1,
                                    perMove: 1
                                },
                                1600: {
                                    perPage: 1,
                                    perMove: 1
                                },
                                2000: {
                                    perPage: 2,
                                    perMove: 1
                                }
                            }
                        }}
                        className={'custom-loader-scale'}
                        // onTouchStart={handleTouchStartChild}
                        // onTouchEnd={handleTouchEndChild}
                    >
                        {[1, 2, 3, 4, 5].map((i, j) => {
                            return (
                                <SplideSlide key={j}>
                                    <div className="card text-white mb-2" style={{ height: '160px', background: 'lightgray' }}>
                                    </div>
                                    <h4 class="fw-bold mb-0 mt-1 text-center">
                                        <span class="placeholder" style={{ width: '100px' }}>
                                            {j}
                                        </span>
                                    </h4>
                                </SplideSlide>
                            )
                        })}
                    </Splide>
                </div>
                <div class="row mt-2">
                    <div class="col-12 mb-0 mt-1 d-flex justify-content-center align-items-center mb-1 placeholder-glow">
                        {/* <ion-icon
                      name="location-outline"
                      class="text-dark fs-6"
                    ></ion-icon>{' '} */}
                        <span class="placeholder"></span>
                    </div>
                    <div class="col-12">
                        <button class="btn w-100 text-nowrap btn-block text-light placeholder moreDetailsButton" style={{ background: button }} disabled>
                        </button>
                    </div>
                </div>
                <div style={{ position: 'absolute', bottom: '-40px', left: '50%', transform: 'translate(-50%, 10%)', width: '100%'}}>
                    <div class="row placeholder-glow">
                        <div class="col-6 col-md-6 d-flex align-items-center">
                            <span class="placeholder">Recommend</span>
                        </div>
                        <div class="col-6 col-md-6 d-flex justify-content-end">
                            <ul class="p-0 d-flex mb-0" style={{ listStyleType: 'none' }}>
                                <li class="me-2 placeholder rounded-circle" style={{ height: '35px', width: '35px' }}>
                                </li>
                                <li class="me-2 placeholder rounded-circle" style={{ height: '35px', width: '35px' }}>
                                </li>
                                <li class="placeholder rounded-circle" style={{ height: '35px', width: '35px' }}>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LoaderCard