import { createSlice } from '@reduxjs/toolkit'
import { FILTERLIST } from '../../config'
const initialState = {
    findViewOffcanvasVisible: false,
    selectedFilter : FILTERLIST.FILTER6,
    ListFiltered : null,
    ListFilteredPosition : 0,
}

export const FindViewSlice = createSlice({
  name: 'findView',
  initialState,
  reducers: {
    showFindViewOffcanvas: (state, action) => {
      const {
        findViewOffcanvasVisible
      } = action.payload
      state.findViewOffcanvasVisible = findViewOffcanvasVisible
    },
    hideFindViewOffcanvas: (state, action) => {
      state.findViewOffcanvasVisible = false
    },
    setSelectedFilter: (state, action) => {
      state.selectedFilter = action.payload
    },
    setListFiltered: (state, action) => {
      state.ListFiltered = action.payload;
    },
    setFilteredPosition: (state, action) => {
      state.ListFilteredPosition = action.payload
    }
  }
})

export const { showFindViewOffcanvas, hideFindViewOffcanvas, setSelectedFilter, setListFiltered, setFilteredPosition } = FindViewSlice.actions;


export default FindViewSlice.reducer
