import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    cityListModalVisible: false
}

export const CityListSlice = createSlice({
  name: 'cityList',
  initialState,
  reducers: {
    showCityListModal: (state, action) => {
      const {
        cityListModalVisible
      } = action.payload
      state.cityListModalVisible = cityListModalVisible
    },
    hideCityListModal: (state, action) => {
      state.cityListModalVisible = false
    }
  }
})

export const { showCityListModal, hideCityListModal } = CityListSlice.actions;


export default CityListSlice.reducer
