import React, { useEffect } from 'react'
import axios from '../../../AxiosConfig'
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux'

const CreateProfileModal = ({
  profileModalVisible
}) => {
  useEffect(() => {
    axios.get("/users").then((data) => {
      // console.log(data);
    });
  }, [])
  return (
    <Modal show={false} fullscreen={true}>
      <Modal.Body>
        <div id="appCapsule">
          <div class="section full d-flex justify-content-center flex-column align-items-center">
            <h2>Choose your profile</h2>
            <div
              class=""
              role="group"
              aria-label="Basic radio toggle button group"
            >
              <div class="row">
                <div class="col-6 col-md-6">
                  <input
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    id="btnradio1"
                  />
                  <label
                    class="btn btn-outline-primary text-nowrap h-auto w-100 mb-2"
                    for="btnradio1"
                  >
                    <div class="card shadow-none bg-transparent w-100">
                      <div class="card-body fs-6">Businessman</div>
                    </div>
                  </label>
                </div>
                <div class="col-6 col-md-6">
                  <input
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    id="btnradio2"
                  />
                  <label
                    class="btn btn-outline-primary text-nowrap h-auto w-100 mb-2"
                    for="btnradio2"
                  >
                    <div class="card shadow-none bg-transparent w-100">
                      <div class="card-body fs-6">Professional</div>
                    </div>
                  </label>
                </div>
                <div class="col-6 col-md-6">
                  <input
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    id="btnradio3"
                  />
                  <label
                    class="btn btn-outline-primary text-nowrap h-auto w-100 mb-2"
                    for="btnradio3"
                  >
                    <div class="card shadow-none bg-transparent w-100">
                      <div class="card-body fs-6">Job Seeker</div>
                    </div>
                  </label>
                </div>
                <div class="col-6 col-md-6">
                  <input
                    type="radio"
                    class="btn-check"
                    name="btnradio"
                    id="btnradio4"
                  />
                  <label
                    class="btn btn-outline-primary text-nowrap h-auto w-100 mb-2"
                    for="btnradio4"
                  >
                    <div class="card shadow-none bg-transparent w-100">
                      <div class="card-body fs-6">Consumer</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>

        <div class="carousel-button-footer">
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-6">
              <a
                href="#"
                class="btn btn-primary btn-lg btn-block"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

const mapStateToProps = ({ profileModal }) => {
  const {
    profileModalVisible
  } = profileModal
  return {
    profileModalVisible
  }
}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(CreateProfileModal)
