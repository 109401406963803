import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import axios from '../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import PlaceholderImage from '../images/placeholder/160x160-00000000.png'
import { showDetailViewModal } from '../../features/offcanvas/DetailViewSlice'
import ShareOffcanvas from '../layout/Sidebar/ShareOffcanvas'
import { setNanoWebsiteData } from '../../features/nanoWebsite/nanoWebsiteSlice'
import LoaderCard from './Comps/LoaderCard'
import {
  setBizCategory,
  setBizLogo,
  setBizBanner
} from '../../features/offcanvas/MyBusinessSlice'
import {
  showChatThreadViewOffcanvas,
  chatConversation,
  setChatViewId,
  setChatBuisnessData,
  getConvoID
} from '../../features/offcanvas/ChatViewSlice'
import { showAuthModal, hideAuthModal } from '../../features/auth/authSlice'
import { setBusinessID } from '../../features/data/dataSlice'
import '../Pages/NanoWebsite.css'
import TextualComp from './Comps/TextualComp'
const NanoWebsite = ({
  data,
  button,
  showDetailViewModal,
  authToken,
  nanoWebsiteData,
  setNanoWebsiteData,
  showChatThreadViewOffcanvas,
  chatConversation,
  setChatViewId,
  setChatBuisnessData,
  setBizCategory,
  BizCategory,
  setBizLogo,
  BizBanner,
  setBizBanner,
  BizLogo,
  getConvoID,
  USERID,
  showAuthModal,
  hideAuthModal,
  setBusinessID
}) => {
  const [businessCardData, setBusinessCardData] = useState(null)
  const [loader, setLoader] = useState(true)
  // const [shareLoader,setShareLoader] = useState(false)
  // const [chatLoader,setChatLoader] = useState(false)
  // const [bookmarkLoader,setBookmarkLoader] = useState(false)
  const [windowWidth, setWindowWidth] = useState(0)
  const [customMargin, setCustomMargin] = useState('')
  const [customIconSize, setCustomIconSize] = useState('')
  const [displayBanner, setdisplayBanner] = useState(false)
  // const [recommendLoader, setRecommendLoader] = useState(false)
  // const [recommendationCount, setRecommendationCount] = useState(0)
  let navigate = useNavigate()
  const innerSplideRef = useRef(null)
  const token = authToken ? jwt_decode(authToken) : false
  // const token = jwt_decode(authToken)
  const handleTouchStartChild = e => {
    e.stopPropagation()
  }
  const handleTouchEndChild = e => {
    e.stopPropagation()
  }
  useEffect(() => {
    if (data) {
      setBusinessCardData(data.Content ? data.Content : [])
    }
  }, [data])
  useEffect(() => {
    if (data) {
      setTimeout(() => {
        setLoader(false)
      }, 2500)
    }
  }, [data])
  const handleDetailViewModal = (url, data) => {
    if (authToken && authToken != '') {
      if (data && data.Type == 'whatsapp') {
        window.open('https://wa.me/' + data.URL, '_blank')
      } else if (data && data.Type == 'form') {
        showDetailViewModal({ detailViewModalVisible: true, linkUrl: 'form' })
      } else {
        showDetailViewModal({ detailViewModalVisible: true, linkUrl: url })
      }
    } else {
      hideAuthModal()
      setTimeout(() => {
        showAuthModal({ authModalVisible: true })
      }, 1)
    }
  }
  const shareButtonClick = (e, data) => {
    console.log(window.location.href)
    e.stopPropagation()
    if (authToken != undefined && authToken != null && authToken != '') {
      if (navigator.share) {
        navigator
          .share({
            title: 'Share Link',
            url: `${window.location.origin}/${data.ShareableURL}`
          })
          .then(() => {})
          .catch(console.error)
      }

      const shareData = {
        UserID: USERID,
        BusinessID: data?.ID
      }

      axios.post('/action/share', shareData).then(res => {
        if (res.data.message === 'Success') {
          // setShareLoader(false)
        }
      })
    } else {
      showAuthModal({ authModalVisible: true })
      // alert('Login')
    }
  }
  const chatButtonClick = async (e, data) => {
    e.stopPropagation()
    if (authToken != undefined && authToken != null && authToken != '') {
      // setChatLoader(true)
      const chatData = {
        UserID: USERID,
        BusinessID: data?.ID
      }
      showChatThreadViewOffcanvas({ chatThreadViewOffcanvasVisible: true })
      axios.post('/action/chat', chatData).then(res => {
        if (res.data.message === 'Success') {
          // setChatLoader(false)
          // window.open(res.data.data, '_blank');
        }
      })
      const chatID = await getConvoID(chatData)
      if (chatID.payload) {
        setChatViewId(chatID.payload)
        chatConversation({ ID: chatID.payload })
      }

      // axios.post('/chats/getUserBuisnessConversationId', chatData).then(res => {
      //   if (res.data.message === 'Success') {
      //     chatConversation({ID: res.data.data})
      //     setChatViewId(res.data.data)
      //     // setChatBuisnessData(data);
      //   }
      // })
    } else {
      showAuthModal({ authModalVisible: true })
    }
  }
  const bookmarkButtonClick = (e, data) => {
    e.stopPropagation()
    if (authToken != undefined && authToken != null && authToken != '') {
      // setBookmarkLoader(true)
      const bookmarkData = {
        UserID: USERID,
        BusinessID: data?.ID,
        Status: !data?.BookmarkStatus
      }
      let tempNanoWebsite = JSON.parse(JSON.stringify(nanoWebsiteData))
      tempNanoWebsite = tempNanoWebsite.map((v, k) => {
        if (data?.ID == v.ID) {
          v.BookmarkStatus = !data?.BookmarkStatus
          // tempNanoWebsite[k] = v
        }
        return v
      })
      setNanoWebsiteData({ nanoWebsiteData: tempNanoWebsite, bufferData: null })
      axios.post('/bookmark', bookmarkData).then(res => {
        if (res.data.message === 'Success') {
          // setBookmarkLoader(false)
        }
      })
    } else {
      showAuthModal({ authModalVisible: true })
    }
  }
  const recommendButtonClick = (e, data) => {
    e.stopPropagation()
    if (authToken != undefined && authToken != null && authToken != '') {
      let recommendationCount = data.Recommendation
      // setRecommendLoader(true)
      if (data.RecommendationStatus == false) {
        recommendationCount = recommendationCount + 1
      } else {
        recommendationCount = recommendationCount - 1
      }
      const recommendData = {
        UserID: USERID,
        BusinessID: data?.ID,
        Status: !data?.RecommendationStatus,
        Recommendation: recommendationCount
      }
      let tempNanoWebsite = JSON.parse(JSON.stringify(nanoWebsiteData))
      tempNanoWebsite = tempNanoWebsite.map((v, k) => {
        if (data?.ID == v.ID) {
          v.RecommendationStatus = !data?.RecommendationStatus
          v.Recommendation = recommendationCount
          // tempNanoWebsite[k] = v
        }
        return v
      })
      setNanoWebsiteData({ nanoWebsiteData: tempNanoWebsite, bufferData: null })
      axios.post('/recommend', recommendData).then(res => {
        if (res.data.message === 'Success') {
          // setRecommendLoader(false)
        }
      })
    } else {
      showAuthModal({ authModalVisible: true })
    }
  }
  const handleActionButton = async (e, data) => {
    e.stopPropagation()
    if (authToken != undefined && authToken != null && authToken != '') {
      handleDetailViewModal(
        data?.ActionButton ? data.ActionButton.URL : data?.URL,
        data.ActionButton
      )
      const actionButtonData = {
        UserID: USERID,
        BusinessID: data?.ID
      }
      setBusinessID({ businessID: data?.ID })
      axios.post('/action/cta', actionButtonData).then(res => {
        if (res.data.message === 'Success') {
        }
      })

      const chatID = await getConvoID(actionButtonData)
      if (chatID.payload) {
        setChatViewId(chatID.payload)
        chatConversation({ ID: chatID.payload })
      }
    } else {
      showAuthModal({ authModalVisible: true })
    }
  }
  useEffect(() => {
    setWindowWidth(window.innerWidth)
  }, [])

  useEffect(() => {
    if (windowWidth < 700) {
      setCustomMargin('me-2')
      setCustomIconSize('fs-1')
    } else {
      setCustomMargin('me-3')
      setCustomIconSize('fs-5')
    }
  }, [windowWidth])
  const getBackgroundBanner = () => {}
  useEffect(() => {
    document.addEventListener('keydown', function (event) {
      const keyType = event.keyCode
      if (keyType == 39) {
        if (innerSplideRef.current) {
          innerSplideRef.current.go(innerSplideRef.current.splide.index + 1)
        }
      } else if (keyType == 37) {
        if (innerSplideRef.current) {
          innerSplideRef.current.go(innerSplideRef.current.splide.index - 1)
        }
      }
    })
  }, [])
  const colors = [
    '#faedcb',
    '#c9e4de',
    '#c6def1',
    '#dbcdf0',
    '#f2c6de',
    '#f7d9c4',
    '#ffe4c4',
    '#a9eadc',
    '#d1c0ff',
    '#ffdab9',
    '#b0e0e6',
    '#f0e68c',
    '#afeeee',
    '#ffb6c1',
    '#c0c0c0',
    '#87ceeb'
  ]
  // console.log(BizBanner)
  function getRandomColor() {
    const randomIndex = Math.floor(Math.random() * colors.length)
    return colors[randomIndex]
  }
  function getRandomNum() {
    const randomIndex = Math.floor(Math.random() * 5)
    return randomIndex
  }
  // axios.get(`/categories/${BizCategory}`).then(res => {
  //   setoverlayImage(res.data.data.Background)
  // })
  return (
    <>
      <div class="container">
        <div
          class="section d-flex justify-content-center align-items-center vh100-height"
          style={{ padding: '0px 24px' }}
        >
          {!loader && (
            <div
              class="card border w-100 w-custom-desktop"
              style={{ marginTop: '-30px' }}
            >
              <div class="card-body pt-2 pb-2">
                {data?.BusinessLogo != null &&
                  data?.BusinessLogo != undefined &&
                  data?.BusinessLogo != '' && (
                    <div class="d-flex justify-content-center align-items-center">
                      <img
                        src={data?.BusinessLogo}
                        alt="avatar"
                        class="imaged"
                        style={{
                          height: '64px'
                        }}
                      />
                    </div>
                  )}
                {data?.BusinessLogo === null && (
                  <div class="d-flex justify-content-center align-items-center">
                    <img
                      src={BizLogo}
                      alt="avatar"
                      class="imaged"
                      style={{
                        height: '64px'
                      }}
                    />
                  </div>
                )}
                <h2 class="h5 fw-bold text-center mb-0 mt-2">
                  {data?.BusinessName}
                </h2>
                <h3 class="h6 text-muted text-center mb-0 mt-1">
                  {data?.Description}
                </h3>
                {businessCardData?.length > 0 && (
                  <div
                    class="pb-2 pt-2 mt-2"
                    style={{ marginLeft: '-8px', marginRight: '-8px' }}
                  >
                    <Splide
                      ref={innerSplideRef}
                      options={{
                        type: 'loop',
                        rewind: true,
                        gap: 10,
                        padding: '3rem',
                        autoplay: true,
                        interval: 3000,
                        pagination: false,
                        arrows: true,
                        breakpoints: {
                          700: {
                            perPage: 1,
                            perMove: 1
                          },
                          1200: {
                            perPage: 1,
                            perMove: 1
                          },
                          1600: {
                            perPage: 1,
                            perMove: 1
                          },
                          2000: {
                            perPage: 2,
                            perMove: 1
                          }
                        }
                      }}
                      // onMove={handleMoveChild}
                      onTouchStart={handleTouchStartChild}
                      onTouchEnd={handleTouchEndChild}
                      className={'custom-card-scale'}
                    >
                      {businessCardData.map((i, j) => {
                        // setdisplayBanner(true)
                        return (
                          <SplideSlide key={j}>
                            <div className="card text-white mb-1">
                              <div class="image-wrapper-responsive">
                                {i.image.includes('#') && (
                                  <div>
                                    <div
                                      className="imgCont"
                                      style={{
                                        background: i.image,
                                        backgroundPosition: 'center',
                                        backgroundSize: '100%',
                                        zIndex: 0,
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0
                                      }}
                                    >
                                      <span className="imageText">
                                        {/* {' '} */}
                                        {/* {i?.title} */}
                                        {i.overlay}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {/* {getBackgroundBanner()} */}
                                {(i.image.includes('data:image') ||
                                  i.image.includes('http')) && (
                                  <div>
                                    <div
                                      className="imgCont"
                                      style={{
                                        backgroundImage: `url(${i.image})`,
                                        backgroundPosition: 'center',
                                        backgroundSize: '200%',
                                        zIndex: 0,
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0
                                      }}
                                    >
                                      <span className="imageText">
                                        {/* {' '} */}
                                        {/* {i?.title} */}
                                        {i.overlay}
                                      </span>
                                    </div>
                                  </div>
                                )}

                                {i.image.length === 0 && (
                                  <div>
                                    <div
                                      className="imgCont"
                                      style={{
                                        backgroundImage: BizBanner
                                          ? `url(${BizBanner[getRandomNum()]})`
                                          : 'none',
                                        backgroundPosition: 'center',
                                        backgroundSize: '100%',
                                        zIndex: 0,
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        right: 0,
                                        bottom: 0
                                      }}
                                    >
                                      <span className="imageText">
                                        {/* {' '} */}
                                        {/* {i?.title} */}
                                        {i.overlay}
                                      </span>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                            {i?.image?.length > 2 && (
                              <h4
                                class="fw-bold mb-0 mt-0 text-center"
                                style={{ fontSize: '13px' }}
                              >
                                {/* {i?.title} */}
                              </h4>
                            )}
                          </SplideSlide>
                        )
                      })}
                    </Splide>
                  </div>
                )}
                <div class="row mt-1">
                  <div
                    class="col-12 mt-1 d-flex justify-content-center align-items-center"
                    style={{ fontSize: '12px', marginBottom: '3px' }}
                  >
                    <ion-icon
                      name="location-outline"
                      class="text-dark"
                      style={{ fontSize: '12px' }}
                    ></ion-icon>{' '}
                    {data?.Location}
                  </div>
                  <div class="col-12">
                    <button
                      class="btn w-100 text-nowrap btn-block text-light moreDetailsButton"
                      data-bs-toggle="modal"
                      data-bs-target="#MoreDetailsModal"
                      style={{ background: button }}
                      onClick={e => {
                        handleActionButton(e, data)
                      }}
                    >
                      {/* onClick={() => {switchToDetailView(data?.URL)}} */}
                      {data?.ActionButton
                        ? data.ActionButton.Title
                        : 'View More'}
                      <ion-icon
                        name="chevron-forward-outline"
                        class="text-light fs-6 me-0"
                      ></ion-icon>
                    </button>
                  </div>
                  <div class="col-12 col-md-12">
                    <h6
                      class="mb-0 d-flex justify-content-center align-items-center"
                      style={{ marginTop: '3px' }}
                    >
                      <>
                        {data?.Recommendation > 0 && (
                          <>Recommended by {data?.Recommendation} people</>
                        )}
                        {data?.Recommendation <= 0 && <>No Recommendations</>}
                      </>
                    </h6>
                  </div>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '-32px',
                    left: '50%',
                    transform: 'translate(-50%, 10%)'
                  }}
                >
                  <div class="row w-custom-desktop">
                    <div class="col-6 col-md-6 text-light">
                      <ul class="p-0 d-flex" style={{ listStyleType: 'none' }}>
                        <li
                          class={`${customMargin} d-flex align-items-center`}
                          onClick={e => {
                            recommendButtonClick(e, data)
                          }}
                        >
                          <ion-icon
                            name={
                              data?.RecommendationStatus
                                ? 'checkmark-done-circle-outline'
                                : 'checkmark-circle-outline'
                            }
                            class={`text-light ${customIconSize}`}
                            style={{ marginRight: '3px' }}
                          ></ion-icon>
                          <>
                            {data?.RecommendationStatus && (
                              <span style={{ fontSize: '11px' }}>
                                Recommended
                              </span>
                            )}
                            {!data?.RecommendationStatus && (
                              <span style={{ fontSize: '11px' }}>
                                Recommend
                              </span>
                            )}
                          </>
                        </li>
                      </ul>
                    </div>
                    <div class="col-6 col-md-6 d-flex align-items-end justify-content-end">
                      <ul class="p-0 d-flex" style={{ listStyleType: 'none' }}>
                        <li
                          class={`${customMargin} d-flex align-items-center`}
                          onClick={e => {
                            shareButtonClick(e, data)
                          }}
                        >
                          <ion-icon
                            name="share-social-outline"
                            class={`text-light ${customIconSize}`}
                          ></ion-icon>
                        </li>
                        <li
                          class={`${customMargin} d-flex align-items-center`}
                          onClick={e => {
                            chatButtonClick(e, data)
                          }}
                        >
                          <ion-icon
                            name="chatbubbles-outline"
                            class={`text-light ${customIconSize}`}
                          ></ion-icon>
                        </li>
                        <li
                          class="d-flex align-items-center"
                          onClick={e => {
                            bookmarkButtonClick(e, data)
                          }}
                        >
                          <ion-icon
                            name={
                              data?.BookmarkStatus
                                ? 'bookmark'
                                : 'bookmark-outline'
                            }
                            class={`text-light ${customIconSize}`}
                          ></ion-icon>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(loader || data == '' || data == null || data == undefined) && (
            <LoaderCard button={button} />
          )}
        </div>
      </div>
      <ShareOffcanvas></ShareOffcanvas>
    </>
  )
}

const mapStateToProps = ({ auth, nanoWebsite, myBusiness }) => {
  const { authToken, USERID } = auth
  const { nanoWebsiteData } = nanoWebsite
  const { BizCategory, BizLogo, BizBanner } = myBusiness

  return {
    authToken,
    nanoWebsiteData,
    BizCategory,
    BizLogo,
    BizBanner,
    USERID
  }
}

const mapDispatchToProps = {
  showDetailViewModal,
  setNanoWebsiteData,
  setBizCategory,
  setBizLogo,
  setBizBanner,
  showChatThreadViewOffcanvas,
  setChatViewId,
  chatConversation,
  setChatBuisnessData,
  getConvoID,
  showAuthModal,
  hideAuthModal,
  setBusinessID
}

export default connect(mapStateToProps, mapDispatchToProps)(NanoWebsite)
