import React, { useState, useEffect, useRef } from 'react'
import { ImageCompressor } from 'image-compressor'
import Offcanvas from 'react-bootstrap/Offcanvas'
import axios from '../../AxiosConfig'
import { connect } from 'react-redux'
import { setImageUploadOffCanvas } from '../../features/offcanvas/ImageUploadSlice'
import './Imageupload.css'
import {
  showMyBusinessModal,
  setCreateBusinessData
} from '../../features/offcanvas/MyBusinessSlice'
import { useColorPicker } from '../../hooks/useColorPicker'
import { getContrastYIQ } from './Comps/TextualComp'
import { useBusinessImageUpload } from '../../hooks/useBusinessImageUpload'
import { setBusinessContentActionPosition } from '../../features/offcanvas/EditBusinessSlice'

function ImageUploadPage({
  setImageUploadOffCanvas,
  ImageUploadOffCanvas,
  businessData,
  setBusinessContentActionPosition,
  businessContentPosition,
  businessContentAction,
  setCreateBusinessData
}) {
  const [description, setDescription] = useState('')
  const fileInputRef = useRef(null)
  const [updatedImageData, setUpdatedImageData] = useState(null)
  const [activeTab, setActiveTab] = useState('image')
  const [image, setImage] = useState('')
  const offcanvasRef = useRef(null)

  const { proceedCompressedImage, ImageLink, setImageLink, ImageLoader } =
    useBusinessImageUpload()
  const [loader, setLoader] = useState(false)

  const [text, settext] = useState('')
  const [backgroundColor, setBackgroundColor] = useState('#daa3b8')

  const checkTextSize = () => {
    const textLength = text.length
    // const fontSize = Math.min(Math.max(18, textLength * 0.5), 12);
    const fontSize = textLength * 0.5
    switch (true) {
      case fontSize < 18:
        return `18px`
        break
      case fontSize > 18:
        return `13px`
        break
      default:
        return `16px`
      // code block
    }
  }

  const { ShowPicker, setShowPicker, ColorPickerContainer } = useColorPicker({
    CurrentColor: backgroundColor,
    onColorChanged: OnColorChange,
    hideCancelButton: true,
    hideDoneButton: true
  })

  const handleImageTabClick = () => {
    // setTimeout(() => {
    console.log(fileInputRef.current)
    if (fileInputRef.current) {
      fileInputRef.current.click()
    }
    // }, 500) // delay to ensure the tab content is rendered
  }
  // const updateColor = updatedBusinessData => {

  // }
  // const updateFinalColor = () => {
  //   updateColor(businessData)
  // }
  const updateBusinessData = () => {
    const tempData = JSON.parse(JSON.stringify(businessData))
    console.log(tempData)
    setLoader(true)
    const contentData = {
      image: updatedImageData ? updatedImageData : '',
      title: text,
      bgColor: backgroundColor
    }
    // if (type == 'businessColor') {
    //   tempData.Background = value
    //   setCreateBusinessData({ businessData: tempData })
    // } else if (type == 'itemTitle') {
    if (tempData.Content == null || tempData.Content == undefined) {
      tempData.Content = []
    }
    if (businessContentAction === 'add') {
      tempData.Content.splice(businessContentPosition, 0, contentData)
    } else {
      tempData.Content[businessContentPosition] = { ...contentData }
    }
    axios
      .put('/businesses/' + tempData.ID, tempData)
      .then(res => {
        setLoader(false)

        if (res.data.message === 'Success') {
          setCreateBusinessData({ businessData: tempData })
          // setCreateBusinessData({ businessData: tempData })
          // setColorPickerVisible(false)
          setImageUploadOffCanvas(false)
          setActiveTab('text')
          setUpdatedImageData(null)
          setImageLink(null)
          settext('')
          setBackgroundColor('#daa3b8')
        }
      })
      .catch(error => {
        setLoader(false)
      })
    //   tempData.Content[index].title = value
    //   setCreateBusinessData({ businessData: tempData })
    // }
  }

  useEffect(() => {
    if (updatedImageData) {
      updateBusinessData()
    }
  }, [updatedImageData])

  function OnColorChange(color) {
    console.log(color)
    setBackgroundColor(color)
    setShowPicker(false)
  }

  function uploadRefImage(img) {
    const fileUrl = URL.createObjectURL(img)
    const imageCompressor = new ImageCompressor()
    const compressorSettings = {
      toWidth: 500,
      toHeight: 500,
      mimeType: 'image/png',
      mode: 'strict',
      quality: 0.6,
      speed: 'low'
    }
    imageCompressor.run(fileUrl, compressorSettings, proceedCompressedImage)
  }

  useEffect(() => {
    if (ImageLink) {
      console.log(ImageLink)
      setUpdatedImageData(ImageLink)
    }
  }, [ImageLink])

  // const handleClickOutside = event => {
  //   if (offcanvasRef.current && !offcanvasRef.current.contains(event.target)) {
  //     setImageUploadOffCanvas(false)
  //   }
  // }

  // useEffect(() => {
  //   document.addEventListener('mousedown', handleClickOutside)
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside)
  //   }
  // }, [])

  return (
    <>
      <Offcanvas
        className="offcanvasForImageUpload"
        placement="bottom"
        show={ImageUploadOffCanvas}
        backdrop={'static'}
        onHide={() => setImageUploadOffCanvas(false)}
        ref={offcanvasRef}
        style={{
          transition: '0.2s ease-in-out',
          height: 'auto',
          zIndex: 9999,
          borderTopLeftRadius: '25px',
          borderTopRightRadius: '25px',
          maxWidth: '358px',
          margin: '0 auto'
        }}
      >
        <div class="wide-block pb-2">
          <ul class="nav nav-tabs lined" role="tablist">
            <li class="nav-item">
              <a
                class="nav-link active"
                data-bs-toggle="tab"
                href="#textTab"
                role="tab"
                onClick={() => {
                  setActiveTab('text')
                }}
              >
                Text
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link"
                onClick={() => {
                  if (!loader || !ImageLoader) {
                    setActiveTab('image')
                    // handleImageTabClick()
                  }
                }}
              >
                Image
                {ImageLoader && <div className="loader3 ms-1"></div>}
              </a>
            </li>
          </ul>
          <button
            className="closeBtn closeImageUploadOffcanvas"
            disabled={ImageLoader || loader}
            onClick={() => {
              setBusinessContentActionPosition({
                businessContentPosition: null,
                businessContentAction: null
              })
              setImageUploadOffCanvas(false)
            }}
          >
            <ion-icon name="close-outline"></ion-icon>
          </button>
          <div class="tab-content mt-2 position-relative">
            <div
              class={`tab-pane fade show active`}
              id="textTab"
              role="tabpanel"
            >
              <div className="previewContainer">
                <div
                  className="div2"
                  style={{ backgroundColor: backgroundColor }}
                >
                  <span
                    className="divIcon"
                    onClick={() => {
                      setShowPicker(true)
                    }}
                  >
                    <ion-icon
                      name="color-palette-outline"
                      style={{ color: getContrastYIQ(backgroundColor) }}
                    ></ion-icon>
                  </span>
                  <div className="divText">
                    <div className="inputCont">
                      <textarea
                        class="form-control textarea"
                        style={{
                          color: getContrastYIQ(backgroundColor),
                          fontSize: checkTextSize(),
                          '--placeholderColor': getContrastYIQ(backgroundColor)
                        }}
                        onChange={e => {
                          settext(e.target.value)
                        }}
                        id="name5"
                        placeholder="Enter Text Here"
                        autocomplete="off"
                        rows="5"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <button
                class="btn btn-primary btn-block mt-2"
                disabled={ImageLoader || loader}
                onClick={() => updateBusinessData()}
                type="submit"
              >
                Done
                {loader && <div className="loader3 ms-1"></div>}
              </button>
              <ColorPickerContainer />
            </div>
            <div class={`tab-pane fade show`} id="imageTab" role="tabpanel">
              <div className="previewContainer">
                <div className="div2"></div>
              </div>
              <div className="inputCont">
                <input
                  hidden
                  type="file"
                  ref={fileInputRef}
                  id="uploadNewBusinessLogo"
                  accept=".png, .jpg, .jpeg"
                  onChange={e => {
                    uploadRefImage(e.target.files[0])
                  }}
                />
              </div>
              <button class="btn btn-primary btn-block mt-2" type="submit">
                Submit
              </button>
            </div>
          </div>
        </div>
      </Offcanvas>
    </>
  )
}

const mapStateToProps = ({ ImageUploadData, myBusiness, editBusiness }) => {
  const { myBusinessModalVisible, businessData } = myBusiness
  const { ImageUploadOffCanvas } = ImageUploadData
  const { businessContentAction, businessContentPosition } = editBusiness
  return {
    myBusinessModalVisible,
    businessData,
    ImageUploadOffCanvas,
    businessContentPosition,
    businessContentAction
  }
}

const mapDispatchToProps = {
  setImageUploadOffCanvas,
  setBusinessContentActionPosition,
  setCreateBusinessData
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadPage)
