const config = {
  appUrl: 'http://localhost:3000',
  // apiUrl : "https://api.webweb.ai"
  apiUrl: 'https://app-api.webweb.ai'
  // apiUrl: 'http://localhost:3010'
}

export const FILTERLIST = {
  FILTER1: { filter: 'HealthCare', text: 'Health care' },
  FILTER2: { filter: 'Education', text: 'Education' },
  FILTER3: { filter: 'Manufacturing', text: 'Manufacturing' },
  FILTER4: { filter: 'Professional', text: 'Professional' },
  FILTER5: { filter: 'Retails', text: 'Retails' },
  FILTER6: { filter: 'Within5kms', text: 'Within 5kms' },
  FILTER7: { filter: 'WithinCity', text: 'Within City' },
  FILTER8: { filter: 'Popular', text: 'Popular' },
  FILTER9: { filter: 'ListOfCities', text: 'List of Cities' },
  FILTER10: { filter: 'Bookmarked', text: 'Bookmarked' },
  FILTER11: { filter: 'NewlyAdded', text: 'Newly Added' },
  FILTER12: { filter: 'Suggestions', text: 'Suggestions' },
  FILTER13: { filter: 'Featured', text: 'Featured' }
}

export default config
