import React, { useState, useRef, useEffect } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import axios from '../../../AxiosConfig'
import {
  hideMyBusinessModal,
  setCreateBusinessData
} from '../../../features/offcanvas/MyBusinessSlice'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import {
  showEditBusinessViewModal,
  setBusinessContentActionPosition
} from '../../../features/offcanvas/EditBusinessSlice'
import { editItemData } from '../../../features/data/dataSlice'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css'
import { ColorExtractor } from 'react-color-extractor'
import { ImageCompressor } from 'image-compressor'
import { showConfirmationViewModal } from '../../../features/offcanvas/ConfirmationSlice'

import Frame1 from '../../images/sample/Frame-1.png'
import Frame2 from '../../images/sample/Frame-2.png'
import Frame3 from '../../images/sample/Frame-3.png'
import { setImageUploadOffCanvas } from '../../../features/offcanvas/ImageUploadSlice'
import ImageuploadOffCanvas from '../../Pages/ImageuploadOffCanvas'
import TextualComp from '../../Pages/Comps/TextualComp'
import { useColorPicker } from '../../../hooks/useColorPicker'

const MyBusinessModal = ({
  myBusinessModalVisible,
  hideMyBusinessModal,
  button = '#a9965e',
  showEditBusinessViewModal,
  businessData,
  setCreateBusinessData,
  editItemData,
  cardID,
  itemID,
  setImageUploadOffCanvas,
  showConfirmationViewModal,
  setBusinessContentActionPosition
}) => {
  // const [colorPickerVisible, setColorPickerVisible] = useState(false)
  const [initiateCropper, setInitiateCropper] = useState(false)
  const [imageData, setImageData] = useState(null)
  const [updatedImageData, setUpdatedImageData] = useState(null)
  const [showTitleInput, setShowTitleInput] = useState(false)
  const [logoColors, setLogoColors] = useState([])
  const [uploadLoader, setUploadLoader] = useState(false)
  const [doneButtonDisable, setDoneButtonDisable] = useState(false)
  const [disableItemUpdateButton, setDisableItemUpdateButton] = useState(false)
  const [uploadImageLoader, setUploadImageLoader] = useState(false)
  const [showMessage, setShowMessage] = useState('')
  const [tempImage, setTempImage] = useState(null)
  const cropperRef = useRef()

  const { ShowPicker, setShowPicker, ColorPickerContainer } = useColorPicker({
    DynamicColors: logoColors,
    CurrentColor: businessData?.Background,
    onFinalColorSelected: OnColorFinalSelectionDone,
    onColorChanged: updateBusinessData,
    onCancelled: OnCancelColorSelection,
    hideCancelButton: true
  })

  let dummyData = [
    {
      title: 'Title 1',
      image: Frame1
    },
    {
      title: 'Title 2',
      image: Frame2
    },
    {
      title: 'Title 3',
      image: Frame3
    }
  ]
  const handleBusinessCardEdit = () => {
    showEditBusinessViewModal({
      editBusinessOffcanvasVisible: true,
      actionType: 'edit'
    })
  }
  const viewerList = type => {
    showEditBusinessViewModal({
      editBusinessOffcanvasVisible: true,
      actionType: type
    })
  }
  const uploadRefImage = filePath => {
    setUploadImageLoader(true)
    const formData = new FormData()
    formData.append('inputFile', filePath)
    axios
      .post(
        'https://api.allpos.in/applications/upload/businessImage',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(res => {
        if (res.data.message == 'Success') {
          const data = JSON.parse(JSON.stringify(businessData))
          data.BusinessLogo = res.data.data
          axios.put('/businesses/' + data.ID, data).then(res => {
            if (res.data.message === 'Success') {
              setUploadImageLoader(false)
            }
          })
          setCreateBusinessData({ businessData: data })
        }
      })
      .catch(err => {})
  }

  const uploadRefImageItem = (filePath, index) => {
    if (filePath) {
      const reader = new FileReader()
      reader.onload = event => {
        setTempImage(event.target.result)
      }
      reader.readAsDataURL(filePath)
    }
    setUploadLoader(true)
    const fileUrl = URL.createObjectURL(filePath)

    setUpdatedImageData({
      image: '',
      id: index
    })
    // setUpdatedImageData({
    //   'image':tempImage,
    //   'id': index
    // })
    handleUploadImage(fileUrl)
  }

  function updateBusinessData(value) {
    const tempData = JSON.parse(JSON.stringify(businessData))
    // if (type == 'businessColor') {
    tempData.Background = value
    setCreateBusinessData({ businessData: tempData })
    // } else if (type == 'itemTitle') {
    //   if (tempData.Content == null || tempData.Content == undefined) {
    //     tempData.Content = []
    //   }
    //   if (
    //     tempData.Content[index] == undefined ||
    //     tempData.Content[index] == null
    //   ) {
    //     tempData.Content[index] = {
    //       title: '',
    //       id: updatedImageData?.id ? updatedImageData.id : null
    //     }
    //   }
    // tempData.Content[index].title = value
    // setCreateBusinessData({ businessData: tempData })
    // }
  }

  function changeBusinessTitle(value, type, index) {
    const tempData = JSON.parse(JSON.stringify(businessData))
    // if (tempData.Content == null || tempData.Content == undefined) {
    //   tempData.Content = []
    // }
    // if (
    //   tempData.Content[index] == undefined ||
    //   tempData.Content[index] == null
    // ) {
    //   tempData.Content[index] = {
    //     title: '',
    //     id: updatedImageData?.id ? updatedImageData.id : null
    //   }
    // }
    tempData.Content[index].title = value
    setCreateBusinessData({ businessData: tempData })
  }

  const updateColor = updatedBusinessData => {
    axios
      .put('/businesses/' + updatedBusinessData.ID, updatedBusinessData)
      .then(res => {
        if (res.data.message === 'Success') {
          setCreateBusinessData({ businessData: updatedBusinessData })
          // setColorPickerVisible(false)
        }
      })
  }

  function OnColorFinalSelectionDone(color) {
    console.log(color, businessData)
    // if(color !== businessData.Background) {
    updateColor(businessData)
    // }
  }

  function OnCancelColorSelection(color) {
    console.log(color)
    updateBusinessData(color)
  }

  const handleUploadImage = imageData => {
    // setInitiateCropper(true)
    setImageData(imageData)
  }

  useEffect(() => {
    const imageCompressor = new ImageCompressor()
    const compressorSettings = {
      toWidth: 500,
      toHeight: 500,
      mimeType: 'image/png',
      mode: 'strict',
      quality: 0.6,
      speed: 'low'
    }
    imageCompressor.run(imageData, compressorSettings, proceedCompressedImage)
  }, [imageData])

  function proceedCompressedImage(compressedSrc) {
    setUpdatedImageData({
      image: compressedSrc,
      id: updatedImageData.id
    })
    const base64String = compressedSrc.split(',')[1]

    const byteCharacters = atob(base64String)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)

    const blob = new Blob([byteArray], { type: 'image/png' })

    const formData = new FormData()
    formData.append('inputFile', blob)
    axios
      .post(
        'https://api.allpos.in/applications/upload/businessImage',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }
      )
      .then(res => {
        setDoneButtonDisable(false)
        const tempBusinessData = JSON.parse(JSON.stringify(businessData))
        if (tempBusinessData.Content == null) {
          tempBusinessData.Content = []
        }
        if (tempBusinessData.Content[updatedImageData.id] == undefined) {
          tempBusinessData.Content[updatedImageData.id] = {
            image: '',
            id: updatedImageData.id
          }
        }
        tempBusinessData.Content[updatedImageData.id].image = res.data.data

        axios
          .put('/businesses/' + tempBusinessData.ID, tempBusinessData)
          .then(res => {
            if (res.data.message === 'Success') {
              // setInitiateCropper(false)
              setUploadLoader(false)
            }
          })
        setCreateBusinessData({ businessData: tempBusinessData })
      })
      .catch(err => {})
  }

  const onCrop = () => {
    //   setDoneButtonDisable(true)
    //   const cropper = cropperRef.current?.cropper;
    //   cropper.getCroppedCanvas({
    //       maxHeight: 360,
    //       maxWidth: 360,
    //       fillColor: 'transparent'
    //   }).toBlob((blob) => {
    //       const formData = new FormData();
    //       const fileUrl = URL.createObjectURL(blob);
    //       formData.append('inputFile', blob)
    //       axios.post('https://api.allpos.in/applications/upload/businessImage', formData, {
    //           headers: {
    //               'Content-Type': 'multipart/form-data',
    //           },
    //       }).then(res => {
    //         setDoneButtonDisable(false)
    //           const tempBusinessData = JSON.parse(JSON.stringify(businessData))
    //           if (tempBusinessData.Content == null) {
    //             tempBusinessData.Content = []
    //           }
    //           if (tempBusinessData.Content[updatedImageData.id] == undefined) {
    //             tempBusinessData.Content[updatedImageData.id] = {'image':'', 'id': updatedImageData.id}
    //           }
    //           tempBusinessData.Content[updatedImageData.id].image = res.data.data;
    //           axios.put('/businesses/' + tempBusinessData.ID, tempBusinessData).then(res => {
    //               if (res.data.message === 'Success') {
    //                   setInitiateCropper(false)
    //               }
    //           })
    //           setCreateBusinessData({ businessData: tempBusinessData })
    //       }).catch(err => {
    //       })
    //   }, 'png', 100);
  }

  const updateItemTitle = () => {
    setDisableItemUpdateButton(true)
    axios.put('/businesses/' + businessData.ID, businessData).then(res => {
      if (res.data.message === 'Success') {
        setShowTitleInput(false)
        setDisableItemUpdateButton(false)
      }
    })
  }

  const addNewItem = currentIndex => {
    const tempBusinessData = JSON.parse(JSON.stringify(businessData))
    const imageArr = tempBusinessData.Content
    if (imageArr.length == 10) {
      setShowMessage('Image Limit Reached')
      setTimeout(() => {
        setShowMessage('')
      }, 5000)
    } else {
      let tempNewData = { image: Frame3, title: 'New Title' }
      const newArray = [
        ...imageArr.slice(0, currentIndex),
        tempNewData,
        ...imageArr.slice(currentIndex)
      ]
      tempBusinessData.Content = newArray
      setCreateBusinessData({ businessData: tempBusinessData })
      console.log(tempBusinessData)
      axios
        .put('/businesses/' + tempBusinessData.ID, tempBusinessData)
        .then(res => {
          if (res.data.message === 'Success') {
          }
        })
    }
  }

  const deleteItem = currentIndex => {
    showConfirmationViewModal({
      confirmationModalVisible: true,
      deleteItemID: currentIndex
    })
  }

  const cancelCrop = () => {
    setInitiateCropper(false)
  }

  const handleItemTitleEdit = (cardID, itemID) => {
    setShowTitleInput(true)
    editItemData({ cardID: cardID, itemID: itemID })
  }

  return (
    <div>
      <Modal
        show={myBusinessModalVisible}
        style={{ transition: '0.2s ease-in-out' }}
        fullscreen
      >
        <Modal.Body
          className="d-flex justify-content-center align-items-center flex-column"
          style={{
            height: '100vh',
            background: businessData?.Background
              ? businessData?.Background
              : '#000000'
          }}
        >
          <div
            class="position-fixed cursor-pointer safe-area-custom-top"
            onClick={() => {
              hideMyBusinessModal()
            }}
            style={{ left: 10, marginTop: '10px' }}
          >
            <ion-icon
              name="chevron-back-outline"
              class="fs-1 text-light"
            ></ion-icon>
          </div>
          {ShowPicker == false && (
            <span
              class="btn btn-link text-light pe-1 safe-area-custom-top"
              onClick={() => setShowPicker(true)}
              style={{
                position: 'fixed',
                right: 10,
                fontSize: '13px',
                height: '34px',
                marginTop: '10px'
              }}
            >
              <ion-icon
                name="create-outline"
                style={{ marginRight: '2px' }}
              ></ion-icon>
              <u>Change Background</u>
            </span>
          )}
          {ShowPicker == true && (
            <>
              <ColorPickerContainer />
            </>
          )}
          <div
            class="card border w-100 w-custom-desktop"
            style={{ marginTop: '-30px', position: 'relative' }}
          >
            {initiateCropper && (
              <div
                class="d-flex justify-content-center align-items-center"
                style={{
                  background: 'rgba(0,0,0,0.5)',
                  position: 'fixed',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 9999
                }}
              >
                <button
                  class="btn btn-success"
                  onClick={onCrop}
                  disabled={doneButtonDisable}
                  style={{
                    position: 'absolute',
                    top: '50%',
                    transform: 'translate(0px, 150px)'
                  }}
                >
                  <ion-icon name="checkmark-circle-outline"></ion-icon> Done
                </button>
                <span
                  onClick={cancelCrop}
                  class="cursor-pointer"
                  style={{ position: 'absolute', top: '5px', right: '5px' }}
                >
                  <ion-icon
                    name="close-outline"
                    class="fs-1 text-light"
                  ></ion-icon>
                </span>
                <Cropper
                  src={imageData}
                  style={{
                    height: '194px',
                    width: '194px',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    zIndex: '1',
                    transform: 'translate(-100px, -89px)'
                  }}
                  viewMode={0}
                  // preview={imageData}
                  initialAspectRatio={1 / 1}
                  aspectRatio={1}
                  dragMode="move"
                  guides={false}
                  scalable={false}
                  movable={true}
                  rotatable={false}
                  zoomable={true}
                  zoomOnTouch={true}
                  cropBoxMovable={false}
                  cropBoxResizable={false}
                  minCanvasWidth={1}
                  minCanvasHeight={1}
                  autoCropArea={1}
                  minCropBoxWidth={'100%'}
                  minCropBoxHeight={'100%'}
                  ref={cropperRef}
                />
              </div>
            )}
            <div class="card-body pt-2 pb-2">
              <div class="d-flex justify-content-center align-items-center flex-column">
                {(businessData.BusinessLogo == '' ||
                  businessData.BusinessLogo == null ||
                  businessData.BusinessLogo == undefined) && (
                  <div
                    class="custom-file-upload"
                    id="fileUpload1"
                    style={{ height: '74px', width: '200px' }}
                  >
                    {uploadImageLoader && (
                      <div
                        class="d-flex align-items-center justify-content-center text-light flex-column"
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          left: 0,
                          bottom: 0,
                          background: 'rgba(0,0,0,0.6)',
                          borderRadius: '6px',
                          zIndex: 2
                        }}
                      >
                        <div
                          class="spinner-border text-light"
                          style={{ height: '20px', width: '20px' }}
                          role="status"
                        ></div>
                        <span>Uploading...</span>
                      </div>
                    )}
                    <input
                      type="file"
                      id="uploadNewBusinessLogo"
                      accept=".png, .jpg, .jpeg"
                      onChange={e => {
                        uploadRefImage(e.target.files[0])
                      }}
                    />
                    <label for="uploadNewBusinessLogo">
                      <span>
                        <strong>
                          <ion-icon
                            name="cloud-upload-outline"
                            class="fs-5"
                          ></ion-icon>
                          <i class="fs-6">Upload your Logo</i>
                        </strong>
                      </span>
                    </label>
                  </div>
                )}
                {businessData.BusinessLogo && (
                  <>
                    <div style={{ position: 'relative' }}>
                      <ColorExtractor
                        getColors={colors => setLogoColors(colors)}
                      >
                        <img
                          src={
                            businessData.BusinessLogo
                              ? businessData.BusinessLogo
                              : 'https://webweb.ams3.cdn.digitaloceanspaces.com/data/webwebapp/Categories/product.svg'
                          }
                          alt="avatar"
                          class="imaged"
                          style={{
                            height: '64px'
                          }}
                        />
                      </ColorExtractor>
                      <input
                        type="file"
                        id="uploadBusinessLogo"
                        accept=".png, .jpg, .jpeg"
                        hidden
                        onChange={e => {
                          uploadRefImage(e.target.files[0])
                        }}
                      />
                      <label for="uploadBusinessLogo">
                        {/* {(businessData?.BusinessLogo === undefined || businessData?.BusinessLogo === null || businessData?.BusinessLogo === '') && */}
                        <div
                          class="d-flex justify-content-center align-items-center"
                          style={{
                            position: 'absolute',
                            background: 'rgba(0,0,0,0.0)',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            borderRadius: '10px'
                          }}
                        >
                          {/* <ion-icon
                              name="image-outline"
                              class="fs-2 text-light"
                            ></ion-icon> */}
                        </div>
                        {/* } */}
                      </label>
                    </div>
                    <label for="uploadBusinessLogo">
                      <span class="text-secondary d-flex align-items-center fs-10 cursor-pointer">
                        {' '}
                        Change Logo
                      </span>
                    </label>
                  </>
                )}
              </div>
              <h2
                class="h5 fw-bold text-center mb-0 mt-2 cursor-pointer"
                onClick={handleBusinessCardEdit}
              >
                {businessData.BusinessName
                  ? businessData.BusinessName
                  : 'Business Name'}
              </h2>
              <h3
                class="h6 text-muted text-center mb-0 mt-1 cursor-pointer"
                onClick={handleBusinessCardEdit}
              >
                {businessData.Description
                  ? businessData.Description
                  : 'Description'}
              </h3>
              {showMessage == 'Image Limit Reached' && (
                <h3 class="h6 text-danger fw-bold text-center mb-0 mt-1">
                  {showMessage}
                </h3>
              )}
              <div
                class="pb-2 mt-2"
                style={{ marginLeft: '-8px', marginRight: '-8px' }}
              >
                <Splide
                  options={{
                    type: 'slide',
                    rewind: true,
                    gap: 10,
                    padding: '4rem',
                    autoplay: false,
                    interval: 3000,
                    pagination: false,
                    arrows: true,
                    breakpoints: {
                      700: {
                        perPage: 1,
                        perMove: 1
                      },
                      1200: {
                        perPage: 1,
                        perMove: 1
                      },
                      1600: {
                        perPage: 1,
                        perMove: 1
                      },
                      2000: {
                        perPage: 1,
                        perMove: 1
                      }
                    }
                  }}
                >
                  <>
                    {(businessData.Content
                      ? businessData.Content
                      : dummyData
                    ).map((i, j) => {
                      return (
                        <>
                          <SplideSlide key={j}>
                            <div
                              className="card text-white mb-1"
                              style={{ background: 'lightgray' }}
                            >
                              <button
                                className="btn btn-primary rounded-circle p-0 d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  // addNewItem(j)
                                  console.log('one')
                                  setBusinessContentActionPosition({
                                    businessContentPosition: j + 1,
                                    businessContentAction: 'add'
                                  })
                                  setImageUploadOffCanvas(true)
                                }}
                                for={'uploadNewBusinessItems'}
                                style={{
                                  position: 'absolute',
                                  top: '50%',
                                  right: 0,
                                  height: '30px',
                                  width: '30px',
                                  zIndex: 5,
                                  transform: 'translate(20px, -20px)'
                                }}
                              >
                                {/* <input
                                  class="rounded-circle"
                                  type="file"
                                  id={'uploadNewBusinessItems'}
                                  accept=".png, .jpg, .jpeg"
                                  onChange={e => {
                                    uploadRefImageItem(e.target.files[0], j)
                                  }}
                                  style={{
                                    position: 'sticky',
                                    zIndex: 6,
                                    position: 'absolute',
                                    bottom: 0,
                                    right: 0,
                                    opacity: 0,
                                    height: '30px',
                                    width: '30px'
                                  }}
                                /> */}
                                <ion-icon
                                  name="add-outline"
                                  class="fs-1 me-0"
                                ></ion-icon>
                              </button>
                              {ImageuploadOffCanvas && <ImageuploadOffCanvas />}
                              <div class="image-wrapper-responsive position-relative">
                                <>
                                  {businessData?.Content?.length > 1 && (
                                    <div
                                      class="fs-3 text-danger cursor-pointer"
                                      style={{
                                        position: 'sticky',
                                        zIndex: 5,
                                        position: 'absolute',
                                        top: 10,
                                        right: 10
                                      }}
                                      onClick={() => {
                                        deleteItem(j)
                                      }}
                                    >
                                      <ion-icon
                                        name="trash-outline"
                                        class="fs-3 me-0 text-danger"
                                      ></ion-icon>
                                    </div>
                                  )}
                                  <label
                                    for={'uploadNewBusinessItems'}
                                    class="btn fs-8 text-light text-nowrap btn-primary cursor-pointer"
                                    style={{
                                      position: 'sticky',
                                      zIndex: 5,
                                      position: 'absolute',
                                      bottom: 10,
                                      right: 10,
                                      border: 'solid 2px #1E74FD',
                                      borderRadius: '25px',
                                      height: '24px'
                                    }}
                                  >
                                    Upload
                                  </label>
                                  <input
                                    class="w-50"
                                    type="file"
                                    id={'uploadNewBusinessItems'}
                                    accept=".png, .jpg, .jpeg"
                                    onChange={e => {
                                      uploadRefImageItem(e.target.files[0], j)
                                    }}
                                    style={{
                                      position: 'sticky',
                                      zIndex: 6,
                                      position: 'absolute',
                                      bottom: 10,
                                      right: 10,
                                      border: 'solid 2px #1E74FD',
                                      borderRadius: '25px',
                                      opacity: 0
                                    }}
                                  />
                                </>
                                {i?.image?.length > 2 && (
                                  <img
                                    src={i?.image ? i.image : 'Add Image'}
                                    // src={(tempImage) ? tempImage : i.image}
                                    className="card-img overlay-img w-100"
                                    alt="image"
                                    style={{
                                      objectFit: 'cover',
                                      zIndex: 1,
                                      background: 'rgba(255,255,255,0.4)'
                                    }}
                                  />
                                )}
                                {i?.image?.length < 2 && (
                                  <TextualComp
                                    onClick={() => {
                                      handleItemTitleEdit(businessData.ID, j)
                                    }}
                                    text={i?.title}
                                    bgColor={i?.bgColor}
                                  />
                                )}
                                <div
                                  style={{
                                    backgroundImage: `url(${i?.image})`,
                                    backgroundPosition: 'center',
                                    backgroundSize: '200%',
                                    zIndex: 0,
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    bottom: 0,
                                    filter: 'blur(2px)',
                                    backgroundColor: i?.bgColor
                                  }}
                                ></div>
                                {uploadLoader && (
                                  <div
                                    class="d-flex justify-content-center align-items-center flex-column"
                                    style={{
                                      position: 'absolute',
                                      top: 0,
                                      right: 0,
                                      left: 0,
                                      bottom: 0,
                                      background: 'rgba(0,0,0,0.4)',
                                      zIndex: 1
                                    }}
                                  >
                                    <div
                                      class="spinner-border text-light mb-1"
                                      style={{ height: '18px', width: '18px' }}
                                      role="status"
                                    ></div>
                                    {/* Uploading... */}
                                  </div>
                                )}
                              </div>
                            </div>
                            {i?.image?.length > 2 && (
                              <h4
                                class="fw-bold mb-0 mt-0 d-flex align-items-center justify-content-center"
                                style={{ fontSize: '13px' }}
                              >
                                <div
                                  class="d-flex justify-content-center align-items-center cursor-pointer"
                                  onClick={() => {
                                    handleItemTitleEdit(businessData.ID, j)
                                  }}
                                >
                                  {i?.title ? i.title : 'Title'}&nbsp;
                                  {/* <ion-icon
                                        name="create-outline"
                                        class="fs-6"
                                    ></ion-icon> */}
                                </div>
                              </h4>
                            )}
                          </SplideSlide>
                        </>
                      )
                    })}
                  </>
                </Splide>
              </div>
              <div class="row mt-1">
                <div
                  class="col-12 mt-1 d-flex justify-content-center align-items-center text-dark cursor-pointer"
                  style={{ fontSize: '12px', marginBottom: '3px' }}
                  onClick={handleBusinessCardEdit}
                >
                  <ion-icon
                    name="location-outline"
                    class="text-dark"
                    style={{ fontSize: '12px' }}
                  ></ion-icon>{' '}
                  {businessData.Location ? businessData.Location : 'Location'}
                </div>
                <div class="col-12">
                  <button
                    class="btn w-100 text-nowrap btn-block text-light moreDetailsButton"
                    style={{
                      background: businessData?.Background
                        ? businessData?.Background
                        : '#000000',
                      position: 'relative'
                    }}
                    onClick={handleBusinessCardEdit}
                  >
                    {businessData.ActionButton
                      ? businessData.ActionButton.Title
                      : 'View More'}
                    &nbsp;&nbsp;
                    <span
                      class="badge bg-danger fs-10"
                      style={{
                        position: 'absolute',
                        height: '16px',
                        top: '-8px',
                        right: '-8px'
                      }}
                    >
                      250
                    </span>
                    <ion-icon
                      name="chevron-forward-outline"
                      class="text-light fs-6 me-0"
                    ></ion-icon>
                  </button>
                </div>
                <div class="col-12 col-md-12">
                  <h6
                    class="mb-0 d-flex justify-content-center align-items-center"
                    style={{ marginTop: '3px' }}
                  >
                    No Recommendations
                  </h6>
                </div>
              </div>
              <span
                class="text-dark"
                style={{ position: 'absolute', top: 10, right: 10 }}
              >
                <span
                  class="badge bg-success border-success text-light cursor-pointer p-1 ps-2 pe-2"
                  style={{ fontSize: '14px', zIndex: 1 }}
                  onClick={handleBusinessCardEdit}
                >
                  <ion-icon
                    name="create-outline"
                    style={{ marginRight: '2px' }}
                  ></ion-icon>
                  Edit
                </span>
              </span>
              <div
                style={{
                  position: 'absolute',
                  bottom: '-46px',
                  left: '50%',
                  transform: 'translate(-50%, 10%)'
                }}
              >
                <div class="row w-custom-desktop">
                  <div class="col-6 col-md-6 text-light d-flex">
                    <ul
                      class="p-0 mb-0 d-flex align-items-center"
                      style={{ listStyleType: 'none' }}
                    >
                      <li class={`me-2 d-flex align-items-center flex-column`}>
                        <ion-icon
                          name="information-circle-outline"
                          class={`text-light fs-3`}
                        ></ion-icon>
                        <span class="text-light fs-10">250</span>
                      </li>
                    </ul>
                  </div>
                  <div class="col-6 col-md-6 d-flex align-items-end justify-content-end">
                    <ul
                      class="p-0 d-flex mb-0"
                      style={{ listStyleType: 'none' }}
                    >
                      <li
                        class={`me-2 d-flex align-items-center flex-column cursor-pointer`}
                        onClick={() => {
                          viewerList('share')
                        }}
                      >
                        <ion-icon
                          name="share-social-outline"
                          class={`text-light fs-3`}
                        ></ion-icon>
                        <span class="text-light fs-10">550</span>
                      </li>
                      <li
                        class={`me-2 d-flex align-items-center flex-column cursor-pointer`}
                        onClick={() => {
                          viewerList('chat')
                        }}
                      >
                        <ion-icon
                          name="chatbubbles-outline"
                          class={`text-light fs-3`}
                        ></ion-icon>
                        <span class="text-light fs-10">62</span>
                      </li>
                      <li
                        class="d-flex align-items-center flex-column cursor-pointer"
                        onClick={() => {
                          viewerList('bookmark')
                        }}
                      >
                        <ion-icon
                          name="bookmark"
                          class={`text-light fs-3`}
                        ></ion-icon>
                        <span class="text-light fs-10">800</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                style={{
                  position: 'absolute',
                  bottom: '-65px',
                  left: '50%',
                  transform: 'translate(-50%, 10%)'
                }}
              >
                <div class="row w-custom-desktop">
                  <div class="col-12 col-md-12 text-light">
                    {/* <ul
                      class="p-0 mb-0"
                      style={{ listStyleType: 'none' }}
                    >
                      <li>
                        <>
                          <span class="text-dark mt-5">
                            <span
                              class="badge bg-transparent border-light p-2 ps-4 pe-4 cursor-pointer"
                              style={{ fontSize: '14px' }}
                              onClick={handleBusinessCardEdit}
                            >
                              <ion-icon
                                name="create-outline"
                                style={{ marginRight: '2px' }}
                              ></ion-icon>
                              Edit
                            </span>
                          </span>
                        </>
                      </li>
                    </ul> */}
                  </div>
                  <div class="col-6 col-md-6 d-flex align-items-end justify-content-end">
                    {/* <ul class="p-0 d-flex" style={{listStyleType: 'none'}}>
                                        <li class={`me-2 d-flex align-items-center`} >
                                            <ion-icon name="share-social-outline" class={`text-light fs-6`}></ion-icon>
                                        </li>
                                        <li class={`me-2 d-flex align-items-center`} >
                                            <ion-icon name="chatbubbles-outline" class={`text-light fs-6`}></ion-icon>
                                        </li>
                                        <li class="d-flex align-items-center" >
                                            <ion-icon name={"bookmark"} class={`text-light fs-6`}></ion-icon>
                                        </li>
                                    </ul> */}
                  </div>
                </div>
              </div>
              {showTitleInput == true && (
                <div
                  class="d-flex justify-content-center align-items-center text-light"
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    background: 'rgba(0,0,0,0.6)',
                    zIndex: 1
                  }}
                >
                  <div>
                    <div class="form-group boxed">
                      <div class="input-wrapper d-flex justify-content-center flex-column">
                        <input
                          type="text"
                          class="form-control fw-bold"
                          value={
                            businessData?.Content
                              ? businessData?.Content[itemID]?.title
                              : 'New Title'
                          }
                          id="itemTitle"
                          placeholder="Enter Title"
                          onChange={e => {
                            changeBusinessTitle(
                              e.target.value,
                              'itemTitle',
                              itemID
                            )
                          }}
                          // onBlur={updateItemTitle}
                          required
                          autocomplete="off"
                          autoFocus
                          style={{
                            fontSize: '13px',
                            height: '30px',
                            textAlign: 'center'
                          }}
                        />
                        <button
                          class="btn btn-success m-auto mt-2"
                          disabled={disableItemUpdateButton}
                          onClick={updateItemTitle}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                    <div
                      class="position-absolute"
                      onClick={() => {
                        setShowTitleInput(false)
                      }}
                      style={{ top: '53px', right: '15px' }}
                    >
                      <ion-icon name="close-outline" class="fs-1"></ion-icon>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

const mapStateToProps = ({ myBusiness, data }) => {
  const { myBusinessModalVisible, businessData } = myBusiness
  const { cardID, itemID } = data
  return {
    myBusinessModalVisible,
    businessData,
    cardID,
    itemID
  }
}

const mapDispatchToProps = {
  hideMyBusinessModal,
  showEditBusinessViewModal,
  setCreateBusinessData,
  editItemData,
  setImageUploadOffCanvas,
  showConfirmationViewModal,
  setBusinessContentActionPosition
}

export default connect(mapStateToProps, mapDispatchToProps)(MyBusinessModal)
