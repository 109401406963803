import React, { useEffect, useState } from 'react';
import './UserChatView.css';
import { connect } from 'react-redux'
import { hideChatThreadViewOffcanvas, setMessage,setSentMessage, sendMessage, showChatActionSheet,getConvoID } from '../../../features/offcanvas/ChatViewSlice';
import { registerUser } from '../../../features/auth/authSlice';
import Messages from '../Messages';

function UserChatView({ 
    hideChatThreadViewOffcanvas,
    chatConveration,
    USERID,
    ChatBuisnessData,
    setMessage,
    Message,
    sendMessage,
    SentMessage,
    setSentMessage,
    getConvoID,
    showChatActionSheet
 }) {
    
    function formatDateTimeYYYYMMDDHHMMSS(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Add leading zero for single-digit months
        const day = String(date.getDate()).padStart(2, '0'); // Add leading zero for single-digit days
      
        const hours = String(date.getHours()).padStart(2, '0'); // Add leading zero for single-digit hours
        const minutes = String(date.getMinutes()).padStart(2, '0'); // Add leading zero for single-digit minutes
        const seconds = String(date.getSeconds()).padStart(2, '0'); // Add leading zero for single-digit seconds
      
        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      }
      const [singleTick,setSingleTick]=useState('failed');
      useEffect(() => {
        // Listen for the online event
        const handleOnline = () => {
            
            // Send stored messages if any
            const storedMessages = JSON.parse(localStorage.getItem('storedMessages')) || [];
            storedMessages.forEach(message => {
                sendMessage({
                    "ID": chatConveration.ID,
                    "Msg": message,
                    "To_BusinessID": chatConveration.To_BusinessID,
                    "Type": "Message"
                }).then(response => {
                    if (response.type === "sendMessage/fulfilled") {
                        console.log("Message sent:", message);
                        setSingleTick('success');
                    } else {
                        console.error("Failed to send message:", response.error);
                        setSingleTick('waiting');
                    }
                }).catch(error => {
                    console.error("Error sending message:", error);
                    setSingleTick('failed');
                });
            });
            // Clear stored messages
            localStorage.removeItem('storedMessages');
        };

        window.addEventListener('online', handleOnline);

        return () => {
            window.removeEventListener('online', handleOnline);
        };
    },  []);
    function SendMessage(e) {
        
        e.preventDefault();
        if (Message.trim().length == 0) {
         return   
        }
        if (!navigator.onLine) {
            // Store the message in local storage if offline
            console.log("offline")
            const storedMessages = JSON.parse(localStorage.getItem('storedMessages')) || [];
            storedMessages.push(Message);
            localStorage.setItem('storedMessages', JSON.stringify(storedMessages));
            setSentMessage(Message);
            setSingleTick('failed');
            setMessage(""); // Clear the input field
            return;
        }
        
        setMessage("");
        sendMessage({
            "ID": chatConveration.ID,
            "Msg" : Message,
            "To_BusinessID" : chatConveration.To_BusinessID,
            "Type": "Message"
        }).then(response => {
            if (response.type==="sendMessage/fulfilled") {
                console.log(response.type);
                setSingleTick('success');
                
            } 
            else if(response.type==="sendMessage/unfulfilled"){
                console.error("Failed to send message:", response.error);
                setSingleTick('waiting');
            }
            else{
                setSingleTick('failed');
            }
        }).catch(error => {
            console.error("Error sending message:", error);
        });
       
    }

   
    // useEffect(() => {
    //     const timer = setTimeout(() => {
    //         setShowDoubleTick(true);
    //     }, 5000);

    //     return () => clearTimeout(timer);
    // }, []);

    const handleActionSheet = () => {
        showChatActionSheet({chatActionSheetVisible: true})
    }
    return (
        <div style={{'minWidth' : '300px'}}>
            
            <div style={{height: '100vh', overflow: 'auto', paddingBottom: '96px'}}>

                {
                    chatConveration?.Conversation?.map((singleChat) => {
                       
                        return (
                            <>
                            {
                                singleChat.From_userID === USERID && singleChat.Type === 'Message' &&
                                <div className="message-item user">
                                    <div className="content">
                                        <div className="bubble">
                                            {singleChat.Message}
                                        </div>
                                        <div className="footer">{singleChat.DateTime}</div>
                                        <div className='readRecipients'>
                                        {/* {formatDateTimeYYYYMMDDHHMMSS(new Date())} */}
                                        {/* {singleTick?(
                                         
                                            ):(<ion-icon name="time-outline"></ion-icon>
                                            )} */}
                                             <ion-icon width={30} name="checkmark-done-outline"></ion-icon>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                singleChat.From_userID !== USERID && singleChat.Type === 'Message' &&
                                <div class="message-item">
                                    <img src="assets/img/sample/avatar/avatar1.jpg" alt="avatar" class="avatar" />
                                    <div class="content">
                                        {/* <div class="title">John</div> */}
                                        <div class="bubble">
                                            {singleChat.Message}
                                        </div>
                                        <div class="footer">{singleChat.DateTime}</div>
                                    </div>
                                </div>
                            }
                            {
                                singleChat.From_userID === USERID && singleChat.Type === 'Table' &&
                                <div className="message-item user">
                                    <div className="content w-100">
                                        <div className="bubble">
                                            {Object.keys(JSON.parse(singleChat?.Message)).map((k,v) => {
                                                return <> 
                                                    <span>{k}: {JSON.parse(singleChat?.Message)[k]}</span><br />
                                                </>
                                            })}
                                        </div>
                                        <div className="footer">{singleChat.DateTime}</div>
                                        <div className='readRecipients'>
                                        <ion-icon  name="checkmark-outline"></ion-icon>
                                            
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                singleChat.Type === 'Badge' &&
                                <div class="message-divider">
                                    {singleChat.Message}
                                </div>
                            }
                            
                            </>
                        )
                    })
                }
                {
                    SentMessage && <div className="message-item user">
                        <div className="content">
                            <div className="bubble">
                                {SentMessage}
                            </div>
                            <div className="footer">{formatDateTimeYYYYMMDDHHMMSS(new Date())}</div>
                            <div className='readRecipients'>
                            {singleTick==='success'&& <ion-icon  name="checkmark-outline"></ion-icon>}
                            {singleTick==='waiting'&& <ion-icon name="time-outline"></ion-icon>}
                            {singleTick==='failed' && <ion-icon style={{color:'red'}} name="time-outline"></ion-icon>}


                            {/* <ion-icon name="time-outline"></ion-icon> */}
                            
                            </div>
                        </div>
                    </div>
                }

                {
                    (chatConveration?.Conversation === null || chatConveration?.Conversation?.length === 0) && SentMessage !== null &&
                    <div className='h-100 w-00 d-flex justify-content-center align-items-center'>
                        <span>Start Conversation...</span>
                    </div>
                }
            </div>

            <div class="chatFooter position-absolute" style={{bottom: 0}}>
                <form onSubmit={SendMessage}className={`d-flex align-items-center ${Message ? 'justify-content-between' : ''}`}>
                   { Message && <button class="btn btn-icon btn-secondary rounded" disabled onClick={handleActionSheet}>
                        <ion-icon name="add"></ion-icon>
                    </button>}
                    <div class="form-group boxed"  >
                        <div class="input-wrapper">
                            <input type="text" style={{ width: Message===""?'225px':"" }}  class="form-control" value={Message} onChange={(e) => setMessage(e.target.value)} placeholder="Type a message..." required />
                            <i class="clear-input">
                                <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                    {Message ?<button type="submit" style={{margin:'10px 0px',paddingLeft:'0'}} class="btn btn-icon bg-primary rounded">
                        <ion-icon name="send"></ion-icon>
                    </button>:
                     <button class="btn btn-icon btn-secondary rounded" disabled onClick={handleActionSheet}>
                     <ion-icon name="add"></ion-icon>
                 </button>}
                </form>
            </div>
        </div>
    )
}

const mapStateToProps = ({ chatView, auth }) => {
    const { chatConveration, ChatBuisnessData, Message, SentMessage } = chatView
    const { USERID } = auth
    return {
        chatConveration,
        ChatBuisnessData,
        USERID,
        Message,
        SentMessage
    }
}
  
const mapDispatchToProps = {
    hideChatThreadViewOffcanvas,
    setMessage,
    sendMessage,
    setSentMessage,
    showChatActionSheet
}

export default connect(mapStateToProps, mapDispatchToProps)(UserChatView)