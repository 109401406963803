import { mappls } from 'mappls-web-maps'
import { useEffect, useRef, useState } from 'react'
import { FILTERLIST } from '../../config'
import { Splide, SplideSlide } from '@splidejs/react-splide'

const mapplsClassObject = new mappls()

const App = () => {
  const map = useRef(null)
  const [isMapLoaded, setIsMapLoaded] = useState(false)
  const [markerLocation, setMarkerLocation] = useState([18.987982, 73.110498]) // Center marker location

  const specifiedMarkers = [
    [18.999103935972553, 73.12302484065006],
    [19.000916534415317, 73.1232608750455],
    [19.002945621005264, 73.12544407358324],
    [19.002600720139643, 73.12232198228213],
    [19.005278755540388, 73.12299789895359]
  ]

  const Array1 = [
    {
      logo: 'https://www.svgrepo.com/show/220086/health-clinic-health-care.svg',
      filter: FILTERLIST.FILTER1.filter,
      text: FILTERLIST.FILTER1.text
    },
    {
      logo: 'https://www.svgrepo.com/show/398447/teacher.svg',
      filter: FILTERLIST.FILTER2.filter,
      text: FILTERLIST.FILTER2.text
    },
    {
      logo: 'https://www.svgrepo.com/show/500084/factory.svg',
      filter: FILTERLIST.FILTER3.filter,
      text: FILTERLIST.FILTER3.text
    },
    {
      logo: 'https://www.svgrepo.com/show/52533/photographer.svg',
      filter: FILTERLIST.FILTER4.filter,
      text: FILTERLIST.FILTER4.text
    },
    {
      logo: 'https://www.svgrepo.com/show/362148/shop.svg',
      filter: FILTERLIST.FILTER5.filter,
      text: FILTERLIST.FILTER5.text
    },
    {
      logo: 'https://www.svgrepo.com/show/362124/map-map-marker.svg',
      filter: FILTERLIST.FILTER6.filter,
      text: FILTERLIST.FILTER6.text
    },
    {
      logo: 'https://www.svgrepo.com/show/476994/city.svg',
      filter: FILTERLIST.FILTER7.filter,
      text: FILTERLIST.FILTER7.text
    }
  ]

  useEffect(() => {
    mapplsClassObject.initialize(
      '176e217706b57cf254f6ee42dd41f0a7',
      { map: true },
      () => {
        if (map.current) {
          map.current.remove()
        }
        map.current = mapplsClassObject.Map({
          id: 'map',
          properties: {
            center: markerLocation, // Center the map at the marker location
            draggable: true,
            zoom: 5,
            minZoom: 8,
            maxZoom: 15,
            backgroundColor: '#fff',
            heading: 100,
            traffic: true,
            geolocation: false,
            disableDoubleClickZoom: true,
            fullscreenControl: true,
            scrollWheel: true,
            scrollZoom: true,
            rotateControl: true,
            scaleControl: true,
            zoomControl: true,
            clickableIcons: true,
            indoor: true,
            indoor_position: 'bottom-left',
            tilt: 30
          }
        })
        map.current.on('load', () => {
          setIsMapLoaded(true)
        })
      }
    )
  }, [])

  useEffect(() => {
    if (isMapLoaded) {
      const mainMarker = new mapplsClassObject.Marker({
        position: markerLocation,
        map: map.current
      })

      specifiedMarkers.forEach(([lat, lon]) => {
        new mapplsClassObject.Marker({
          position: [lat, lon],
          map: map.current
        })
      })
    }
  }, [isMapLoaded, markerLocation])

  return (
    <div
      id="map"
      style={{
        width: '100%',
        height: '99vh',
        display: 'inline-block',
        position: 'relative'
      }}
    >
      <div className="form-group searchbox">
        <i className="text-icon">
          <ion-icon name="search-outline"></ion-icon>
        </i>
        <input
          type="filter"
          className="form-control"
          // onFocus={openSearchInput}
          placeholder="Search..."
        />
      </div>
      <Splide
        options={{
          type: 'loop',
          rewind: false,
          gap: 5,
          perMove: 1,
          autoplay: false,
          easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
          pagination: false,
          arrows: false,
          breakpoints: {
            700: {
              perPage: 4
            },
            1200: {
              perPage: 2
            },
            1600: {
              perPage: 3
            },
            2000: {
              perPage: 3
            }
          }
        }}
        style={{
          position: 'absolute',
          top: '10px', // Adjust based on your needs
          left: '50%',
          transform: 'translateX(-50%)',
          width: '100%', // Adjust based on your needs
          background: 'transparent'
        }}
      >
        {Array1.map((itm, i) => (
          <SplideSlide key={i}>
            <div
              className="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
              style={{
                height: '67px',
                width: 'auto',
                backgroundSize: 'cover', // Ensure the image covers the entire div
                backgroundPosition: 'center',
                borderRadius: '25px'
              }}
              onClick={() => {
                // showFindViewOffcanvas({
                //   findViewOffcanvasVisible: true
                // })
                // setSelectedFilter(itm)
              }}
            ></div>
            <span
              className="two-line-limit  text-nowrap"
              style={{
                fontWeight: 'bold',
                color: 'white',
                padding: '3px 10px',
                textAlign: 'center',
                fontSize: '10px',
                marginTop: '2px',
                lineHeight: '15px',
                backgroundColor: '#2235ffbd',
                borderRadius: '20px'
              }}
            >
              {itm.text}
            </span>
          </SplideSlide>
        ))}
      </Splide>
      {isMapLoaded}
    </div>
  )
}

export default App
