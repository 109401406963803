import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { connect } from "react-redux"
import QuickMeetModal from '../Sidebar/QuickMeetModal'
import { showQuickMeetModal } from '../../../features/offcanvas/QuickMeetModalSlice'

const Footer = ({
    showQuickMeetModal
}) => {
    const handleQuickMeetState = () => {
        showQuickMeetModal({quickMeetModalVisible: true})
    }
    let navigate = useNavigate()
    return (
        <>
            <QuickMeetModal></QuickMeetModal>
            <div>
                <div class="section full mt-2 d-block d-md-none">
                    <div class="appBottomMenu flex-column d-flex">
                        <div class="w-100 d-flex">
                            <Link to="/home" class="item text-secondary">
                                <div class="col">
                                    <ion-icon name="home-outline" class="fs-4"></ion-icon>
                                    <strong>Home</strong>
                                </div>
                            </Link>
                            <Link to="/find" class="item text-secondary">
                                <div class="col d-flex justify-content-center align-items-center flex-column">
                                        <ion-icon name="search-outline" class="fs-4"></ion-icon>
                                    <strong>Find</strong>
                                </div>
                            </Link>
                            <a href="javascript:void(0)" class="item" onClick={() => {navigate('/nanoWebsites')}}>
                                <div class="col d-flex justify-content-center align-items-center flex-column">
                                    <div class="dropdown text-secondary fs-3 rounded-circle bg-primary d-flex align-items-center" style={{width: '40px', height: '40px', marginTop: '-18px'}}>
                                        <ion-icon name="albums-outline" class="fs-1 text-white"></ion-icon>
                                    </div>
                                    <strong>1 to 1</strong>
                                </div>
                            </a>  
                            <Link to="/MessagesView" class="item text-secondary">
                                <div class="col">
                                    <ion-icon name="chatbubbles-outline" class="fs-4"></ion-icon>
                                    <strong>Message</strong>
                                </div>
                            </Link>
                            <Link to="/manage" class="item">
                                <div class="col d-flex justify-content-center align-items-center flex-column">
                                    <div class="webweb-w-40 dropdown text-secondary fs-3">
                                        <img src="assets/img/sample/avatar/avatar3.jpg" alt="image" style={{width: '28px', borderRadius: '100px'}} />
                                    </div>
                                    <strong>My Site</strong>
                                </div>
                            </Link>  
                            {/* <Link to="/MeetView" class="item text-secondary">
                                <div class="col d-flex justify-content-center align-items-center flex-column">
                                        <ion-icon name="people-outline" class="fs-4"></ion-icon>
                                    <strong>Meet</strong>
                                </div>
                            </Link> */} 
                        </div>                     
                    </div>
                </div>
            </div>   
        </>
    );
}

const mapStateToProps = () => {
    return {
    }
  }
  
  const mapDispatchToProps = {
    showQuickMeetModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(Footer)