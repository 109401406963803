import React, { useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import { connect } from 'react-redux'
import MoreDetails from '../../Pages/MoreDetails'
import { hideDetailViewModal } from '../../../features/offcanvas/DetailViewSlice'

const MoreDetailModal = ({
    detailViewModalVisible,
    hideDetailViewModal
}) => {
  const handleCloseMoreDetailModal = () => {
    hideDetailViewModal()
  }
  return (
    <Modal className="animate-modal" show={detailViewModalVisible} fullscreen={true} style={{transition: '0.2s ease-in-out'}}>
        <MoreDetails></MoreDetails>
    </Modal>
  )
}

const mapStateToProps = ({ detailView }) => {
    const {
        detailViewModalVisible
    } = detailView
  return {
    detailViewModalVisible
  }
}

const mapDispatchToProps = {
  hideDetailViewModal
}

export default connect(mapStateToProps, mapDispatchToProps)(MoreDetailModal)
