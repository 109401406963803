import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'
import NanoWebsite from './NanoWebsite'
import { connect } from 'react-redux'
import Moment from 'react-moment'
import Axios from '../../AxiosConfig'
import axios from 'axios'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import Tour from './Comps/Tour'
import {
  setNanoWebsiteData,
  setCardLogs
} from '../../features/nanoWebsite/nanoWebsiteSlice'
import MoreDetailModal from '../layout/Sidebar/MoreDetailModal'
import {
  hideFindViewOffcanvas,
  showFindViewOffcanvas
} from '../../features/offcanvas/FindViewSlice'
import FindViewOffcanvas from '../layout/Sidebar/FindViewOffcanvas'
import ShareOffcanvas from '../layout/Sidebar/ShareOffcanvas'
import ChatViewOffcanvas from '../layout/Sidebar/ChatViewOffcanvas'
import {
  showChatViewOffcanvas,
  hideChatViewOffcanvas
} from '../../features/offcanvas/ChatViewSlice'
import ChatThreadViewOffcanvas from '../layout/Sidebar/ChatThreadViewOffcanvas'
import LoaderCard from './Comps/LoaderCard'
import CityListModal from '../layout/Sidebar/CityListModal'
import {
  setUserCurrentLocation,
  setBizCategory,
  setBizLogo,
  setBizBanner
} from '../../features/offcanvas/MyBusinessSlice'
import ImageUploadModal from '../layout/Sidebar/ImageUploadModal'
import AuthModal from '../layout/AuthModal/AuthModal'
import QrPage from './QrPage'
import { showAuthModal } from '../../features/auth/authSlice'
import ConfirmationModal from '../layout/Sidebar/ConfirmationModal'
import ChatActionSheetOffcanvas from '../layout/Sidebar/ChatActionSheetOffcanvas'
import { useLocationHook } from '../../hooks/useLocationHook'
import { useAxios } from '../../hooks/useAxios'
import FirstCard from './FirstCard'

const NanoWebsiteState = ({
  nanoWebsiteData,
  setNanoWebsiteData,
  showFindViewOffcanvas,
  hideFindViewOffcanvas,
  showChatViewOffcanvas,
  showQRPage,
  setShowQRPage,
  hideChatViewOffcanvas,
  authToken,
  cardLogs,
  setCardLogs,
  setUserCurrentLocation,
  setBizCategory,
  BizCategory,
  setBizLogo,
  BizLogo,
  BizBanner,
  setBizBanner,
  userCurrentLocation,
  showAuthModal,
  selectedFilter
}) => {
  const [ShowTour, setShowTour] = useState(false)
  const [currentNewIndex, setCurrentNewIndex] = useState(0)
  const [buttonConfig, setButtonConfig] = useState({
    color: 'red'
  })
  const [tempData, setTempData] = useState([])
  const [loading, setLoading] = useState(true)
  const [pageIndex, setPageIndex] = useState(0)
  const [tempCurrentIndex, setTempCurrentIndex] = useState(0)
  const [tapped, setTapped] = useState(false)
  const [postData, setPostData] = useState(null)
  const [prevIndex, setPrevIndex] = useState(0)
  const [windowWidth, setWindowWidth] = useState(0)
  const [newCardLogs, setNewCardLogs] = useState([])
  const [dateTime, setDateTime] = useState(null)
  const splideRef = useRef(null)
  const wrapperRef = useRef(null)
  let navigate = useNavigate()
  const { getUserLocation } = useLocationHook()
  const [overlayImage, setoverlayImage] = useState()

  const WEBWEBAPPVARIABLE = 'WEBWEBAPP'

  const [isVisible, setIsVisible] = useState(false)
  const divRef = useRef(null)

  const { BID } = useParams()

  const { AxiosCall, Loading } = useAxios()
  const [searchText, setSearchText] = useState('')
  const [searchResult, setSearchResult] = useState(null)

  function GOTOTOP() {
    wrapperRef.current.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  function GOTOBUSI() {
    wrapperRef.current.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    })
  }

  useEffect(() => {
    let timer
    if (searchText.trim().length >= 3) {
      const data = {
        keyword: searchText
      }
      timer = setTimeout(async () => {
        const suggesstions = await AxiosCall({
          method: 'post',
          url: 'businesses/search',
          sendData: data
        })
        setSearchResult(suggesstions)
      }, 1000)
    }

    if (searchText.trim().length < 3) {
      setSearchResult(null)
    }

    return () => clearTimeout(timer)
  }, [searchText])

  function GoToSearch(data) {
    setPageIndex(0)
    firstData(data.ShareableURL)
    setTempData([])
    console.log('GOTOSEARCH')
    navigate(`/${data.ShareableURL}`)
    setSearchText('')
    setSearchResult(null)
    if (!isVisible) {
      GOTOBUSI()
    }
  }

  useEffect(() => {
    if (
      BID &&
      BID !== 'undefined' &&
      BID !== WEBWEBAPPVARIABLE &&
      tempData &&
      tempData.length === 0
    ) {
      // console.log({BID , tempData}, window.innerHeight)
      GOTOBUSI()
    }
    if (BID === undefined || BID === 'undefined') {
      console.log('IF', WEBWEBAPPVARIABLE)
      navigate(`/${WEBWEBAPPVARIABLE}`)
    }

    // if (nanoWebsiteData[tempCurrentIndex]?.ShareableURL != 'undefined') {
    //     console.log("ELSE")
    //     navigate(`/${nanoWebsiteData[tempCurrentIndex]?.ShareableURL}`)
    //   }
    //   console.log('one', BID)
    // }
  }, [])

  useEffect(() => {
    if (BID === WEBWEBAPPVARIABLE) {
      // console.log(BID);
      GOTOTOP()
    }
  }, [BID])

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting)
      },
      {
        root: null, // relative to the viewport
        rootMargin: '0px',
        threshold: 0.1 // trigger when at least 10% of the element is in the viewport
      }
    )

    if (divRef.current) {
      observer.observe(divRef.current)
    }

    return () => {
      if (divRef.current) {
        observer.unobserve(divRef.current)
      }
    }
  }, [divRef])

  useEffect(() => {
    if (isVisible) {
      console.log(
        'ISVISIBLE',
        tempCurrentIndex,
        isVisible,
        nanoWebsiteData[tempCurrentIndex]
      )
      navigate(`/${nanoWebsiteData[tempCurrentIndex]?.ShareableURL}`)
      const BizCat = nanoWebsiteData[tempCurrentIndex]?.BusinessCategory
      Axios.get(`/categories/${BizCat}`).then(res => {
        setBizBanner(res.data.data.Background)
        setBizLogo(res.data.data.Icons)
        // console.log(res)
      })
      // console.log(overlayImage)
      // console.log(BizCategory)
    }
  }, [isVisible, tempCurrentIndex, nanoWebsiteData])

  // const [location, setLocation] = useState('')
  useEffect(() => {
    getUserLocation()
    // const fetchLocation = async () => {
    //   if ('geolocation' in navigator) {
    //     try {
    //       const position = await new Promise((resolve, reject) => {
    //         navigator.geolocation.getCurrentPosition(resolve, reject)
    //       })

    //       const { latitude, longitude } = position.coords

    //       const response = await fetch(
    //         `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
    //       )
    //       const data = await response.json()

    //       const city =
    //         data.address.city ||
    //         data.address.town ||
    //         data.address.village ||
    //         data.address.hamlet ||
    //         ''
    //       const country = data.address.country || ''
    //       // setLocation(city)
    //       setUserCurrentLocation({ userCurrentLocation: city })
    //     } catch (error) {
    //       console.error('Error getting location:', error.message)
    //     }
    //   } else {
    //     console.error('Geolocation is not supported by this browser.')
    //   }
    // }
    // fetchLocation()
  }, [])

  function firstData(firstURL) {
    const sharedUrl = BID
    if (authToken !== undefined && authToken !== null && authToken !== '') {
      // const sharedUrl = window.location.href.split('/')[3]
      let landOnUrl
      if (sharedUrl != 'undefined') {
        landOnUrl = '&sharedUrl=' + sharedUrl
      }
      // console.log(landOnUrl);

      if (firstURL) {
        landOnUrl = '&sharedUrl=' + firstURL
      }
      Axios.get('/businesses?pageSize=15&pageIndex=0' + landOnUrl).then(res => {
        if (res.data.message === 'Success') {
          setNanoWebsiteData({
            nanoWebsiteData: res.data.data.items,
            bufferData: null
          })
          // console.log('Success');
          setPageIndex(pageIndex + 1)
          // navigate((prevState === null) ? '/find'  : '/' + prevState)
        } else {
        }
      })
    } else {
      // const sharedUrl = window.location.href.split('/')[3]
      let landOnUrl
      if (sharedUrl != 'undefined') {
        landOnUrl = '&sharedUrl=' + sharedUrl
      }
      if (firstURL) {
        landOnUrl = '&sharedUrl=' + firstURL
      }
      console.log('else')
      axios
        .get(
          'https://app-api.webweb.ai/businesses?pageSize=15&pageIndex=0' +
            landOnUrl
        )
        .then(res => {
          if (res.data.message === 'Success') {
            setNanoWebsiteData({
              nanoWebsiteData: res.data.data.items,
              bufferData: null
            })
          } else {
          }
        })
    }
  }

  useEffect(() => {
    firstData()
  }, [authToken])

  useEffect(() => {
    if (nanoWebsiteData.length > 0) {
      setTempData(nanoWebsiteData)
      setPostData(nanoWebsiteData[tempCurrentIndex])
      setNewCardLogs([
        {
          BusinessID: nanoWebsiteData[tempCurrentIndex].ID,
          LocalTime: getDateTime()
        }
      ])
      setTimeout(() => {
        setLoading(false)
      }, 100)
    } else {
      localStorage.setItem('prevState', 'nanoWebsites')
    }
  }, [nanoWebsiteData])

  const GoToHomeCard = () => {
    console.log('HOME')
    navigate(`/${WEBWEBAPPVARIABLE}`)
  }

  const handleNavigation = () => {
    if (authToken != undefined && authToken != null && authToken != '') {
      showFindViewOffcanvas({ findViewOffcanvasVisible: true })
    } else {
      showAuthModal({ authModalVisible: true })
      // alert('Please login to continue')
    }
  }

  let currentIndex = 0
  const getDateTime = () => {
    var now = new Date()
    var year = now.getFullYear()
    var month = ('0' + (now.getMonth() + 1)).slice(-2)
    var day = ('0' + now.getDate()).slice(-2)
    var hours = ('0' + now.getHours()).slice(-2)
    var minutes = ('0' + now.getMinutes()).slice(-2)
    var seconds = ('0' + now.getSeconds()).slice(-2)
    var milliseconds = ('00' + now.getMilliseconds()).slice(-3)
    var formattedDateTime =
      year +
      '-' +
      month +
      '-' +
      day +
      ' ' +
      hours +
      ':' +
      minutes +
      ':' +
      seconds +
      ':' +
      milliseconds
    return formattedDateTime
  }

  const handleActive = e => {
    setTimeout(() => {
      setDateTime(getDateTime())
      currentIndex = splideRef.current.splide.index
      setTempCurrentIndex(currentIndex)
      changeBackgroundColor(nanoWebsiteData[currentIndex]?.Background)
    }, 1)
  }
  useEffect(() => {
    setCardLogs({ cardLogs: cardLogs.concat(newCardLogs) })
  }, [newCardLogs])

  useEffect(() => {
    var search = document.getElementById('search')
    search.classList.remove('show')
    if (tempCurrentIndex > prevIndex) {
      setTapped(false)
    } else {
      setTapped(true)
    }
    // const sharedUrl = window.location.href.split('/')[3]
    const sharedUrl = BID
    if (sharedUrl != 'undefined') {
      if (authToken != undefined && authToken != null && authToken != '') {
        if (nanoWebsiteData.length - tempCurrentIndex < 7) {
          swipeData()
        }
      }
    }
    // if (nanoWebsiteData[tempCurrentIndex]?.ShareableURL != 'undefined') {
    //   navigate(`/${nanoWebsiteData[tempCurrentIndex]?.ShareableURL}`)
    // }
  }, [tempCurrentIndex])

  function swipeData() {
    Axios.get('/businesses?pageSize=15&pageIndex=' + pageIndex).then(res => {
      if (res.data.message === 'Success') {
        // setNanoWebsiteData({nanoWebsiteData: nanoWebsiteData.concat(res.data.data.items), bufferData: null})
        setNanoWebsiteData({
          nanoWebsiteData: [...nanoWebsiteData, ...res.data.data.items]
        })
        setPageIndex(pageIndex + 1)
      } else {
      }
    })
  }

  const [backgroundColor, setBackgroundColor] = useState('#6C7C94') // Initial background color
  let randomColor = ''
  useEffect(() => {
    if (tempData.length > 0) {
      if (tempCurrentIndex === 0) {
        setTimeout(() => {
          setBackgroundColor(
            tempData[0].Background ? tempData[0].Background : '#000000'
          )
          setNewCardLogs([
            {
              BusinessID: nanoWebsiteData[0].ID,
              LocalTime: dateTime
            }
          ])
        }, 100)
      } else {
        setBackgroundColor(
          tempData[tempCurrentIndex].Background
            ? tempData[tempCurrentIndex].Background
            : '#000000'
        )
        setNewCardLogs([
          {
            BusinessID: nanoWebsiteData[tempCurrentIndex].ID,
            LocalTime: dateTime
          }
        ])
      }
    }
  }, [tempCurrentIndex])
  const changeBackgroundColor = color => {
    setBackgroundColor(color)
    setButtonConfig({
      color: color
    })
  }

  function setHideTour() {
    setShowTour(false)
    localStorage.setItem('TourStatus', 'Hide')
  }

  useEffect(() => {
    if (
      localStorage.getItem('TourStatus') === undefined ||
      localStorage.getItem('TourStatus') === null ||
      localStorage.getItem('TourStatus') === 'Show'
    ) {
      setShowTour(true)
    }
  }, [])

  const onTap = () => {
    setTapped(!tapped)
  }

  const openSearchInput = () => {
    var search = document.getElementById('search')
    var a = search.classList.contains('show')
    if (a) {
      search.classList.remove('show')
      setSearchText('')
      setSearchResult(null)
    } else {
      search.classList.add('show')
      search.querySelector('.form-control').focus()
    }
  }

  const touchStartX = useRef(null)
  const touchEndX = useRef(null)

  const handleTouchStart = e => {
    if (splideRef.current) {
      setPrevIndex(splideRef.current.splide.index)
      touchStartX.current = e.touches[0].clientX
    }
  }

  const handleTouchEnd = e => {
    touchEndX.current = e.changedTouches[0].clientX
    handleSwipe()
  }

  const handleSwipe = () => {
    const swipeDistance = touchStartX.current - touchEndX.current
    const swipeThreshold = 50

    if (swipeDistance > swipeThreshold) {
      console.log('Swiped left')
    } else if (swipeDistance < -swipeThreshold) {
      console.log('Swiped right')
    }
  }

  useEffect(() => {
    const preventSwipeBack = event => {
      window.history.pushState(null, null, window.location.pathname)
      event.preventDefault()
      window.removeEventListener('popstate', preventSwipeBack)
    }

    window.addEventListener('popstate', preventSwipeBack)
    //
    return () => {
      window.removeEventListener('popstate', preventSwipeBack)
    }
  }, [])

  useEffect(() => {
    setWindowWidth(window.innerWidth)
    let setPostLog
    if (cardLogs.length > 0) {
      setPostLog = setInterval(() => {
        if (authToken != undefined && authToken != null && authToken != '') {
          Axios.post('/behaviour', { behaviourData: cardLogs }).then(res => {
            if (res.data.message === 'Success') {
              setCardLogs({ cardLogs: [] })
            } else {
            }
          })
        }
      }, 10000)
    }

    return () => {
      clearInterval(setPostLog)
    }
  }, [cardLogs])

  useEffect(() => {
    document.addEventListener('keydown', function (event) {
      const keyType = event.keyCode
      if (keyType == 40) {
        if (splideRef.current) {
          splideRef.current.go(splideRef.current.splide.index + 1)
        }
      } else if (keyType == 38) {
        if (splideRef.current) {
          splideRef.current.go(splideRef.current.splide.index - 1)
        }
      }
    })
  }, [])

  const handleChatView = () => {
    if (authToken != undefined && authToken != null && authToken != '') {
      showChatViewOffcanvas({ chatViewOffcanvasVisible: true })
    } else {
      showAuthModal({ authModalVisible: true })
      // alert('Please login to continue')
    }
  }

  return (
    <>
      <div
        class="appHeader border-0 safe-area-top w-custom-desktop-header-footer m-auto"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        style={{
          minHeight: 'auto',
          height: tapped ? '42px' : '0px',
          background: 'rgba(255,255,255,0.9)',
          bottom: 'auto',
          transition: 'height 0.2s ease',
          zIndex: 999,
          marginTop: tapped ? '0px' : '-100px'
        }}
      >
        <a
          href="javascript:void(0)"
          class="item d-flex justify-content-between w-100 safe-area-top"
          style={{
            height: 'auto',
            opacity: tapped ? '1' : '0',
            color: 'inherit'
          }}
        >
          <h3
            class="mb-0 d-flex align-items-center ps-1"
            onClick={handleNavigation}
            style={{ fontSize: '14px' }}
          >
            <ion-icon
              name="menu-outline"
              style={{ fontSize: '24px' }}
            ></ion-icon>
            &nbsp;
            {/* {postData?.BusinessCategory} */}
            {selectedFilter.text}
          </h3>
          {userCurrentLocation && (
            <h3
              class="mb-0 d-flex align-items-center pe-1"
              style={{ fontSize: '14px' }}
            >
              <ion-icon
                name="location-outline"
                style={{ fontSize: '14px' }}
              ></ion-icon>
              {userCurrentLocation}
            </h3>
          )}
        </a>
      </div>
      <div
        class="section-bg position-fixed"
        style={{
          backgroundColor: backgroundColor ? backgroundColor : '#000000',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <div
          className="contianerCardsWrapper"
          onClick={() => {
            onTap()
          }}
          ref={wrapperRef}
        >
          <FirstCard openSearchInput={openSearchInput} />
          <div ref={divRef}>
            {loading ? (
              <>
                <div class="section d-flex justify-content-center align-items-center vh100-height">
                  <LoaderCard />
                </div>
              </>
            ) : (
              <Splide
                ref={splideRef}
                id="splide"
                options={{
                  direction: 'ttb',
                  height: '100%',
                  width: '100%',
                  autoWidth: true,
                  autoHeight: true,
                  perPage: 1,
                  perMove: 1,
                  gap: 0,
                  pagination: false,
                  arrows: windowWidth > 700 ? true : false,
                  type: 'slide',
                  flickPower: 200,
                  flickMaxPages: 1,
                  wheel: windowWidth > 700 ? true : false,
                  wheelMinThreshold: 150
                }}
                className={'vh100-height'}
                onActive={handleActive}
                onTouchStart={handleTouchStart}
                onTouchEnd={handleTouchEnd}
              >
                {tempData.map((v, k) => {
                  return (
                    <SplideSlide key={k}>
                      <NanoWebsite
                        data={v}
                        button={v.Background ? v.Background : '#000000'}
                      ></NanoWebsite>
                    </SplideSlide>
                  )
                })}
              </Splide>
            )}
          </div>
        </div>
        {/* {!loading && (
          <>
            {ShowTour && tempData && tempData.length > 0 && (
              <Tour setHideTour={setHideTour} />
            )}
          </>
        )} */}
      </div>
      <div
        class="appBottomMenu border-0 align-items-start m-auto w-custom-desktop-header-footer"
        style={{
          minHeight: tapped ? '42px' : '0px',
          background: 'rgba(255,255,255,0.9)',
          transition: 'height 0.2s ease',
          zIndex: 999,
          bottom: tapped ? '0px' : '-100px'
        }}
      >
        <div
          class="item"
          style={{
            height: tapped ? '42px' : '0px',
            opacity: tapped ? '1' : '0'
          }}
        >
          <div class="col" onClick={GoToHomeCard}>
            <ion-icon name="home-outline"></ion-icon>
          </div>
        </div>
        <a
          href="javascript:void(0)"
          class="item"
          style={{
            height: tapped ? '48px' : '0px',
            opacity: tapped ? '1' : '0'
          }}
        >
          <div class="col" onClick={openSearchInput}>
            <ion-icon name="search-outline"></ion-icon>
          </div>
        </a>
        <a
          href="javascript:void(0)"
          class="item"
          style={{
            height: tapped ? '48px' : '0px',
            opacity: tapped ? '1' : '0'
          }}
        >
          <div
            class="col"
            onClick={() => {
              handleChatView()
            }}
          >
            <ion-icon name="chatbubbles-outline"></ion-icon>
          </div>
        </a>
      </div>
      <MoreDetailModal></MoreDetailModal>
      <FindViewOffcanvas
        List={tempData}
        pageIndex={pageIndex}
      ></FindViewOffcanvas>
      <ShareOffcanvas></ShareOffcanvas>
      <ChatViewOffcanvas></ChatViewOffcanvas>
      <ChatThreadViewOffcanvas></ChatThreadViewOffcanvas>
      <CityListModal></CityListModal>
      <ImageUploadModal></ImageUploadModal>
      <AuthModal></AuthModal>
      <QrPage></QrPage>
      {/* { showQRPage&&<QrPage></QrPage>  */}
      <ConfirmationModal></ConfirmationModal>
      <ChatActionSheetOffcanvas></ChatActionSheetOffcanvas>
      {/* <LoginOffcanvas></LoginOffcanvas> */}
      <div id="search" class="appHeader" style={{ minHeight: '42px' }}>
        <div class="search-form">
          <div class="form-group searchbox">
            <input
              type="text"
              class="form-control"
              placeholder="Search..."
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
              style={{ height: '42px' }}
            />
            <i
              class="input-icon"
              style={{ height: '42px' }}
              onClick={() => {
                setSearchText('')
                setSearchResult(null)
              }}
            >
              <ion-icon name="search-outline"></ion-icon>
            </i>
            <a
              href="#"
              class="ms-1 close toggle-searchbox"
              onClick={openSearchInput}
              style={{ height: '42px' }}
            >
              <ion-icon name="close-circle"></ion-icon>
            </a>
          </div>
        </div>
        {searchResult && (
          <div className="suggesstions">
            {searchResult.length > 0 ? (
              <ul class="listview link-listview search-result">
                {searchResult.map((itm, i) => {
                  return (
                    <li key={i} onClick={() => GoToSearch(itm)}>
                      <a href="#" className="py-1">
                        <div>
                          <h4 class="mb-05">{itm.BusinessName}</h4>
                          <div class="text-muted">
                            <div class="mt-05 text-truncate">
                              <strong>{itm.Description}</strong>
                            </div>
                          </div>
                        </div>
                      </a>
                    </li>
                  )
                })}
              </ul>
            ) : (
              <h4 className="text-center my-0">No result found...</h4>
            )}
          </div>
        )}
      </div>
    </>
  )
}

const mapStateToProps = ({ nanoWebsite, auth, myBusiness, findView }) => {
  const { nanoWebsiteData, cardLogs } = nanoWebsite
  const { authToken } = auth
  const { userCurrentLocation, BizCategory, BizLogo, BizBanner } = myBusiness
  const { selectedFilter } = findView
  return {
    nanoWebsiteData,
    authToken,
    BizCategory,
    BizLogo,
    BizBanner,
    cardLogs,
    userCurrentLocation,
    selectedFilter
  }
}

const mapDispatchToProps = {
  setNanoWebsiteData,
  showFindViewOffcanvas,
  showChatViewOffcanvas,
  hideFindViewOffcanvas,
  hideChatViewOffcanvas,
  setBizCategory,
  setCardLogs,
  setUserCurrentLocation,
  setBizLogo,
  setBizBanner,
  showAuthModal
}

export default connect(mapStateToProps, mapDispatchToProps)(NanoWebsiteState)
