import React, { useRef } from 'react'
import { connect } from 'react-redux'
import Offcanvas from 'react-bootstrap/Offcanvas'
import Messages from '../../Pages/Messages'
import { hideChatViewOffcanvas } from '../../../features/offcanvas/ChatViewSlice'
import { useNavigate } from 'react-router-dom'

const ChatViewOffcanvas = ({
  chatViewOffcanvasVisible,
  hideChatViewOffcanvas,
  chatThreadViewOffcanvasVisible
}) => {
  let navigate = useNavigate()
  const handleCloseChatViewOffcanvas = () => {
    navigate('/')
    hideChatViewOffcanvas()
  }
  const touchStartX = useRef(null)
  const touchEndX = useRef(null)

  const handleTouchStart = e => {
    touchStartX.current = e.touches[0].clientX
  }

  const handleTouchEnd = e => {
    touchEndX.current = e.changedTouches[0].clientX
    handleSwipe()
  }

  const handleSwipe = () => {
    const swipeDistance = touchStartX.current - touchEndX.current
    const swipeThreshold = 50 // Adjust as needed

    if (swipeDistance > swipeThreshold) {
      console.log('Swiped left')
    } else if (swipeDistance < -swipeThreshold) {
      console.log('Swiped right')
      hideChatViewOffcanvas()
    }
  }
  return (
    <div>
      <Offcanvas
        show={chatViewOffcanvasVisible}
        onHide={() => {
          handleCloseChatViewOffcanvas()
        }}
        onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}
        placement="end"
        backdrop={true}
        style={{ transition: '0.2s ease-in-out' }}
        className={'offcanvas-end'}
      >
        <div class="profileBox-end bg-primary">
            <div class="chatHeader bg-primary" style={{height: '50px'}}>
                <div className="in">
                    <div>
                        Chats
                    </div>
                </div>
            </div>
        </div>
        <div style={{height: '100vh'}}>
          <Messages></Messages>
        </div>
      </Offcanvas>
    </div>
  )
}

const mapStateToProps = ({ chatView }) => {
  const { chatViewOffcanvasVisible, chatThreadViewOffcanvasVisible } = chatView
  return {
    chatViewOffcanvasVisible,
    chatThreadViewOffcanvasVisible
  }
}

const mapDispatchToProps = {
  hideChatViewOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatViewOffcanvas)
