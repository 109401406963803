import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    profileModalVisible: false
}

export const profileModalSlice = createSlice({
  name: 'profileModal',
  initialState,
  reducers: {
    showProfileModal: (state, action) => {
      console.log(state, action)
      const {
        profileModalVisible
      } = action.payload
      state.profileModalVisible = profileModalVisible
    },
    hideProfileModal: (state, action) => {
      state.profileModalVisible = false
    }
  }
})

export const { showProfileModal, hideProfileModal } = profileModalSlice.actions;


export default profileModalSlice.reducer
