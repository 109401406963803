import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  QrOffCanvas: false,
  customerName: '',
  UpiUrl: '',
  UpiID: ''
}

export const QrSlice = createSlice({
  name: 'QrOffCanvas',
  initialState,
  reducers: {
    setQrOffCanvas: (state, action) => {
      state.QrOffCanvas = action.payload
    },
    setCustomerName: (state, action) => {
      state.customerName = action.payload
    },
    setUpiUrl: (state, action) => {
      state.UpiUrl = action.payload
    },
    setUpiID: (state, action) => {
      state.UpiID = action.payload
    }
  }
})

export const { setQrOffCanvas, setCustomerName, setUpiUrl, setUpiID } =
  QrSlice.actions

export default QrSlice.reducer
