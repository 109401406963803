import React from 'react'

function CityChips({ children }) {
    const colors = [
        "#faedcb", "#c9e4de", "#c6def1", "#dbcdf0", "#f2c6de", "#f7d9c4",
      ];
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
  return (
    <div style={{ backgroundColor: randomColor }} className="chip mb-1 me-1 p-2">
        {children}
    </div>
  )
}

export default CityChips