import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import { backToLogin } from '../../features/auth/authSlice'
import { connect } from "react-redux"
import axios from '../../AxiosConfig'

const Register = ({
  backToLogin
}) => {
  const [registerButtonDisabled, setRegisterButtonDisabled] = useState(true)
  const [fullName, setFullName] = useState('')
  const [mobileNumber, setMobileNumber] = useState('')
  const [termsChecked, setTermsChecked] = useState(false)
  const [selectedProfile, setSelectedProfile] = useState('Businessman');

  const handleSelectProfile = (event) => {
    console.log(event.target.value)
    setSelectedProfile(event.target.value);
  };

  let navigate = useNavigate()
  const search = useLocation().search
  const mobileParams = new URLSearchParams(search).get('mobile')

  useEffect(() => {
    if (mobileParams) {
      const mobile = mobileParams.slice(3)
      setMobileNumber(mobile)
    }
  }, [mobileParams])

  useEffect(() => {
    if ((fullName.length > 0) && (mobileNumber.length > 0) && (termsChecked == true)) {
      setRegisterButtonDisabled(false)
    } else {
      setRegisterButtonDisabled(true)
    }
  }, [fullName, mobileNumber, termsChecked])

  const handleBack = () => {
    backToLogin()
    navigate('/')
  }

  const handleRegitserUser = (e) => {
    e.preventDefault()
    let newUserData = {
      'username':fullName,
      'mobileNumber':mobileNumber,
      'profile': selectedProfile
    }
    axios.post('/users/register', newUserData).then(res => {
      if (res.data.message === 'Success') {
        console.log(res.data)
        navigate('/otp?mobile=' + mobileNumber)
      }
    })
  }
  return (
    <>
      <div class="appHeader no-border transparent position-absolute">
        <div class="left">
          <a href="javaScript:void(0)" class="headerButton goBack" onClick={handleBack}>
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div class="pageTitle"></div>
        <div class="right">
          <a href="javaScript:void(0)" class="headerButton"  onClick={handleBack}>
            Login
          </a>
        </div>
      </div>
      <div id="appCapsule">
        <div class="login-form">
          <div class="section">
            <h1>Register</h1>
            <h4>Fill the form to join us</h4>
          </div>
          <div class="section mt-2 mb-5">
            <form onSubmit={(e) => {handleRegitserUser(e)}}>
              <div class="form-group boxed">
                <div class="input-wrapper">
                  <input
                    type="text"
                    class="form-control"
                    id="name1"
                    placeholder="Full name"
                    value={fullName}
                    onChange={(e) => {setFullName(e.target.value)}}
                    required
                    autoFocus
                  />
                  <i class="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>

              <div class="form-group boxed" hidden>
                <div class="input-wrapper">
                  <input
                    type="number"
                    min="0"
                    class="form-control"
                    id="mobile1"
                    placeholder="Mobile number"
                    maxlength={10}
                    minLength={10}
                    value={mobileNumber}
                    onChange={(e) => {setMobileNumber(e.target.value)}}
                    required
                  />
                  <i class="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>

              <div class="section full d-flex justify-content-center flex-column align-items-center">
                <h4 class="mt-1">Choose your profile</h4>
                <div
                  class=""
                  role="group"
                  aria-label="Basic radio toggle button group"
                >
                  <div class="row">
                    <div class="col-6 col-md-6">
                      <input
                        type="radio"
                        class="btn-check"
                        name="btnradio"
                        id="btnradio1"
                        value="Businessman"
                        checked={selectedProfile === 'Businessman'}
                        onClick={handleSelectProfile}
                        required
                      />
                      <label
                        class="btn btn-outline-primary text-nowrap h-auto w-100 mb-2"
                        for="btnradio1"
                      >
                        <div class="card shadow-none bg-transparent w-100">
                          <div class="card-body fs-6">Businessman</div>
                        </div>
                      </label>
                    </div>
                    <div class="col-6 col-md-6">
                      <input
                        type="radio"
                        class="btn-check"
                        name="btnradio"
                        id="btnradio2"
                        value="Professional"
                        checked={selectedProfile === 'Professional'}
                        onClick={handleSelectProfile}
                        required
                      />
                      <label
                        class="btn btn-outline-primary text-nowrap h-auto w-100 mb-2"
                        for="btnradio2"
                      >
                        <div class="card shadow-none bg-transparent w-100">
                          <div class="card-body fs-6">Professional</div>
                        </div>
                      </label>
                    </div>
                    <div class="col-6 col-md-6">
                      <input
                        type="radio"
                        class="btn-check"
                        name="btnradio"
                        id="btnradio3"
                        value="JobSeeker"
                        checked={selectedProfile === 'JobSeeker'}
                        onClick={handleSelectProfile}
                        required
                      />
                      <label
                        class="btn btn-outline-primary text-nowrap h-auto w-100 mb-2"
                        for="btnradio3"
                      >
                        <div class="card shadow-none bg-transparent w-100">
                          <div class="card-body fs-6">Job Seeker</div>
                        </div>
                      </label>
                    </div>
                    <div class="col-6 col-md-6">
                      <input
                        type="radio"
                        class="btn-check"
                        name="btnradio"
                        id="btnradio4"
                        value="Consumer"
                        checked={selectedProfile === 'Consumer'}
                        onClick={handleSelectProfile}
                        required
                      />
                      <label
                        class="btn btn-outline-primary text-nowrap h-auto w-100 mb-2"
                        for="btnradio4"
                      >
                        <div class="card shadow-none bg-transparent w-100">
                          <div class="card-body fs-6">Consumer</div>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div></div>

              <div class=" mt-1 text-start">
                <div class="form-check">
                  <input
                    type="checkbox"
                    class="form-check-input"
                    id="customCheckb1"
                    checked={termsChecked}
                    onChange={(e) => setTermsChecked(e.target.checked)}
                    required
                  />
                  <label class="form-check-label" for="customCheckb1">
                    I Agree <a href="#">Terms & Conditions</a>
                  </label>
                </div>
              </div>

              <div
                class="position-fixed d-flex align-items-center justify-content-center"
                style={{
                  bottom: 0,
                  left: 0,
                  right: 0,
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  minHeight: '84px',
                  width: '100%'
                }}
              >
                <div class="row w-100 d-flex justify-content-center">
                    <div class="col-12 col-md-6 p-0">
                    <button
                        class="btn btn-primary btn-lg btn-block"
                        disabled={registerButtonDisabled}
                        type="submit"
                    >
                        Register {registerButtonDisabled}
                    </button>
                    </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = () => {
  return {
  }
}

const mapDispatchToProps = {
  backToLogin
}

export default connect(mapStateToProps, mapDispatchToProps)(Register)
