import { createSlice } from '@reduxjs/toolkit'

export const imageUploadSlice = createSlice({
  name: 'ImageUpload',
  initialState: {
    ImageUploadOffCanvas: false
  },
  reducers: {
    setImageUploadOffCanvas: (state, action) => {
      state.ImageUploadOffCanvas = action.payload
    }
  }
})

export const { setImageUploadOffCanvas } = imageUploadSlice.actions

export default imageUploadSlice.reducer
