import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'

const ImageUploadModal = () => {
    return (
        <div>
            <Modal show={false} style={{transition: '0.2s ease-in-out'}} centered>
                <Modal.Body>
                    Image Upload Modal
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = () => {
    return {
    }
  }
  
  const mapDispatchToProps = {
  }

export default connect(mapStateToProps, mapDispatchToProps)(ImageUploadModal)