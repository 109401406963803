import { useEffect, useRef, useState } from 'react'

export function useHandleScrollEnd({ 
    onScrollToEnd = () => { console.log('end') }, 
    waitForNextScroll = 5000
}) {
    const ElementRef = useRef(null);
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);

    const [waiting, setWaiting] = useState(true);

    const handleScroll = () => {
        const div = ElementRef.current;

        if (div) {
            const { scrollHeight, scrollTop, clientHeight } = div;
            const isNearBottom = scrollHeight - scrollTop <= clientHeight + 10; // Add buffer of 10px

            setIsScrolledToEnd(isNearBottom);
        }
    };

    function scrollToBottom() {
        if(waiting) {
            setWaiting(false);
            onScrollToEnd();
            setTimeout(() => {
                setWaiting(true);
            }, waitForNextScroll);
        }
    }

    useEffect(() => {
        if(isScrolledToEnd) {
            scrollToBottom();
        }
    }, [isScrolledToEnd]);

    useEffect(() => {
        const div = ElementRef.current;
        if(div) div.addEventListener('scroll', handleScroll);
        return () => {
            if(div) div.removeEventListener('scroll', handleScroll)
        };
    }, [])
    return { ElementRef }
}