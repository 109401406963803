import React, { useState } from 'react'
import { connect } from "react-redux"
import { useLocation, useNavigate } from 'react-router-dom'
import axios from '../../AxiosConfig'
import config from '../../config'
import { setToken, backToLogin } from '../../features/auth/authSlice'

const OTP = ({
  setToken,
  backToLogin
}) => {
  const [otp, setOtp] = useState('')
  const search = useLocation().search
  const mobileNumber = new URLSearchParams(search).get('mobile')

  let navigate = useNavigate()
  
  const submitOtp = (e) => {
    e.preventDefault()
    axios.get(config.apiUrl + '/users/login?mobile='+ mobileNumber +'&otp=' + otp).then(res => {
      if (res.data.message === 'Success') {
        console.log(res.data)
        localStorage.setItem('token', res.data.data)
        setToken({authToken: res.data.data})
        navigate('/')
      }
    })
  }
  const backToAuth = () => {
    backToLogin()
    navigate('/')
  }
  return (
    <>
      <div class="appHeader no-border transparent position-absolute">
        <div class="left">
          <a href="javascript:void(0)" class="headerButton goBack" onClick={() => {backToAuth()}}>
            <ion-icon name="chevron-back-outline"></ion-icon>
          </a>
        </div>
        <div class="pageTitle"></div>
        <div class="right"></div>
      </div>
      <div id="appCapsule">
        <div class="login-form">
          <div class="section">
            <h1>Verify Now</h1>
            <h4>We sent a verification code on your phone</h4>
          </div>
          <div class="section mt-2 mb-5">
            <form onSubmit={(e) => {submitOtp(e)}}>
              <div class="form-group boxed">
                <div class="input-wrapper">
                  <input
                    type="text"
                    class="form-control verify-input"
                    id="smscode"
                    placeholder="••••••"
                    maxlength="6"
                    value={otp}
                    onChange={(e) => {setOtp(e.target.value)}}
                  />
                </div>
              </div>

              <div
                class="position-fixed d-flex align-items-center justify-content-center"
                style={{
                  bottom: 0,
                  left: 0,
                  right: 0,
                  paddingLeft: '16px',
                  paddingRight: '16px',
                  minHeight: '84px',
                  width: '100%'
                }}
              >
                <div class="row w-100 d-flex justify-content-center">
                    <div class="col-12 col-md-6 p-0">
                      <button
                          type="submit"
                          class="btn btn-primary btn-lg btn-block"
                      >
                          Verify
                      </button>
                    </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = () => {
  return {
  }
}

const mapDispatchToProps = {
  backToLogin,
  setToken
}

export default connect(mapStateToProps, mapDispatchToProps)(OTP)
