import axios from 'axios'
import React, { useState } from 'react'
import config from '../config'
import { useSelector } from 'react-redux'

export function useAxios() {
  const [Loading, setLoading] = useState(null)
  const { authToken } = useSelector(state => state.auth)

  async function AxiosCall({
    method = 'get',
    url,
    sendData,
    Authorization = true
  }) {
    let HEADER = { headers: { Authorization: authToken } }
    // console.log(HEADER);
    let APIURL = `${config.apiUrl}/${url}`
    let RESPONSE
    setLoading(true)
    if (method === 'get') {
      if (Authorization) RESPONSE = await axios.get(APIURL, { ...HEADER })
      else RESPONSE = await axios.get(APIURL)
      console.log(RESPONSE)
    } else if (method === 'post') {
      if (Authorization)
        RESPONSE = await axios.post(APIURL, sendData, { ...HEADER })
      else RESPONSE = await axios.post(APIURL, sendData)
    } else if (method === 'put') {
      if (Authorization)
        RESPONSE = await axios.put(APIURL, sendData, { ...HEADER })
      else RESPONSE = await axios.put(APIURL, sendData)
    }
    // console.log(RESPONSE);
    if (
      RESPONSE.data.message === 'Success' &&
      (RESPONSE.data.status === 200 || RESPONSE.data.status === '200')
    ) {
      RESPONSE = RESPONSE.data.data
    } else {
      RESPONSE = false
    }
    setLoading(false)

    return RESPONSE
  }

  return { AxiosCall, Loading }
}
