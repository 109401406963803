import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: 0,
  isVisible: false,
  background: '#000000',
  timeout: 1000,
  icon:'close-outline',
  position: 'toast-center',
  toastMessage: ''
}

export const appToastSlice = createSlice({
  name: 'appToast',
  initialState,
  reducers: {
    showAppToast: (state, action) => {
      console.log(state, action)
      const {
        toastMessage,
        background,
        icon,
        position,
        timeout
      } = action.payload
      state.isVisible = true
      state.toastMessage = toastMessage
      state.background = background
      state.icon = icon
      state.position = position
      state.timeout = timeout
    },
    hideAppToast: (state, action) => {
      state.isVisible = false
      state.toastMessage = ''
    }
  }
})

export const { showAppToast, hideAppToast } = appToastSlice.actions;


export default appToastSlice.reducer
