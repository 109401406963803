import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import './Comps/UsersChatList.css';
import axios from '../../AxiosConfig'
import jwt_decode from 'jwt-decode'
import UserChatView from './Comps/UserChatView';
import UsersChatsList from './Comps/UsersChatsList';
import { useSearchParams } from "react-router-dom";
import { hideChatViewOffcanvas, showChatThreadViewOffcanvas, setChatViewId, chatConversation } from '../../features/offcanvas/ChatViewSlice';

const Messages = ({
    hideChatViewOffcanvas,
    authToken,
    showChatThreadViewOffcanvas,
    setChatViewId,
    chatConversation,
    chatViewId
}) => {
    // const [searchParams, setSearchParams] = useSearchParams({'chatId': ''})

    // const chatId = searchParams.get('chatId')

    // console.log(chatId)

    const [currentChats, setCurrentChats] = useState([]);

    const [searchBox, setSearchBox] = useState('');

    const token = jwt_decode(authToken)

    const [screenWidth, setScreenWidth] = useState(null)
    useEffect(() => {
        setScreenWidth(window.screen.width)

        axios.get(`/chats/usersAllChats/${token.userInfo.ID}`).then(res => {
            if (res.data.message === 'Success') {
                setCurrentChats(res.data.data);
              // setShareLoader(false)
            }
          })
     
    }, [])

    const chatButtonClick = async (e,data) => {
        e.stopPropagation()
        // setChatLoader(true)
        const chatData = {
          "UserID": token.userInfo.ID,
            "BusinessID": data?.To_BusinessID
        }
        showChatThreadViewOffcanvas({chatThreadViewOffcanvasVisible: true})
        axios.post('/action/chat', chatData).then(res => {
          console.log(res)
        })
        axios.get(`/chats/usersAllChats/${data.To_BusinessID}`).then(res => {
          console.log("res")
        })
     
        if(data.ID){
          setChatViewId(data.ID);
          chatConversation({ID: data.ID});
        }
      }

      
    return (
        <div class={(screenWidth < 700) ? '' : 'p-0'}>
                <div className="section ps-1 pe-1">
                    <div className='UsersChatsListView'>
                    <div class="wide-block p-1">
                        <form class="search-form">
                            <div class="form-group searchbox">
                                <input type="text" class="form-control" placeholder='Search chat' onChange={(e) => setSearchBox(e.target.value)} value={searchBox} />
                                <i class="input-icon">
                                    <ion-icon name="search-outline"></ion-icon>
                                </i>
                            </div>
                        </form>
                    </div>
                        <ul className="listview image-listview">
                        {
                            currentChats.map((item, i) => {
                                return <li
                                        key={i}
                                        className={
                                            (
                                                searchBox.trim().length === 0 || 
                                                item.BusinessData.BusinessName.includes(searchBox.trim()) || 
                                                item?.UserData?.FullName?.includes(searchBox.trim()) || 
                                                item.BusinessData.BusinessCategory.includes(searchBox.trim()))
                                             ? '' : 'd-none'}>
                                        <a href="javascript:void(0)" className="item" onClick={(e) => chatButtonClick(e, item)}>
                                            <img src={item.BusinessData.BusinessLogo} onError={(e) => {e.target.src = 'assets/img/webweb.svg'}} alt="image" className="image" />
                                            <div className="in">
                                                <div>
                                                   {item?.UserData?.FullName ? item?.UserData?.FullName : item.BusinessData.BusinessName}
                                                    <header>
                                                        {item?.UserData?.FullName ? item?.UserData?.Profile : item.BusinessData.BusinessCategory} 
                                                        {/* <span className='timeAgo'>
                                                            <ion-icon name="location-outline"></ion-icon>
                                                            Location
                                                        </span> */}
                                                    </header>
                                                </div>
                                            </div>
                                        </a>
                                </li>
                            })
                        }
                        </ul>

                        {
                            currentChats.length === 0 &&
                            <div className='h-100 w-00 d-flex justify-content-center align-items-center'>
                                <span className='text-muted'>Chat Not Started!</span>
                            </div>
                        }

                        {/* <div className={`col-12 d-sm-block col-sm-5 bg-light px-0`}>
                            <ul class="nav nav-tabs lined" role="tablist">
                                <li class="nav-item">
                                    <a class="nav-link active" data-bs-toggle="tab" href="#chats" role="tab" style={{height: '40px', fontSize: '14px'}}>
                                        Chats
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" data-bs-toggle="tab" href="#prospects" role="tab" style={{height: '40px', fontSize: '14px'}}>
                                        Groups
                                    </a>
                                </li> */}
                                {/* <li class="nav-item">
                                    <a class="nav-link fs-6" data-bs-toggle="tab" href="#other" role="tab" style={{height: '40px'}}>
                                        Other
                                    </a>
                                </li> */}
                            {/* </ul>
                            <div class="tab-content">
                                <div class="tab-pane fade show active" id="chats" role="tabpanel">
                                    <UsersChatsList />
                                </div>
                                <div class="tab-pane fade" id="prospects" role="tabpanel">
                                    <UsersChatsList />
                                </div>
                                <div class="tab-pane fade" id="other" role="tabpanel">
                                    <UsersChatsList />
                                </div>
                            </div>
                        </div> */}
                        {/* <div className={`col-12 ${chatId === '' ? 'd-none' : ''} col-sm-7 px-0`}>
                            <UserChatView />
                        </div> */}
                    </div>
                </div>
            {/* </div> */}
            {/* <div id="appCapsule">
                <div className="section full">
                </div>
            </div> */}
            {/* <div class="offcanvas offcanvas-bottom action-sheet inset" tabindex="-1" id="actionSheetAdd">
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title">Share</h5>
                </div>
                <div class="offcanvas-body">
                    <ul class="action-button-list">
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                                <span>
                                    <ion-icon name="camera-outline"></ion-icon>
                                    Take a photo
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                                <span>
                                    <ion-icon name="videocam-outline"></ion-icon>
                                    Video
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                                <span>
                                    <ion-icon name="image-outline"></ion-icon>
                                    Upload from Gallery
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                                <span>
                                    <ion-icon name="document-outline"></ion-icon>
                                    Documents
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                                <span>
                                    <ion-icon name="musical-notes-outline"></ion-icon>
                                    Sound file
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div> */}
        </div>
    );
}

const mapStateToProps = ({ auth, chatView }) => {
    const { chatViewId } = chatView
    const { authToken } = auth;
    return {
        authToken,
        chatViewId
    }
  }
  
  const mapDispatchToProps = {
    hideChatViewOffcanvas,
    setChatViewId,
    showChatThreadViewOffcanvas,
    chatConversation
  }

export default connect(mapStateToProps, mapDispatchToProps)(Messages)