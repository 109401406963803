import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    nanoWebsiteData: [],
    bufferData: [],
    cardLogs: []
}

export const nanoWebsiteSlice = createSlice({
  name: 'nanoWebsite',
  initialState,
  reducers: {
    setNanoWebsiteData: (state, action) => {
      const {
        nanoWebsiteData,
        bufferData
      } = action.payload
      state.nanoWebsiteData = nanoWebsiteData
      state.bufferData = bufferData
    },
    setCardLogs: (state, action) => {
      const {
        cardLogs
      } = action.payload
      state.cardLogs = cardLogs
    }
  }
})

export const { setNanoWebsiteData, setCardLogs } = nanoWebsiteSlice.actions;


export default nanoWebsiteSlice.reducer
