import React from 'react'
import { connect } from 'react-redux'
import Offcanvas from 'react-bootstrap/Offcanvas'

const ShareOffcanvas = () => {
    
    return (
        <div>
            <Offcanvas show={false} placement='bottom' style={{transition: '0.2s ease-in-out' }}>
                <div class="offcanvas-header">
                    <h5 class="offcanvas-title">Share</h5>
                </div>
                <div class="offcanvas-body">
                    <ul class="action-button-list">
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                                <span>
                                    <ion-icon name="camera-outline"></ion-icon>
                                    Take a photo
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                                <span>
                                    <ion-icon name="videocam-outline"></ion-icon>
                                    Video
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                                <span>
                                    <ion-icon name="image-outline"></ion-icon>
                                    Upload from Gallery
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                                <span>
                                    <ion-icon name="document-outline"></ion-icon>
                                    Documents
                                </span>
                            </a>
                        </li>
                        <li>
                            <a href="#" class="btn btn-list" data-bs-dismiss="offcanvas">
                                <span>
                                    <ion-icon name="musical-notes-outline"></ion-icon>
                                    Sound file
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </Offcanvas>
        </div>
    );
};

const mapStateToProps = () => {
    return {
    }
  }
  
  const mapDispatchToProps = {
  }

export default connect(mapStateToProps, mapDispatchToProps)(ShareOffcanvas);