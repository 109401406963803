import React, { useState } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-bootstrap/Modal'
import axios from '../../../AxiosConfig'
import { hideConfirmationViewModal } from '../../../features/offcanvas/ConfirmationSlice'
import { setCreateBusinessData } from '../../../features/offcanvas/MyBusinessSlice'

const ConfirmationModal = ({
    confirmationModalVisible,
    deleteItemID,
    hideConfirmationViewModal,
    businessData,
    setCreateBusinessData
}) => {
    const [buttonDisable, setButtonDisable] = useState(false)
    const handleDelete = () => {
        setButtonDisable(true)
        const tempBusinessData = JSON.parse(JSON.stringify(businessData))
        const imageArr = tempBusinessData.Content
        const newArray = [...imageArr.slice(0, deleteItemID), ...imageArr.slice(deleteItemID + 1)];
        // const newArray = imageArr.filter((item, currentIndex) => currentIndex !== 1);
        tempBusinessData.Content = newArray
        setCreateBusinessData({ businessData: tempBusinessData })
        axios.put('/businesses/' + tempBusinessData.ID, tempBusinessData).then(res => {
            if (res.data.message === 'Success') {
                setButtonDisable(false)
                hideConfirmationViewModal()
            }
        })
    }
    return (
        <div>
            <Modal show={confirmationModalVisible} centered style={{transition: '0.2s ease-in-out', zIndex: 9999}} onHide={() => {hideConfirmationViewModal()}}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h3 class="fw-bold mb-0">Delete</h3>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body class="modal-body">
                    <div class="section full">
                        <h3 class="mb-2">Are you sure ?</h3>
                        <div class="d-flex justify-content-end">
                            <button class="btn btn-secondary me-1" disabled={buttonDisable} onClick={() => {hideConfirmationViewModal()}}><ion-icon name="close-outline" class="me-0"></ion-icon> Cancel</button>
                            <button class="btn btn-danger" disabled={buttonDisable} onClick={() => {handleDelete()}}><ion-icon name="trash-outline" class="me-0"></ion-icon> Delete</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

const mapStateToProps = ({ confirmation, myBusiness }) => {
    const {
        confirmationModalVisible,
        deleteItemID
    } = confirmation
    const {
        businessData
    } = myBusiness
    return {
        confirmationModalVisible,
        deleteItemID,
        businessData
    }
  }
  
  const mapDispatchToProps = {
    hideConfirmationViewModal,
    setCreateBusinessData
  }

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal)