import React from 'react'
import './FirstCard.css'
import { connect } from 'react-redux'

import { Splide, SplideSlide } from '@splidejs/react-splide'
import { useSwipeable } from 'react-swipeable'
import Hospital from '../images/icon/hospital.png'
import Education from '../images/icon/educational.png'
import Industry from '../images/icon/industry.png'
import Professional from '../images/icon/profesional.png'
import ProductShowcase from '../images/icon/productShowcase.png'
import LocationRadius from '../images/icon/within5km.png'
import LocationCity from '../images/icon/city.png'
import LocationNearby from '../images/icon/nearby.png'
import ListCities from '../images/icon/listofcity.png'
import Bookmarked from '../images/icon/bookmark.png'
import NewlyAdded from '../images/icon/newlyAdded.png'
import Suggestions from '../images/icon/suggestion.png'
import Featured from '../images/icon/featured.png'
import {
  setSelectedFilter,
  showFindViewOffcanvas
} from '../../features/offcanvas/FindViewSlice'
import { FILTERLIST } from '../../config'

export const FirstCard = ({
  openSearchInput,
  setSelectedFilter,
  showFindViewOffcanvas
}) => {
  const listMyBusiness = [
    {
      BusinessLogo:
        'https://webweb.ams3.cdn.digitaloceanspaces.com/data/skyqtech.webweb.ai.in/SkyQ-Tech-logo-Horizontal.svg',
      BusinessName: 'SkyQ Tech'
    },
    {
      BusinessLogo:
        'https://webweb.ams3.cdn.digitaloceanspaces.com/data/skyqtech.webweb.ai.in/SkyQ-Tech-logo-Horizontal.svg',
      BusinessName: 'SkyQ Tech'
    },
    {
      BusinessLogo:
        'https://webweb.ams3.cdn.digitaloceanspaces.com/data/skyqtech.webweb.ai.in/SkyQ-Tech-logo-Horizontal.svg',
      BusinessName: 'SkyQ Tech'
    },
    {
      BusinessLogo:
        'https://webweb.ams3.cdn.digitaloceanspaces.com/data/skyqtech.webweb.ai.in/SkyQ-Tech-logo-Horizontal.svg',
      BusinessName: 'SkyQ Tech'
    },
    {
      BusinessLogo:
        'https://webweb.ams3.cdn.digitaloceanspaces.com/data/skyqtech.webweb.ai.in/SkyQ-Tech-logo-Horizontal.svg',
      BusinessName: 'SkyQ Tech'
    },
    {
      BusinessLogo:
        'https://webweb.ams3.cdn.digitaloceanspaces.com/data/skyqtech.webweb.ai.in/SkyQ-Tech-logo-Horizontal.svg',
      BusinessName: 'SkyQ Tech'
    }
  ]

  const Array1 = [
    {
      logo: 'https://www.svgrepo.com/show/220086/health-clinic-health-care.svg',
      filter: FILTERLIST.FILTER1.filter,
      text: FILTERLIST.FILTER1.text
    },
    {
      logo: 'https://www.svgrepo.com/show/398447/teacher.svg',
      filter: FILTERLIST.FILTER2.filter,
      text: FILTERLIST.FILTER2.text
    },
    {
      logo: 'https://www.svgrepo.com/show/500084/factory.svg',
      filter: FILTERLIST.FILTER3.filter,
      text: FILTERLIST.FILTER3.text
    },
    {
      logo: 'https://www.svgrepo.com/show/52533/photographer.svg',
      filter: FILTERLIST.FILTER4.filter,
      text: FILTERLIST.FILTER4.text
    },
    {
      logo: 'https://www.svgrepo.com/show/362148/shop.svg',
      filter: FILTERLIST.FILTER5.filter,
      text: FILTERLIST.FILTER5.text
    },
    {
      logo: 'https://www.svgrepo.com/show/362124/map-map-marker.svg',
      filter: FILTERLIST.FILTER6.filter,
      text: FILTERLIST.FILTER6.text
    },
    {
      logo: 'https://www.svgrepo.com/show/476994/city.svg',
      filter: FILTERLIST.FILTER7.filter,
      text: FILTERLIST.FILTER7.text
    }
  ]

  const Array2 = [
    {
      logo: 'https://www.svgrepo.com/show/405798/heart-on-fire.svg',
      filter: FILTERLIST.FILTER8.filter,
      text: FILTERLIST.FILTER8.text
    },
    {
      logo: 'https://www.svgrepo.com/show/176730/city-nature.svg',
      filter: FILTERLIST.FILTER9.filter,
      text: FILTERLIST.FILTER9.text
    },
    {
      logo: 'https://www.svgrepo.com/show/87245/bookmark.svg',
      filter: FILTERLIST.FILTER10.filter,
      text: FILTERLIST.FILTER10.text
    },
    {
      logo: 'https://www.svgrepo.com/show/382495/add-data-file-page-paper.svg',
      filter: FILTERLIST.FILTER11.filter,
      text: FILTERLIST.FILTER11.text
    },
    {
      logo: 'https://www.svgrepo.com/show/231563/light-bulb-idea.svg',
      filter: FILTERLIST.FILTER12.filter,
      text: FILTERLIST.FILTER12.text
    },
    {
      logo: 'https://www.svgrepo.com/show/234871/browser-star.svg',
      filter: FILTERLIST.FILTER13.filter,
      text: FILTERLIST.FILTER13.text
    },
    {
      logo: 'https://www.svgrepo.com/show/234871/browser-star.svg',
      filter: FILTERLIST.FILTER13.filter,
      text: FILTERLIST.FILTER13.text
    }
  ]
  return (
    <div className="FirstCard" id="FirstCard">
      <div className="FistCardWrapper">
        <div className="SearchSection">
          <form className="search-form">
            <div
              className="form-group searchbox"
              style={{ marginTop: '-44px' }}
            >
              <i className="text-icon">
                <ion-icon name="search-outline"></ion-icon>
              </i>
              <input
                type="filter"
                className="form-control"
                onFocus={openSearchInput}
                placeholder="Search..."
              />
            </div>
          </form>
        </div>
        <div className="StoriesSection">
          <Splide
            options={{
              type: 'slide',
              rewind: false,
              gap: 0,
              padding: { left: 3 },
              autoplay: false,
              interval: 3000,
              pagination: false,
              arrows: false,
              breakpoints: {
                700: {
                  perPage: 4,
                  perMove: 1
                },
                1200: {
                  perPage: 3,
                  perMove: 1
                },
                1600: {
                  perPage: 3,
                  perMove: 1
                },
                2000: {
                  perPage: 3,
                  perMove: 1
                }
              }
            }}
          >
            {listMyBusiness && (
              <>
                {listMyBusiness.map((v, k) => {
                  return (
                    <SplideSlide
                      key={k}
                      style={{ width: '70px', height: '70px' }}
                    >
                      <a
                        href="javascript:void(0)"
                        // onClick={() => {
                        //     handleMyBusiness('update', v)
                        // }}
                      >
                        <img
                          src={
                            v.BusinessLogo
                              ? v.BusinessLogo
                              : 'assets/img/sample/avatar/avatar1.jpg'
                          }
                          alt="alt"
                          className="imaged rounded"
                          style={{
                            border: 'solid 3px',
                            borderSpacing: '5px ',
                            width: '75px',
                            height: '75px',
                            objectFit: 'contain'
                          }}
                        />
                        <span
                          className="two-line-limit fw-bold text-nowrap d-flex justify-content-center"
                          style={{
                            marginRight: '15px',
                            fontSize: '10px',
                            marginTop: '2px',
                            lineHeight: '15px',
                            color: '#707070'
                          }}
                        >
                          {v.BusinessName}
                        </span>
                      </a>
                    </SplideSlide>
                  )
                })}
              </>
            )}
          </Splide>
        </div>
        <div className="CategoriesSection">
          <div className="mb-5 BigImages">
            <Splide
              options={{
                type: 'loop',
                rewind: false,
                gap: 5,
                perMove: 1,
                padding: { left: '10%', right: '10%' },
                // padding: { left: '0.5rem', right: '2.5rem' },
                autoplay: false,
                easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                pagination: false,
                arrows: false,
                // direction: 'rtl',
                breakpoints: {
                  700: {
                    perPage: 1
                  },
                  1200: {
                    perPage: 2
                  },
                  1600: {
                    perPage: 3
                  },
                  2000: {
                    perPage: 3
                  }
                }
              }}
            >
              <SplideSlide>
                <div className="card">
                  <img
                    src="assets/img/sample/avatar/Optimize your brand.png"
                    className="imaged w-100 square"
                    alt="image"
                  />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="card">
                  <img
                    src="assets/img/sample/avatar/8.png"
                    className="imaged w-100 square"
                    alt="image"
                  />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="card">
                  <img
                    src="assets/img/sample/avatar/Growth Opportunities.png"
                    className="imaged w-100 square"
                    alt="image"
                  />
                </div>
              </SplideSlide>
              <SplideSlide>
                <div className="card">
                  <img
                    src="assets/img/sample/avatar/4.png"
                    className="imaged w-100 square"
                    alt="image"
                  />
                </div>
              </SplideSlide>
            </Splide>
          </div>
          <div className="mb-5">
            <Splide
              options={{
                type: 'loop',
                rewind: false,
                gap: 5,
                perMove: 1,
                padding: { left: '10%', right: '10%' },
                // padding: { left: '0.5rem', right: '2.5rem' },
                autoplay: false,
                easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                pagination: false,
                arrows: false,
                // direction: 'rtl',
                breakpoints: {
                  700: {
                    perPage: 4
                  },
                  1200: {
                    perPage: 2
                  },
                  1600: {
                    perPage: 3
                  },
                  2000: {
                    perPage: 3
                  }
                }
              }}
            >
              {Array1.map((itm, i) => {
                return (
                  <SplideSlide key={i}>
                    <div
                      className="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                      style={{
                        height: '67px',
                        width: 'auto',
                        backgroundImage: `url(${itm.logo})`,
                        backgroundSize: 'cover', // Ensure the image covers the entire div
                        backgroundPosition: 'center',
                        borderRadius: '25px'
                      }}
                      onClick={() => {
                        showFindViewOffcanvas({
                          findViewOffcanvasVisible: true
                        })
                        setSelectedFilter(itm)
                      }}
                    >
                      {/* <img
                        src={itm.logo}
                        alt="avatar"
                        className="m-auto"
                        width="50px"
                      /> */}
                    </div>
                    <span
                      className="two-line-limit fw-bold text-nowrap"
                      style={{
                        textAlign: 'center',
                        fontSize: '10px',
                        marginTop: '2px',
                        lineHeight: '15px',
                        color: '#707070'
                      }}
                    >
                      {itm.text}
                    </span>
                  </SplideSlide>
                )
              })}
            </Splide>
          </div>
          <div className="mb-5">
            <Splide
              options={{
                type: 'slide',
                rewind: true,
                gap: 10,
                perMove: 1,
                padding: { left: 5, right: 20 },
                padding: { left: '0.5rem', right: '2.5rem' },
                autoplay: false,
                interval: 1500,
                speed: 1500, // Adjust the transition speed (in milliseconds)
                easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                pagination: false,
                arrows: false,
                direction: 'ltr',
                breakpoints: {
                  700: {
                    perPage: 4
                  },
                  1200: {
                    perPage: 3
                  },
                  1600: {
                    perPage: 3
                  },
                  2000: {
                    perPage: 3
                  }
                }
              }}
            >
              {Array2.map((itm, i) => {
                return (
                  <SplideSlide key={i}>
                    <div
                      className="me-1 p-1 d-flex justify-content-center flex-column align-items-center text-center"
                      style={{
                        height: '67px',
                        width: 'auto',
                        backgroundImage: `url(${itm.logo})`,
                        backgroundSize: 'cover', // Ensure the image covers the entire div
                        backgroundPosition: 'center',
                        borderRadius: '25px'
                      }}
                      onClick={() => {
                        showFindViewOffcanvas({
                          findViewOffcanvasVisible: true
                        })
                        setSelectedFilter(itm)
                      }}
                    >
                      {/* <img
                        src={itm.logo}
                        alt="avatar"
                        className="m-auto"
                        width="50px"
                      /> */}
                    </div>
                    <span
                      className="two-line-limit fw-bold text-nowrap"
                      style={{
                        textAlign: 'center',
                        fontSize: '10px',
                        marginTop: '2px',
                        lineHeight: '15px',
                        color: '#707070'
                      }}
                    >
                      {itm.text}
                    </span>
                  </SplideSlide>
                )
              })}
            </Splide>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({})

const mapDispatchToProps = {
  setSelectedFilter,
  showFindViewOffcanvas
}

export default connect(mapStateToProps, mapDispatchToProps)(FirstCard)
