import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import axios from '../../../AxiosConfig'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import CityChips from '../../Pages/Comps/CityChips'
import Offcanvas from 'react-bootstrap/Offcanvas'
import {
  hideEditBusinessViewModal,
  showEditBusinessViewModal
} from '../../../features/offcanvas/EditBusinessSlice'
import {
  showMyBusinessModal,
  setCreateBusinessData
} from '../../../features/offcanvas/MyBusinessSlice'
import { showCityListModal } from '../../../features/offcanvas/CityListSlice'
import { showChatThreadViewOffcanvas } from '../../../features/offcanvas/ChatViewSlice'
import { hideMyBusinessModal } from '../../../features/offcanvas/MyBusinessSlice'

// import Kindergarden from '../../images/categories/kindergarden.svg'
// import Training from '../../images/categories/training-center.svg'
// import Placement from '../../images/categories/placement-services-1.svg'
// import Institute from '../../images/categories/institute.svg'
// import Religious from '../../images/categories/religious-place.svg'
// import Blog from '../../images/categories/blog-1.svg'
// import Event from '../../images/categories/event-1.svg'
// import Food from '../../images/categories/food-processing-1.svg'
// import Architech from '../../images/categories/architech-1.svg'
// import Laboratory from '../../images/categories/laboratory-1.svg'
// import Manufacturing from '../../images/categories/manufacturing-1.svg'
import Civil from '../../images/categories/civil-services.svg'
// import Engineering from '../../images/categories/engineering.svg'
// import Estate from '../../images/categories/estate.svg'
// import Professional from '../../images/categories/Professional-1.svg'
// import Studio from '../../images/categories/studio-1.svg'
// import Resort from '../../images/categories/resort.svg'
// import Portfolio from '../../images/categories/portfolio-1.svg'
// import Consultancy from '../../images/categories/consultancy.svg'
// import Law from '../../images/categories/law-firm-1.svg'
// import Artist from '../../images/categories/artist-1.svg'
// import Accounting from '../../images/categories/accounting-1.svg'
// import Food2 from '../../images/categories/food.svg'
// import Indust from '../../images/categories/indust.svg'
// import Plant from '../../images/categories/plant.svg'
// import Books from '../../images/categories/books.svg'
// import Arts from '../../images/categories/arts.svg'
// import Pharmacy from '../../images/categories/pharmacy-medical-1.svg'
// import Gadget from '../../images/categories/gadget-1.svg'
// import Fashion from '../../images/categories/fashion-1.svg'

// import HealthCare from '../../images/categories/Hospital.svg'
// import Ayurvedic from '../../images/categories/ayurvedic-consaltant.svg'
// import MedicalExpert from '../../images/categories/medical-expert-final.svg'
// import Dietitian from '../../images/categories/dietitian.svg'
// import Dentist from '../../images/categories/dentist-1.svg'
// import Beauty from '../../images/categories/beauty.svg'
// import Physiotherapy from '../../images/categories/physiotherapist-1.svg'
// import Fitness from '../../images/categories/fitness-center.svg'
import {
  setCustomerName,
  setUpiUrl,
  setUpiID
} from '../../../features/offcanvas/QrSlice'
import './EditBussinessOffcanvas.css'
import useVerifyMobile from '../../../hooks/useVerifyMobile'
import { setQrOffCanvas } from '../../../features/offcanvas/QrSlice'
// import { get } from 'unsplash-js/dist/methods/users

const EditBusinessOffcanvas = ({
  editBusinessOffcanvasVisible,
  hideEditBusinessViewModal,
  actionType,
  showEditBusinessViewModal,
  showMyBusinessModal,
  showCityListModal,
  setCreateBusinessData,
  businessData,
  userCurrentLocation,
  showChatThreadViewOffcanvas,
  hideMyBusinessModal,
  setCustomerName,
  customerName,
  setQrOffCanvas,
  setUpiUrl,
  UpiUrl,
  setUpiID,
  UpiID,
  userCurrentLat,
  userCurrentLong,
  role
}) => {
  const [disableSubmit, setDisableSubmit] = useState(false)
  const [isDisable, setisDisable] = useState(false)
  const [type, setType] = useState('')
  const [cityInput, enableCityInput] = useState(true)
  const cityInputRef = useRef(null)
  const [categories, setCategories] = useState([])
  const [loading, setLoading] = useState(false)

  const [OwnerName, setOwnerName] = useState(false)
  const [BizMobileOTP, setBizMobileOTP] = useState(null)
  const [ValidateBizOwner, setValidateBizOwner] = useState(false)

  const { sendOTP, verifyOTP, registerUser, Loading, OTPError } =
    useVerifyMobile()

  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (actionType == 'add') {
      setType('add')
    } else if (actionType == 'update') {
      setType('update')
    } else if (actionType == 'edit') {
      setType('edit')
    } else if (actionType == 'verify') {
      setType('verify')
    } else if (
      actionType == 'share' ||
      actionType == 'chat' ||
      actionType == 'bookmark'
    ) {
      setType(actionType)
    }
  }, [actionType])

  const updateBusinessData = (value, type) => {
    const data = JSON.parse(JSON.stringify(businessData))
    if (type == 'businessName') {
      data.BusinessName = value
    } else if (type == 'businessDescription') {
      data.Description = value
    } else if (type == 'buttonTitle') {
      if (data.hasOwnProperty('ActionButton')) {
        if (data.ActionButton == null || data.ActionButton == undefined) {
          data.ActionButton = {}
        }
      } else {
        data.ActionButton = {}
      }
      data.ActionButton.Title = value
    } else if (type == 'buttonURL') {
      if (data.hasOwnProperty('ActionButton')) {
        if (data.ActionButton == null || data.ActionButton == undefined) {
          data.ActionButton = {}
        }
        if (type == 'buttonType') {
          data.ActionButton.URL = 'https://wa.me/' + value
        } else {
          if (value.includes('http')) {
            data.ActionButton.URL = value
          } else {
            data.ActionButton.URL = 'https://' + value
          }
        }
      } else {
        data.ActionButton = {}
        if (type == 'buttonType') {
          data.ActionButton.URL = 'https://wa.me/' + value
        } else {
          data.ActionButton.URL = value
        }
      }
    } else if (type == 'businessCity') {
      data.Location = value
    } else if (type == 'buttonType') {
      if (data.hasOwnProperty('ActionButton')) {
        if (data.ActionButton == null || data.ActionButton == undefined) {
          data.ActionButton = {}
        }
      } else {
        data.ActionButton = {}
      }
      data.ActionButton.Type = value
    } else if (type == 'ownerNumber') {
      data.ownerNumber = value
    } else if (type == 'OwnerName') {
      data.OwnerName = value
    }
    setCreateBusinessData({ businessData: data })
  }

  useEffect(() => {
    if (userCurrentLocation) {
      const data = JSON.parse(JSON.stringify(businessData))
      data.Location = userCurrentLocation
      setCreateBusinessData({ businessData: data })
    }
  }, [userCurrentLocation])

  const WebsiteCreate = () => {
    const data = businessData
    console.log(data)
    showEditBusinessViewModal({
      editBusinessOffcanvasVisible: false
    })
    setQrOffCanvas(true)
    // getCategory()
    // showEditBusinessViewModal({
    //   editBusinessOffcanvasVisible: true,
    //   actionType: 'add'
    // })
  }

  const CreateNanoWebsite = e => {
    e.preventDefault()
    getCategory()
    // WebsiteCreate()
    showEditBusinessViewModal({
      editBusinessOffcanvasVisible: true,
      actionType: 'add'
    })
    // setDisableSubmit(true)
  }

  useEffect(() => {
    let timer
    if (actionType === 'update') {
      console.log(role)

      if (role !== 1) {
        // setLoading(true)
        if (customerName) {
          // timer = setTimeout(() => {
          //   setLoading(false)
          //   WebsiteCreate()
          // }, 5000)
          WebsiteCreate()
          setValidateBizOwner(true)
          setQrOffCanvas(false)
          showEditBusinessViewModal({
            editBusinessOffcanvasVisible: true,
            actionType: 'update'
          })
        }
      } else {
        setValidateBizOwner(true)
        console.log('Hre')
      }
    }
    // Cleanup timer on component unmount
    return () => clearTimeout(timer)
  }, [actionType, customerName, role])

  function createBusiness(data) {
    axios.post('/businesses', data).then(res => {
      if (res.data.message === 'Success') {
        hideEditBusinessViewModal()
        setCreateBusinessData({ businessData: res.data.data })
        showMyBusinessModal({ myBusinessModalVisible: true })
        setDisableSubmit(false)
      }
    })
  }

  const selectCategory = ID => {
    const data = JSON.parse(JSON.stringify(businessData))
    data.BusinessName = customerName
    data.QrBusinessName = customerName
    data.BusinessCategory = ID
    data.Upi_Url = UpiUrl
    data.UPI_ID = UpiID
    // data.Geolocation = `(${userCurrentLat}, ${userCurrentLong})`;
    data.long = userCurrentLong
    data.lat = userCurrentLat
    if (!OwnerName) {
      delete data['OwnerNanme']
    }
    setCreateBusinessData({ businessData: data })
    createBusiness(data)
  }

  const getCategory = () => {
    let urluse = '/categories'
    if (customerName) {
      urluse = `/categories?bizName=${customerName}`
    }
    axios.get(urluse).then(res => {
      if (res) {
        const suggestedIsOne = res.data.data.filter(
          isOne => isOne.ID === 'suggested'
        )
        if (suggestedIsOne && suggestedIsOne[0].Children.length === 1) {
          selectCategory(suggestedIsOne[0].Children[0].ID)
        } else {
          setCategories(res.data.data)
        }
        console.log(res.data.data)
      }
    })
  }

  const EditNanoWebsite = e => {
    e.preventDefault()
    setDisableSubmit(true)
    const data = businessData
    const tempData = JSON.parse(JSON.stringify(businessData))
    tempData.ActionButton = businessData.ActionButton
    console.log(tempData)
    setCreateBusinessData({ businessData: tempData })
    axios.put('/businesses/' + data.ID, data).then(res => {
      if (res.data.message === 'Success') {
        setDisableSubmit(false)
        hideEditBusinessViewModal()
      }
    })
  }

  const updateCity = () => {
    // showCityListModal({cityListModalVisible: true})
    enableCityInput(false)
    setTimeout(() => {
      cityInputRef.current.focus()
    }, 100)
  }

  const handleChatThreadView = () => {
    hideEditBusinessViewModal()
    hideMyBusinessModal()
    showChatThreadViewOffcanvas({ chatThreadViewOffcanvasVisible: true })
  }

  const HandleSendOTP = async () => {
    if (businessData.ownerNumber.toString().trim().length !== 10) {
      return
    }
    const response = await sendOTP(businessData.ownerNumber)
    console.log(response)
    if (response) {
      if (response === 'No User') {
        setOwnerName(true)
      } else {
        setBizMobileOTP('')
      }
    }
  }

  const HandleRegister = async () => {
    const response = await registerUser(
      businessData.OwnerName,
      businessData.ownerNumber
    )
    if (response === 'OTP Sent') {
      setBizMobileOTP('')
      setisDisable(true)
    }
  }

  const VerifyOTP = async () => {
    if (BizMobileOTP.toString().trim().length !== 6) {
      return
    }
    if (OwnerName && businessData.OwnerName.toString().trim().length === 0) {
      return
    }
    console.log('verification of otp function')
    const response = await verifyOTP(businessData.ownerNumber, BizMobileOTP)
    if (response) {
      WebsiteCreate()
    } else {
      console.log('invalid otp')
    }
  }

  useEffect(() => {
    if (!editBusinessOffcanvasVisible) {
      // setCreateBusinessData({ businessData: {} })
      setBizMobileOTP(null)
      setValidateBizOwner(false)
      setLoading(false)
      setDisableSubmit(false)
      const data = JSON.parse(JSON.stringify(businessData))
      data.OwnerName = ''
      data.ownerNumber = ''
      setOwnerName(false)
      setCreateBusinessData({ businessData: data })
      setSearchText('')
    }
  }, [editBusinessOffcanvasVisible])

  return (
    <>
      <Offcanvas
        placement="bottom"
        show={editBusinessOffcanvasVisible}
        style={{
          transition: '0.2s ease-in-out',
          height: 'auto',
          zIndex: 9999,
          borderTopLeftRadius: '25px',
          borderTopRightRadius: '25px',
          maxWidth: '358px',
          margin: '0 auto'
        }}
      >
        <Offcanvas.Body class="offcanvas-body pt-2">
          <div class="row">
            <div class="col-6 col-md-6 mb-0">
              {type == 'add' && <div class="fw-bold">Choose Category</div>}
              {type == 'update' && <div class="fw-bold">Create Business</div>}
              {type == 'verify' && (
                <div class="fw-bold">Verfiy Mobile Number</div>
              )}
              {/* {(type == 'update') && (
                <div class="fw-bold">Add Business</div>
              )} */}
              {type == 'edit' && <div class="fw-bold">Update</div>}
              {type == 'share' && (
                <div class="fw-bold">
                  Share{' '}
                  <span
                    class="badge bg-primary fs-10"
                    style={{ height: '14px' }}
                  >
                    60
                  </span>
                </div>
              )}
              {type == 'chat' && (
                <div class="fw-bold">
                  Chat{' '}
                  <span
                    class="badge bg-primary fs-10"
                    style={{ height: '14px' }}
                  >
                    60
                  </span>
                </div>
              )}
              {type == 'bookmark' && (
                <div class="fw-bold">
                  Bookmark{' '}
                  <span
                    class="badge bg-primary fs-10"
                    style={{ height: '14px' }}
                  >
                    60
                  </span>
                </div>
              )}
            </div>
            <div class="col-6 col-md-6 d-flex justify-content-end mb-0">
              <div
                class="cursor-pointer"
                onClick={() => {
                  setCustomerName('')
                  hideEditBusinessViewModal()
                }}
              >
                <ion-icon name="close-outline" class="fs-1"></ion-icon>
              </div>
            </div>
          </div>

          {/* CATEGORY LIST */}
          {actionType == 'add' && (
            <div
              class="tab-pane fade show active"
              id="businesses"
              role="tabpanel"
            >
              <div className="row categoriesButtonSection mt-0">
                <div class="section">
                  <div class="tab-content mt-0">
                    <div className="search-form">
                      <div className="form-group searchbox">
                        <input
                          type="text"
                          className="form-control"
                          value={searchText}
                          onChange={e => setSearchText(e.target.value)}
                          placeholder="Search..."
                        />
                        <i className="input-icon">
                          <ion-icon name="search-outline"></ion-icon>
                        </i>
                        {searchText.length > 0 && (
                          <span
                            className="ms-1 close toggle-searchbox"
                            style={{ height: '36px' }}
                            onClick={() => setSearchText('')}
                          >
                            <ion-icon name="close-circle"></ion-icon>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="mb-1 categoriesSection">
                      {categories.map((category, index) => (
                        <>
                          {(searchText.length === 0 ||
                            (category?.Children?.length > 0 &&
                              category.Children.filter(itm =>
                                itm.Name.toLowerCase().includes(
                                  searchText.toLowerCase()
                                )
                              ).length > 0)) && (
                            <div class="section-title">{category.Name}</div>
                          )}
                          <div class="row mx-0">
                            {category?.Children?.length > 0 &&
                              category.Children.map((itm, i) => {
                                return (
                                  <div
                                    class={`col-4 col-md-4 mb-1 px-1 ${
                                      searchText.length === 0 ||
                                      itm.Name.toLowerCase().includes(
                                        searchText.toLowerCase()
                                      )
                                        ? 'd-block'
                                        : 'd-none'
                                    }`}
                                    key={i}
                                  >
                                    <div
                                      key={i}
                                      class="card comment-box p-1 categoryName"
                                      style={{
                                        border: '2px solid #807f7d'
                                      }}
                                      onClick={() => {
                                        selectCategory(itm.ID)
                                        console.log('here')
                                      }}
                                    >
                                      {itm.Icons && (
                                        <img src={itm.Icons} alt="" />
                                      )}
                                      <span
                                        class="two-line-limit"
                                        style={{
                                          fontSize: '14px',
                                          marginTop: '2px',
                                          lineHeight: '15px'
                                        }}
                                      >
                                        {itm.Name}
                                      </span>
                                    </div>
                                  </div>
                                )
                              })}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="tab-pane fade" id="cities" role="tabpanel">
                <div className="row categoriesButtonSection">
                  <div class="section full">
                    <div class="listview-title">Maharashtra</div>
                    <div class="mb-2">
                      <Splide
                        options={{
                          type: 'loop',
                          gap: 2,
                          arrows: false,
                          trimSpace: false,
                          autoWidth: true,
                          pagination: false,
                          grid: {
                            rows: 2,
                            cols: 2
                          },
                          rewind: true,
                          perMove: 1,
                          padding: '3.5rem',
                          autoplay: false,
                          interval: 1500,
                          speed: 1500, // Adjust the transition speed (in milliseconds)
                          easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                          breakpoints: {
                            700: {
                              perPage: 3
                            },
                            1200: {
                              perPage: 4
                            },
                            1600: {
                              perPage: 4
                            },
                            2000: {
                              perPage: 4
                            }
                          }
                        }}
                      >
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Mumbai
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Pune
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Nagpur
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Nashik
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Aurangabar
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Thane
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                      </Splide>
                    </div>
                    <div class="listview-title">Uttar Pradesh</div>
                    <div class="mb-2">
                      <Splide
                        options={{
                          type: 'loop',
                          gap: 2,
                          arrows: false,
                          trimSpace: false,
                          autoWidth: true,
                          pagination: false,
                          grid: {
                            rows: 2,
                            cols: 2
                          },
                          rewind: true,
                          perMove: 1,
                          padding: '3.5rem',
                          autoplay: false,
                          interval: 1500,
                          speed: 1500, // Adjust the transition speed (in milliseconds)
                          easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                          breakpoints: {
                            700: {
                              perPage: 3
                            },
                            1200: {
                              perPage: 4
                            },
                            1600: {
                              perPage: 4
                            },
                            2000: {
                              perPage: 4
                            }
                          }
                        }}
                      >
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Lucknow
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Kanpur
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Varanasi
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Agra
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Allahabad
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Ghaziabad
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                      </Splide>
                    </div>
                    <div class="listview-title">Tamil Nadu</div>
                    <div class="mb-2">
                      <Splide
                        options={{
                          type: 'loop',
                          gap: 2,
                          arrows: false,
                          trimSpace: false,
                          autoWidth: true,
                          pagination: false,
                          grid: {
                            rows: 2,
                            cols: 2
                          },
                          rewind: true,
                          perMove: 1,
                          padding: '3.5rem',
                          autoplay: false,
                          interval: 1500,
                          speed: 1500, // Adjust the transition speed (in milliseconds)
                          easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                          breakpoints: {
                            700: {
                              perPage: 3
                            },
                            1200: {
                              perPage: 4
                            },
                            1600: {
                              perPage: 4
                            },
                            2000: {
                              perPage: 4
                            }
                          }
                        }}
                      >
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Chennai
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Coimbatore
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Madurai
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Salem
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Erode
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Trichy
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                      </Splide>
                    </div>
                    <div class="listview-title">West Bengal</div>
                    <div class="mb-2">
                      <Splide
                        options={{
                          type: 'loop',
                          gap: 2,
                          arrows: false,
                          trimSpace: false,
                          autoWidth: true,
                          pagination: false,
                          grid: {
                            rows: 2,
                            cols: 2
                          },
                          rewind: true,
                          perMove: 1,
                          padding: '3.5rem',
                          autoplay: false,
                          interval: 1500,
                          speed: 1500, // Adjust the transition speed (in milliseconds)
                          easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                          breakpoints: {
                            700: {
                              perPage: 3
                            },
                            1200: {
                              perPage: 4
                            },
                            1600: {
                              perPage: 4
                            },
                            2000: {
                              perPage: 4
                            }
                          }
                        }}
                      >
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Kolkata
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Howrah
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Durgapur
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Asansol
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Siliguri
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Bardhaman
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                      </Splide>
                    </div>
                    <div class="listview-title">Karnataka</div>
                    <div class="mb-2">
                      <Splide
                        options={{
                          type: 'loop',
                          gap: 2,
                          arrows: false,
                          trimSpace: false,
                          autoWidth: true,
                          pagination: false,
                          grid: {
                            rows: 2,
                            cols: 2
                          },
                          rewind: true,
                          perMove: 1,
                          padding: '3.5rem',
                          autoplay: false,
                          interval: 1500,
                          speed: 1500, // Adjust the transition speed (in milliseconds)
                          easing: 'cubic-bezier(0.25, 0.1, 0.25, 1)',
                          breakpoints: {
                            700: {
                              perPage: 3
                            },
                            1200: {
                              perPage: 4
                            },
                            1600: {
                              perPage: 4
                            },
                            2000: {
                              perPage: 4
                            }
                          }
                        }}
                      >
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Bengaluru
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Mysore
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Mangalore
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Hubballi
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                        <SplideSlide>
                          <div class="d-flex flex-column">
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Belagavi
                                </span>
                              </Link>
                            </CityChips>
                            <CityChips>
                              <Link
                                to="#"
                                className="d-flex align-items-center"
                              >
                                <span className="chip-delete ms-1">
                                  <ion-icon
                                    name="location-outline"
                                    class="fs-6"
                                  ></ion-icon>
                                </span>
                                <span
                                  className="chip-label ps-0"
                                  style={{ fontSize: '0.9rem' }}
                                >
                                  Gilbarga
                                </span>
                              </Link>
                            </CityChips>
                          </div>
                        </SplideSlide>
                      </Splide>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="offerings" role="tabpanel">
                <div
                  class="pb-3"
                  // style={{ marginLeft: '-8px', marginRight: '-8px' }}
                >
                  <div class="listview-title">Offerings</div>
                  <Splide
                    options={{
                      type: 'loop',
                      rewind: true,
                      perMove: 1,
                      perPage: 2,
                      gap: 10,
                      padding: '2.5rem',
                      autoplay: true,
                      interval: 3000,
                      pagination: false,
                      arrows: false,
                      breakpoints: {
                        700: {
                          perPage: 2
                        },
                        1200: {
                          perPage: 3
                        },
                        1600: {
                          perPage: 3
                        },
                        2000: {
                          perPage: 3
                        }
                      }
                    }}
                  >
                    <SplideSlide>
                      <div class="card bg-transparent shadow-none">
                        <img
                          src="assets/img/sample/photo/d1.jpg"
                          class="rounded"
                          alt="image"
                        />
                        <div class="card-body pt-2 ps-0">
                          <h3 class="mb-0 fw-normal">
                            Progressive web app ready
                          </h3>
                        </div>
                      </div>
                    </SplideSlide>
                  </Splide>
                </div>
              </div> */}
            </div>
          )}

          {/* FORM */}
          {
            actionType == 'update' && (
              // (loading ? (
              //   <div className="loaderDiv">
              //     <div className="loader"></div>
              //     <div className="loaderText">Creating New Business</div>
              //   </div>
              // ) : (
              <div class="section full" style={{ position: 'relative' }}>
                <form
                  onSubmit={e => {
                    CreateNanoWebsite(e)
                  }}
                  id="createBusinessForm"
                >
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label class="form-label" htmlhtmlFor="businessName">
                        Business Name
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value={customerName}
                        onChange={e => {
                          updateBusinessData(e.target.value, 'businessName')
                        }}
                        id="businessName"
                        placeholder="Business Name"
                        required
                        autoComplete="off"
                      />
                      <i class="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                    </div>
                  </div>

                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label class="form-label" htmlFor="city">
                        City
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value={businessData.Location}
                        id="city"
                        disabled={cityInput}
                        onChange={e => {
                          updateBusinessData(e.target.value, 'businessCity')
                        }}
                        placeholder="City"
                        autocomplete="off"
                        ref={cityInputRef}
                      />
                      <span
                        class="badge bg-secondary cursor-pointer fs-10"
                        onClick={() => {
                          updateCity()
                        }}
                        style={{ height: '16px' }}
                      >
                        {' '}
                        Change City
                      </span>
                      <i class="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                    </div>
                  </div>

                  <div class="form-group boxed">
                    <div class="input-wrapper text-end">
                      <button
                        class="btn btn-success btn-block"
                        type="submit"
                        disabled={disableSubmit || loading}
                      >
                        {loading ? (
                          <>
                            <span className="me-1">Creating Buisness</span>
                            <div
                              className="loader position-relative"
                              style={{ width: '20px', height: '20px' }}
                            ></div>
                          </>
                        ) : (
                          <>
                            Proceed
                            <ion-icon name="arrow-forward-outline"></ion-icon>
                          </>
                        )}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            )
            // ))
          }
          {actionType == 'verify' && (
            // customerName &&
            // customerName !== '' &&
            // businessData.Location &&
            // businessData.Location !== ''
            <>
              <div
                className={`form-group boxed ${
                  BizMobileOTP === null ? 'd-block' : 'd-none'
                }`}
              >
                <div class="input-wrapper">
                  <label class="form-label" htmlhtmlFor="ownerNumber">
                    Business Owner Mobile Number
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    value={
                      businessData?.ownerNumber ? businessData?.ownerNumber : ''
                    }
                    onChange={e => {
                      updateBusinessData(e.target.value, 'ownerNumber')
                    }}
                    id="ownerNumber"
                    placeholder=""
                    required
                    autocomplete="off"
                    disabled={OwnerName}
                  />
                  <i class="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>

                  <button
                    class="btn btn-success btn-block badgeButtons"
                    type="button"
                    disabled={
                      Loading ||
                      OwnerName ||
                      businessData?.ownerNumber?.toString().trim().length !== 10
                    }
                    onClick={HandleSendOTP}
                  >
                    Verify {Loading}
                  </button>
                </div>
              </div>

              {OwnerName && (
                <div className={`form-group boxed `}>
                  <div class="input-wrapper">
                    <label class="form-label" htmlFor="OwnerName">
                      Owner Name
                    </label>
                    <input
                      id="OwnerName"
                      type="text"
                      class="form-control"
                      value={
                        businessData.OwnerName ? businessData.OwnerName : ''
                      }
                      onChange={e => {
                        updateBusinessData(e.target.value, 'OwnerName')
                      }}
                      placeholder=""
                      required
                      autoComplete="off"
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                    <button
                      class="btn btn-success btn-block badgeButtons"
                      type="button"
                      disabled={
                        Loading ||
                        businessData.OwnerName?.toString().trim().length ===
                          0 ||
                        isDisable
                      }
                      onClick={HandleRegister}
                    >
                      Submit
                    </button>
                  </div>
                </div>
              )}

              <div
                className={`form-group boxed ${
                  BizMobileOTP !== null ? 'd-block' : 'd-none'
                }`}
              >
                <div class="input-wrapper">
                  <label class="form-label" htmlFor="ownerOTP">
                    Enter OTP{' '}
                    {OTPError && (
                      <span className="text-danger">Invalid OTP</span>
                    )}
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    value={BizMobileOTP ? BizMobileOTP : ''}
                    onChange={e => {
                      setBizMobileOTP(e.target.value)
                    }}
                    id="ownerOTP"
                    placeholder="******"
                    required
                    autoComplete="off"
                  />
                  <button
                    class="btn btn-success btn-block badgeButtons"
                    type="button"
                    disabled={
                      Loading ||
                      BizMobileOTP?.toString().trim().length !== 6 ||
                      (OwnerName &&
                        businessData?.OwnerName?.toString().trim().length === 0)
                    }
                    onClick={VerifyOTP}
                  >
                    Verify OTP
                  </button>
                  <i class="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
            </>
          )}

          {actionType == 'edit' && (
            <div class="section full" style={{ position: 'relative' }}>
              <form
                onSubmit={e => {
                  EditNanoWebsite(e)
                }}
              >
                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <label class="form-label" htmlFor="businessName">
                      Business Name
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={businessData?.BusinessName}
                      onChange={e => {
                        updateBusinessData(e.target.value, 'businessName')
                      }}
                      id="businessName"
                      placeholder="Business Name"
                      required
                      autocomplete="off"
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <label class="form-label" htmlFor="address5">
                      Description
                    </label>
                    <textarea
                      id="address5"
                      rows="2"
                      value={businessData.Description}
                      class="form-control"
                      onChange={e => {
                        updateBusinessData(
                          e.target.value,
                          'businessDescription'
                        )
                      }}
                    ></textarea>
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <label class="form-label" htmlFor="city">
                      City
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={businessData.Location}
                      id="city"
                      disabled={cityInput}
                      onChange={e => {
                        updateBusinessData(e.target.value, 'businessCity')
                      }}
                      placeholder="City"
                      autocomplete="off"
                      ref={cityInputRef}
                    />
                    <span
                      class="badge bg-secondary cursor-pointer fs-10"
                      onClick={() => {
                        updateCity()
                      }}
                      style={{ height: '16px' }}
                    >
                      {' '}
                      Change City
                    </span>
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <label class="form-label" htmlFor="buttonTitle">
                      Button Title
                    </label>
                    <input
                      type="text"
                      class="form-control"
                      value={businessData?.ActionButton?.Title}
                      onChange={e => {
                        updateBusinessData(e.target.value, 'buttonTitle')
                      }}
                      id="buttonTitle"
                      placeholder="Title"
                      required
                      autocomplete="off"
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>
                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <label class="form-label" htmlFor="buttonType">
                      Call To Action
                    </label>
                    <select
                      class="form-control form-select"
                      value={businessData?.ActionButton?.Type}
                      onChange={e => {
                        updateBusinessData(e.target.value, 'buttonType')
                      }}
                      id="buttonType"
                    >
                      <option value="" selected>
                        Select Type
                      </option>
                      <option value="whatsapp">Whatsapp</option>
                      {/* <option value="pdf">PDF</option> */}
                      <option value="form">Form</option>
                      <option value="website">Website</option>
                      <option value="direction">Direction</option>
                    </select>
                  </div>
                </div>
                {businessData?.ActionButton?.Type == 'direction' && (
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label class="form-label" htmlFor="buttonURL">
                        URL
                      </label>
                      <input
                        type="url"
                        class="form-control"
                        value={businessData?.ActionButton?.URL}
                        onChange={e => {
                          updateBusinessData(e.target.value, 'buttonURL')
                        }}
                        id="buttonURL"
                        placeholder="https://"
                        required
                        autocomplete="off"
                      />
                      <i class="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                    </div>
                  </div>
                )}
                {businessData?.ActionButton?.Type == 'website' && (
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label class="form-label" htmlFor="buttonURL">
                        URL
                      </label>
                      <input
                        type="text"
                        class="form-control"
                        value={businessData?.ActionButton?.URL}
                        onChange={e => {
                          updateBusinessData(e.target.value, 'buttonURL')
                        }}
                        id="buttonURL"
                        placeholder="https://"
                        required
                        autocomplete="off"
                      />
                      <i class="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                    </div>
                  </div>
                )}
                {businessData?.ActionButton?.Type == 'whatsapp' && (
                  <div class="form-group boxed">
                    <div class="input-wrapper">
                      <label class="form-label" htmlFor="buttonURL">
                        Whatsapp Number
                      </label>
                      <input
                        type="number"
                        class="form-control"
                        value={businessData?.ActionButton?.URL}
                        onChange={e => {
                          updateBusinessData(e.target.value, 'buttonURL')
                        }}
                        id="buttonURL"
                        placeholder="Whatsapp Number"
                        required
                        autocomplete="off"
                      />
                      <i class="clear-input">
                        <ion-icon name="close-circle"></ion-icon>
                      </i>
                    </div>
                  </div>
                )}
                {/* {businessData?.ActionButton?.Type == 'pdf' &&
                    <div class="form-group boxed">
                        <div class="input-wrapper">
                            <label class="form-label" htmlFor="buttonURL">
                                Whatsapp Number
                            </label>
                            <input
                            type="file"
                            class="form-control"
                            value={businessData?.ActionButton?.URL}
                            onChange={e => {
                                updateBusinessData('https://wa.me/' + e.target.value, 'buttonURL')
                            }}
                            id="buttonURL"
                            placeholder="Whatsapp Number"
                            required
                            autocomplete="off"
                            />
                            <i class="clear-input">
                            <ion-icon name="close-circle"></ion-icon>
                            </i>
                        </div>
                    </div>
                } */}
                <div class="form-group boxed">
                  <div class="input-wrapper text-end">
                    <button
                      class="btn btn-success btn-block"
                      type="submit"
                      disabled={disableSubmit}
                    >
                      Update
                    </button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {(actionType == 'share' ||
            actionType == 'chat' ||
            actionType == 'bookmark') && (
            <div class="section full" style={{ position: 'relative' }}>
              <ul class="listview image-listview flush border-top">
                <li>
                  <div class="item p-0 pe-1" onClick={handleChatThreadView}>
                    <img
                      src="assets/img/sample/avatar/avatar3.jpg"
                      alt="image"
                      class="image"
                    />
                    <div class="in">
                      <div>Edward Lindgren</div>
                      <span class="text-muted">
                        <ion-icon
                          name="chatbubbles-outline"
                          class="fs-3"
                        ></ion-icon>
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item p-0 pe-1" onClick={handleChatThreadView}>
                    <img
                      src="assets/img/sample/avatar/avatar2.jpg"
                      alt="image"
                      class="image"
                    />
                    <div class="in">
                      <div>Emelda Scandroot</div>
                      <span class="text-muted">
                        <ion-icon
                          name="chatbubbles-outline"
                          class="fs-3"
                        ></ion-icon>
                      </span>
                    </div>
                  </div>
                </li>
                <li>
                  <div class="item p-0 pe-1" onClick={handleChatThreadView}>
                    <img
                      src="assets/img/sample/avatar/avatar5.jpg"
                      alt="image"
                      class="image"
                    />
                    <div class="in">
                      <div>Henry Bove</div>
                      <span class="text-muted">
                        <ion-icon
                          name="chatbubbles-outline"
                          class="fs-3"
                        ></ion-icon>
                      </span>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          )}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
const mapStateToProps = ({ editBusiness, myBusiness, QrData, auth }) => {
  const { editBusinessOffcanvasVisible, actionType } = editBusiness
  const { businessData, userCurrentLocation, userCurrentLat, userCurrentLong } =
    myBusiness
  const { customerName, UpiUrl, UpiID } = QrData
  const { role } = auth
  return {
    editBusinessOffcanvasVisible,
    actionType,
    businessData,
    customerName,
    UpiUrl,
    UpiID,
    userCurrentLocation,
    role,
    userCurrentLat,
    userCurrentLong
  }
}

const mapDispatchToProps = {
  hideEditBusinessViewModal,
  showEditBusinessViewModal,
  showMyBusinessModal,
  showCityListModal,
  setCreateBusinessData,
  setCustomerName,
  setQrOffCanvas,
  setUpiUrl,
  setUpiID,
  showChatThreadViewOffcanvas,
  hideMyBusinessModal
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditBusinessOffcanvas)
