import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  myBusinessModalVisible: false,
  businessData: {},
  userCurrentLocation: '',
  userCurrentCountry: '',
  userCurrentLat: '',
  userCurrentLong: '',
  locationAccess: null,
  BizCategory: null,
  BizLogo: null,
  BizBanner: null,
  userCurrentLat: '',
  userCurrentLong: ''
}

export const MyBusinessModalSlice = createSlice({
  name: 'myBusiness',
  initialState,
  reducers: {
    showMyBusinessModal: (state, action) => {
      const { myBusinessModalVisible } = action.payload
      state.myBusinessModalVisible = myBusinessModalVisible
    },
    hideMyBusinessModal: (state, action) => {
      state.myBusinessModalVisible = false
    },
    setCreateBusinessData: (state, action) => {
      const { businessData } = action.payload
      state.businessData = businessData
    },
    setUserCurrentLocation: (state, action) => {
      const {
        userCurrentLocation,
        userCurrentCountry,
        userCurrentLat,
        userCurrentLong
      } = action.payload
      state.userCurrentLocation = userCurrentLocation
      if (userCurrentCountry) state.userCurrentCountry = userCurrentCountry
      if (userCurrentLat) state.userCurrentLat = userCurrentLat
      if (userCurrentLong) state.userCurrentLong = userCurrentLong
    },
    setLocationAccess: (state, action) => {
      state.locationAccess = action.payload
    },
    setBizCategory: (state, action) => {
      state.BizCategory = action.payload
    },
    setBizLogo: (state, action) => {
      state.BizLogo = action.payload
    },
    setBizBanner: (state, action) => {
      state.BizBanner = action.payload
    }
  }
})

export const {
  showMyBusinessModal,
  hideMyBusinessModal,
  setCreateBusinessData,
  setUserCurrentLocation,
  setLocationAccess,
  setBizCategory,
  setBizBanner,
  setBizLogo
} = MyBusinessModalSlice.actions

export default MyBusinessModalSlice.reducer
