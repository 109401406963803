import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'
import Vector from '../images/sample/vector2.png' 

const Introduction = () => {
  return (
    <>
      <div class="appHeader no-border transparent">
        <div class="left"></div>
        <div class="pageTitle">
          {/* <img src="assets/img/logo.png" alt="logo" class="logo" /> */}
        </div>
        <div class="right">
          <a href="app-pages.html" class="headerButton text-secondary">
            Skip
          </a>
        </div>
      </div>
      <div id="appCapsule">
        <div class="section">
          <Splide
            options={{
              type: "loop",
              arrows: false
            }}
          >
            <SplideSlide>
              <div class="d-flex justify-content-center flex-column">
                <img
                  src={Vector}
                  alt="alt"
                  width="100%"
                  style={{maxWidth: '400px'}}
                  class="imaged square mb-4 m-auto"
                />
                <h2 class="d-flex justify-content-center">Reusable components</h2>
                <p class="d-flex justify-content-center text-center">
                  Reusable components designed for the mobile interface and ready
                  to use.
                </p>
              </div>
            </SplideSlide>
          </Splide>
        </div>

        <div class="carousel-button-footer">
          <div class="row d-flex justify-content-center">
            <div class="col-12 col-md-6">
              <a
                href="#"
                class="btn btn-primary btn-lg btn-block"
              >
                Start here&nbsp;&nbsp;&nbsp;&nbsp;
                <ion-icon name="chevron-forward-outline" class="fs-5"></ion-icon>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Introduction
