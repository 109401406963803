import { configureStore } from '@reduxjs/toolkit'
import appToastReducer from '../features/utilities/appToastSlice'
import authReducer from '../features/auth/authSlice'
import quickMeetReducer from '../features/offcanvas/QuickMeetModalSlice'
import profileModalReducer from '../features/offcanvas/ProfileModalSlice'
import nanoWebsiteReducer from '../features/nanoWebsite/nanoWebsiteSlice'
import loginPopupReducer from '../features/offcanvas/LoginPopupSlice'
import detailViewReducer from '../features/offcanvas/DetailViewSlice'
import findViewReducer from '../features/offcanvas/FindViewSlice'
import chatViewReducer from '../features/offcanvas/ChatViewSlice'
import MyBusinessReducer from '../features/offcanvas/MyBusinessSlice'
import EditBusinessReducer from '../features/offcanvas/EditBusinessSlice'
import CityListReducer from '../features/offcanvas/CityListSlice'
import DataReducer from '../features/data/dataSlice'
import ConfirmationReducer from '../features/offcanvas/ConfirmationSlice'
import QrSliceReducer from '../features/offcanvas/QrSlice'
import imageUploadReducer from '../features/offcanvas/ImageUploadSlice'
export const store = configureStore({
  reducer: {
    appToast: appToastReducer,
    auth: authReducer,
    quickMeet: quickMeetReducer,
    profileModal: profileModalReducer,
    nanoWebsite: nanoWebsiteReducer,
    loginPopup: loginPopupReducer,
    detailView: detailViewReducer,
    findView: findViewReducer,
    chatView: chatViewReducer,
    myBusiness: MyBusinessReducer,
    editBusiness: EditBusinessReducer,
    cityList: CityListReducer,
    data: DataReducer,
    QrData: QrSliceReducer,
    ImageUploadData: imageUploadReducer,
    confirmation: ConfirmationReducer
  }
})
