import React from 'react'
import QRScanner from './Comps/QrScanner'
import './QrPage.css'
import Offcanvas from 'react-bootstrap/Offcanvas'
import { connect } from 'react-redux'
import { setQrOffCanvas } from '../../features/offcanvas/QrSlice'
import showEditBusinessViewModal from '../../features/offcanvas/EditBusinessSlice'
import setCustomerName from '../../features/offcanvas/QrSlice'
function QrPage({
  setQrOffCanvas,
  QrOffCanvas,
  showEditBusinessViewModal,
  setCustomerName
}) {
  return (
    <Offcanvas
      className="offcanvasForQr"
      placement="bottom"
      show={QrOffCanvas}
      style={{
        transition: '0.2s ease-in-out',
        height: 'auto',
        zIndex: 9999,
        borderTopLeftRadius: '25px',
        borderTopRightRadius: '25px',
        maxWidth: '100%',
        margin: '0 auto'
      }}
    >
      <div>
        <button
          className="closeBtn"
          onClick={() => {
            setQrOffCanvas(false)
          }}
        >
          <ion-icon name="close-outline"></ion-icon>
        </button>
        {/* <button className="switchBtn">
          <ion-icon name="camera-reverse-outline"></ion-icon>
        </button> */}
        <QRScanner
          setCustomerName={setCustomerName}
          setQrOffCanvas={setQrOffCanvas}
          showEditBusinessViewModal={showEditBusinessViewModal}
        />
      </div>
    </Offcanvas>
  )
}

const mapStateToProps = ({ QrData }) => {
  const { QrOffCanvas } = QrData
  return {
    QrOffCanvas
  }
}

const mapDispatchToProps = {
  setQrOffCanvas,
  setCustomerName,
  showEditBusinessViewModal
}

export default connect(mapStateToProps, mapDispatchToProps)(QrPage)
