import React, { useEffect, useState, useRef, useMemo } from 'react'
import config from '../../config'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import axios from 'axios'
import 'react-phone-number-input/style.css'
import PhoneInput, { getCountryCallingCode } from 'react-phone-number-input'
import AppToast from '../Elements/Notification/AppToast'
import {
  showAppToast,
  hideAppToast
} from '../../features/utilities/appToastSlice'
import Countdown, { zeroPad } from 'react-countdown'
import {
  requestOtp,
  validateOtp,
  backToLogin,
  registerUser
} from '../../features/auth/authSlice'
import Logo from '../images/webwebapp-rounded.svg'
import {
  showLoginPopup,
  hideLoginPopup
} from '../../features/offcanvas/LoginPopupSlice'
import './Authentication.css'
const Authentication = ({
  showAppToast,
  appToastVisible,
  appToastMessage,
  appToastIcon,
  appToastBackground,
  appToastPosition,
  requestOtp,
  validateOtp,
  otpRequestSuccess,
  otpRequestError,
  hideSendOtpButton,
  showRegisterUser,
  showVerifyOtpForm,
  otpValidateSuccess,
  otpValidateError,
  backToLogin,
  isVisible,
  authToken,
  showLoginPopup,
  hideLoginPopup,
  registerUser
}) => {
  const inputRef = useRef(null)
  const emailInputRef = useRef(null)

  const [mobileNumber, setMobileNumber] = useState('')
  const [mobileNumberCC, setMobileNumberCC] = useState('+91')
  const [disableVerifyOtpButton, setDisableVerifyOtpButton] = useState(false)
  const [logo, setAppLogo] = useState('')
  const [disableOtp, setDisableOtp] = useState(true)

  const [showEmailInput, setShowEmailInput] = useState(false)
  const [emailInput, setEmailInput] = useState(false)
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false)

  const [registerForm, setRegisterForm] = useState(false)

  const [fullName, setFullName] = useState(null)
  const isDisabled = fullName === null || fullName === ''
  const [profileOptions, setProfileOptions] = useState([
    // {
    //   label: 'Businessman',
    //   value: 'Businessman'
    // },
    // {
    //   label: 'Professional',
    //   value: 'Professional'
    // },
    // {
    //   label: 'Job Seeker',
    //   value: 'JobSeeker'
    // },
    {
      label: 'Create Bussiness',
      value: 'Consumer'
    }
  ])
  const [selectedProfile, setSelectedProfile] = useState(null)
  const [registerButtonDisabled, setRegisterButtonDisabled] = useState(true)

  let navigate = useNavigate()

  useEffect(() => {
    if (otpRequestSuccess) {
      showAppToast({
        toastMessage: 'OTP SENT',
        background: 'success',
        timeout: '1000',
        icon: 'checkmark-circle-outline',
        position: 'toast-center'
      })
    }
  }, [otpRequestSuccess, showAppToast])

  useEffect(() => {
    if (otpRequestError) {
      showAppToast({
        toastMessage: 'Error',
        background: 'danger',
        timeout: '1000',
        icon: 'close-circle-outline',
        position: 'toast-center'
      })
    }
  }, [otpRequestError, showAppToast])

  useEffect(() => {
    if (otpValidateSuccess) {
      showAppToast({
        toastMessage: 'OTP SENT',
        background: 'success',
        timeout: '1000',
        icon: 'checkmark-circle-outline',
        position: 'toast-center'
      })
      setDisableVerifyOtpButton(true)
    }
  }, [otpValidateSuccess, showAppToast, setDisableVerifyOtpButton])

  useEffect(() => {
    if (otpValidateError) {
      showAppToast({
        toastMessage: 'Error',
        background: 'danger',
        timeout: '1000',
        icon: 'close-circle-outline',
        position: 'toast-center'
      })
      setDisableVerifyOtpButton(false)
    }
  }, [otpValidateError, showAppToast, setDisableVerifyOtpButton])

  const handleUserNameValidation = e => {
    e.preventDefault()
    // setSendOtpButton(true)
    requestOtp({ mobileNumber: mobileNumber.slice(3) })
  }

  const HandleOtpValidation = e => {
    e.preventDefault()
    setDisableVerifyOtpButton(true)
    const otp = e.target.otp.value
    validateOtp({ mobileNumber: `${mobileNumber.slice(3)}`, otp })
  }

  const [timer, setTimer] = useState(Date.now() + 59999)

  const reSendOtp = e => {
    e.preventDefault()
    axios
      .get(config.apiUrl + '/user/login?mobile=' + `${mobileNumber.slice(3)}`)
      .then(res => {
        if (res.data.message === 'Success') {
          // setResMessage(res.data.data)
          setDisableOtp(true)
          showAppToast({
            toastMessage: 'OTP Sent',
            background: 'success',
            timeout: '1000',
            icon: 'checkmark-circle-outline',
            position: 'toast-center'
          })
          setTimer(Date.now() + 59999)
        } else {
          showAppToast({
            toastMessage: 'Something Went Wrong',
            background: 'danger',
            timeout: '1000',
            icon: 'close-circle-outline',
            position: 'toast-center'
          })
        }
      })
  }

  const Renderer = () => {
    setDisableOtp(false)
  }

  const countdownRenderer = ({ minutes, seconds }) => {
    return (
      <span>
        {zeroPad(minutes)}:{zeroPad(seconds)}
      </span>
    )
  }

  useEffect(() => {
    setTimer(Date.now() + 59999)
  }, [showVerifyOtpForm])

  const countDownTimer = useMemo(() => {
    return (
      <Countdown
        key={timer}
        date={timer}
        onComplete={Renderer}
        renderer={countdownRenderer}
      />
    )
  }, [timer])

  useEffect(() => {
    if (authToken !== null && authToken !== undefined && authToken !== '') {
      // showLoginPopup({loginPopupVisible: false})
    } else {
      // hideLoginPopup({loginPopupVisible: true})
      // alert('Please Register / Login')
    }
  }, [authToken])

  // axios.get(config.apiUrl + '/Ai/readWhitelabelJSON/its.me.in').then(res=>{
  //     if (res.data.message === 'Success') {
  //       setAppLogo(res.data.data.logo)
  //       localStorage.setItem('AppLogo', res.data.data.logo);
  //     } else {
  //       showAppToast({ toastMessage:'Something Went Wrong', background: 'danger', timeout: '1000', icon:'close-circle-outline', position: 'toast-center' })
  //     }
  // })

  const handleUserNameInput = event => {
    const value = event.target.value
    if (value) {
      if (isNaN(value)) {
        setShowEmailInput(true)
        setShowPhoneNumberInput(false)
        setEmailInput(value)
      } else {
        setMobileNumber(`${mobileNumberCC}${value}`)
        setShowEmailInput(false)
        setShowPhoneNumberInput(true)
      }
    } else {
      setShowEmailInput(false)
      setShowPhoneNumberInput(false)
      setEmailInput('')
      setMobileNumber('')
    }
  }

  useEffect(() => {
    if (setShowPhoneNumberInput && inputRef && mobileNumber.length <= 4) {
      inputRef?.current?.focus()
    }
  }, [setShowPhoneNumberInput, mobileNumber])

  useEffect(() => {
    if (showEmailInput && emailInputRef && emailInput.length <= 2) {
      emailInputRef?.current?.focus()
    }
  }, [emailInput, showEmailInput])

  const handlePhoneNumberInput = number => {
    const codeLength = mobileNumberCC.length
    if (number) {
      if (number.slice(0, codeLength) === mobileNumberCC) {
        setMobileNumber(number)
      } else {
        setMobileNumber(mobileNumber.replace(mobileNumberCC, number))
      }
    } else {
      setMobileNumber('')
      setEmailInput('')
      setShowEmailInput(false)
      setShowPhoneNumberInput(false)
    }
  }

  const handleEmailInput = event => {
    const value = event.target.value
    if (value) {
      setEmailInput(value)
    } else {
      setShowEmailInput(false)
      setShowPhoneNumberInput(false)
      setEmailInput('')
      setMobileNumber('')
    }
  }

  const handleCountryChange = event => {
    const countryCode = getCountryCallingCode(event)
    setMobileNumberCC(`+${getCountryCallingCode(event)}`)
    if (countryCode !== '91') {
      setEmailInput('')
      setShowEmailInput(true)
    } else {
      setShowEmailInput(false)
      setEmailInput('')
    }
  }
  // useEffect(() => {
  //   if (showRegisterUser) {
  //     // navigate('/register?mobile=' + mobileNumber)
  //     setRegisterForm(true);
  //   }
  // }, [showRegisterUser])

  useEffect(() => {
    // Select the input element
    const input = document.getElementById('number')
    if (input) {
      input.setAttribute('autofocus', true)
    }
    // Set autofocus attribute to true
  }, [])

  const handleSelectProfile = event => {
    event.preventDefault()
    setSelectedProfile('Consumer')
  }

  const handleRegitserUser = () => {
    // e.preventDefault
    let newUserData = {
      username: fullName,
      mobileNumber: `${mobileNumber.slice(3)}`,
      profile: selectedProfile
    }
    registerUser(newUserData)
  }

  useEffect(() => {
    if (fullName !== null && selectedProfile !== null) {
      handleRegitserUser()
    }
  }, [fullName, selectedProfile])

  const handleBackToLogin = () => {
    backToLogin()
    setMobileNumber('')
    setDisableVerifyOtpButton(false)
    setDisableOtp(true)
    setSelectedProfile(null)
    setFullName(null)
  }

  return (
    <div>
      {!showVerifyOtpForm && !showRegisterUser && (
        <div className="login-form mt-1">
          <div className="section">
            <img
              src={Logo}
              // https://webweb.ams3.cdn.digitaloceanspaces.com/webweb.svg
              alt="Brand Logo"
              className="form-image"
              style={{ maxWidth: '80px' }}
            />
          </div>
          <div className="section mt-1">
            {/* <h1>Authentication</h1> */}
            <h3 class="mt-4 mb-0">Enter phone number</h3>
            {/* <h4>login to manage your websites</h4> */}
          </div>
          <div className="section mt-0 mb-0">
            <form onSubmit={handleUserNameValidation}>
              <div className="form-group boxed ps-2 pe-2 mb-4">
                <div className="input-wrapper">
                  {/* {
                  showPhoneNumberInput && */}
                  <PhoneInput
                    className="form-control phoneInput mt-1 pe-0"
                    international
                    defaultCountry="IN"
                    value={`${mobileNumber}`}
                    onChange={handlePhoneNumberInput}
                    onCountryChange={handleCountryChange}
                    ref={inputRef}
                    maxLength={15}
                    countryCallingCodeEditable={false}
                    focus
                  />
                  {/* } */}
                  {/* {
                  !showPhoneNumberInput && !showEmailInput &&
                  <input
                    type="tel"
                    className="form-control mt-1"
                    id="number"
                    placeholder="Enter Email OR Mobile Number"
                    maxLength={10}
                    required name="mobileNumber"
                    onChange={handleUserNameInput}
                  />
                }
                {
                  showEmailInput && <input
                  className="form-control mt-1"
                  id="email"
                  placeholder="Enter Email"
                  required
                  name="email"
                  value={emailInput}
                  onChange={handleEmailInput}
                  ref={emailInputRef}
                />
                } */}
                  <i className="clear-input">
                    <ion-icon name="close-circle"></ion-icon>
                  </i>
                </div>
              </div>
              {/* <div className="form-links mt-2 mb-2 d-flex justify-content-center align-items-center">
                <div className="text-info fw-bold">
                  Dont have Website? <a href="#!">Build Instant Nano Website</a>
                </div>
              </div>
              <div className="divider bg-secondary mb-2"></div>
              <small className="mt-3">
                By logging in, you agree to our{" "}
                <a href="https://webweb.in/terms-conditions">Terms of Use</a> and
                to receive Webweb emails & updates and acknowledge that you read
                our <a href="https://webweb.in/privacy-policy">Privacy Policy</a>
              </small> */}
              <div
                className="form-button-group login-form mb-1"
                style={{ position: 'initial', minHeight: '50px' }}
              >
                {hideSendOtpButton ? (
                  <button
                    className="btn btn-primary btn-block btn-lg"
                    type="button"
                    disabled
                  >
                    <span
                      className="spinner-border spinner-border-sm me-05"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    Authorizing...
                  </button>
                ) : (
                  <button
                    className="btn btn-primary btn-block btn-lg"
                    type="submit"
                  >
                    Authenticate
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
      {showVerifyOtpForm && !showRegisterUser && (
        <div>
          <div
            className="no-border transparent position-absolute"
            style={{ top: 15 }}
          >
            <div className="left">
              <button
                type="button"
                className="btn btn-text-secondary btn-sm border-0 headerButton goBack"
                onClick={handleBackToLogin}
              >
                <ion-icon name="chevron-back-outline" class="fs-1"></ion-icon>
              </button>
            </div>
            <div className="pageTitle"></div>
            <div className="right"></div>
          </div>
          <div className="login-form mt-5">
            <div className="section">
              <h1>Enter OTP</h1>
              <h4>We sent a verification code on your phone</h4>
            </div>
            <div className="section mt-2 mb-2">
              <form onSubmit={HandleOtpValidation}>
                <div className="form-group boxed">
                  <div className="input-wrapper">
                    <input
                      type="text"
                      className="form-control verify-input"
                      id="smscode"
                      placeholder="••••••"
                      maxlength={6}
                      required
                      name="otp"
                      autoFocus
                    />
                    {countDownTimer}
                  </div>
                </div>
                <div className="form-links mt-2 mb-2 d-flex justify-content-center align-items-center">
                  <div className="d-flex flex-column">
                    <span className="d-flex align-items-center justify-content-center">
                      <ion-icon name="stopwatch-outline"></ion-icon>&nbsp;
                    </span>
                    <button
                      type="button"
                      className="btn btn-outline-primary btn-sm rounded"
                      onClick={reSendOtp}
                      disabled={disableOtp}
                    >
                      {' '}
                      Resend OTP{' '}
                    </button>
                  </div>
                </div>
                <div className="form-button-group login-form position-relative">
                  <button
                    className="btn btn-primary btn-block btn-lg"
                    disabled={disableVerifyOtpButton}
                  >
                    Verify
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      {showRegisterUser && !showVerifyOtpForm && (
        <div>
          <div className="appHeader no-border transparent position-absolute">
            <div className="left">
              <button
                type="button"
                className="btn btn-text-secondary btn-sm border-0 headerButton goBack"
                onClick={handleBackToLogin}
              >
                <ion-icon name="chevron-back-outline"></ion-icon>
              </button>
            </div>
            <div className="pageTitle"></div>
            <div className="right"></div>
          </div>
          <div class="login-form">
            <div class="section">
              <h1>Register</h1>
              <h4>Fill the form to join us</h4>
            </div>
            <div class="section mt-2 mb-5">
              <form
                onSubmit={handleSelectProfile}
                //  onSubmit={(e) => {handleRegitserUser(e)}}
              >
                <div class="form-group boxed">
                  <div class="input-wrapper">
                    <input
                      type="text"
                      class="form-control"
                      id="name1"
                      placeholder="Full name"
                      value={fullName}
                      onChange={e => {
                        setFullName(e.target.value)
                      }}
                      required
                      autoFocus
                    />
                    <i class="clear-input">
                      <ion-icon name="close-circle"></ion-icon>
                    </i>
                  </div>
                </div>

                <div class="section full d-flex justify-content-center flex-column align-items-center">
                  {/* <h4 class="mt-1">Choose your profile</h4> */}
                  <div
                    class=""
                    role="group"
                    aria-label="Basic radio toggle button group"
                  >
                    <div class="row">
                      {profileOptions.map((profile, i) => {
                        return (
                          <div key={i} className="">
                            {/* <input
                              disabled={fullName === null}
                              type="radio"
                              class="btn-check"
                              name="btnradio"
                              id={`btnradio${i}`}
                              value={profile.value}
                              checked={selectedProfile === profile.value}
                              onClick={handleSelectProfile}
                              required
                            /> */}
                            {/* <label
                              class="btn btn-outline-primary text-nowrap h-auto w-100 mb-2"
                              for={`btnradio${i}`}
                            > */}
                            {/* <div class="card shadow-none bg-transparent w-100">
                                <div class="card-body fs-6 px-0">
                                  {profile.label}
                                </div>
                              </div> */}
                            {/* </label> */}

                            <button
                              type="submit"
                              // disabled={isDisabled}
                              className="submitProfile"
                              // value={profile.value}
                              // checked={selectedProfile === profile.value}
                            >
                              Proceed
                            </button>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
                <div></div>

                {/* <div class=" mt-1 text-start">
                  <div class="form-check">
                    <input
                      type="checkbox"
                      class="form-check-input"
                      id="customCheckb1"
                      checked={termsChecked}
                      onChange={(e) => setTermsChecked(e.target.checked)}
                      required
                    />
                    <label class="form-check-label" for="customCheckb1">
                      I Agree <a href="#">Terms & Conditions</a>
                    </label>
                  </div>
                </div> */}

                {/* <div
                  class="position-absolute d-flex align-items-center justify-content-center"
                  style={{
                    bottom: 0,
                    left: 0,
                    right: 0,
                    paddingLeft: '16px',
                    paddingRight: '16px',
                    minHeight: '84px',
                    width: '100%'
                  }}
                >
                  <div class="row w-100 d-flex justify-content-center">
                      <div class="col-12 col-md-6 p-0">
                      <button
                          class="btn btn-primary btn-lg btn-block"
                          // disabled={registerButtonDisabled}
                          type="submit"
                      >
                          Register
                      </button>
                      </div>
                  </div>
                </div> */}
              </form>
            </div>
          </div>
        </div>
      )}
      {appToastVisible && (
        <AppToast
          message={appToastMessage}
          show={true}
          background={appToastBackground}
          icon={appToastIcon}
          position={appToastPosition}
        />
      )}
    </div>
  )
}

const mapStateToProps = ({ appToast, auth }) => {
  const { isVisible, toastMessage, background, icon, position } = appToast

  const {
    otpRequestSuccess,
    otpRequestError,
    hideSendOtpButton,
    showRegisterUser,
    showVerifyOtpForm,
    otpValidateSuccess,
    otpValidateError,
    authToken
  } = auth

  return {
    appToastVisible: isVisible,
    appToastMessage: toastMessage,
    appToastBackground: background,
    appToastIcon: icon,
    appToastPosition: position,
    otpRequestSuccess,
    otpRequestError,
    hideSendOtpButton,
    showRegisterUser,
    showVerifyOtpForm,
    otpValidateSuccess,
    otpValidateError
  }
}

const mapDispatchToProps = {
  showAppToast,
  hideAppToast,
  requestOtp,
  validateOtp,
  backToLogin,
  showLoginPopup,
  hideLoginPopup,
  registerUser
}

export default connect(mapStateToProps, mapDispatchToProps)(Authentication)
