import React, { useEffect, useState } from 'react'
import { connect } from "react-redux"
import Footer from '../layout/Footer/Footer'
import Header from '../layout/Header/Header'
import axios from '../../AxiosConfig'
import config from '../../config'
import Sidebar from '../layout/Sidebar/Sidebar'
import { showProfileModal } from '../../features/offcanvas/ProfileModalSlice' 

const Layout = ({
    children,
    showProfileModal
}) => {
    const [screenWidth, setScreenWidth] = useState(null)
    const pageUrl = window.location.href
    const newUser = localStorage.getItem('NewUser')
    console.log(newUser)
    useEffect(() => {
        setScreenWidth(window.screen.width)
      }, [pageUrl])
      useEffect(() => {
        if (newUser === false) {
          showProfileModal({profileModalVisible: false})
        } else {
          showProfileModal({profileModalVisible: true})
        }
      }, [newUser])
    return (
      <div className="App">
        <div className="">
            <div className="row mx-0 px-0">
              <div class="col-md-3 col-lg-2">
                  <Sidebar></Sidebar>
              </div>
              <div class="col-12 col-md-9 col-lg-10">
                <div class={(pageUrl.includes('MessageView')) ? 'container' : 'w-100'}>
                  {(screenWidth < 700) &&
                      <Header></Header>
                  }
                  {children}
                  {/* <Footer></Footer> */}
                </div>
              </div>
            </div>
        </div>
      </div>
    )
}

const mapStateToProps = () => {
    return {
    }
  }
  
  const mapDispatchToProps = {
    showProfileModal
  }

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
