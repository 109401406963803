import React, { useState } from 'react'
import './Tour.css';
import { Button } from 'react-bootstrap';
import { useEffect } from 'react';

function Tour({ setHideTour }) {

    const [CurrentStep, setCurrentStep] = useState(1);

    function GoToNext (num) {
        if(num !== 0) {
            setCurrentStep(num)
        } else {
            setHideTour()
        }
    }

    useEffect(() => {
        if(document.querySelectorAll('.splide__slide.is-active .moreDetailsButton')) {
            const FirstButton = document.querySelectorAll('.splide__slide.is-active .moreDetailsButton');
            if(FirstButton.length > 0) {
                let num = FirstButton[0].getBoundingClientRect();
                let Btnx = num.left + window.scrollX;
                let Btny = num.top + window.scrollY;
                document.documentElement.style.setProperty('--buttonY', `${Btny}px`);
                document.documentElement.style.setProperty('--buttonX', `${Btnx}px`);
            }
        }
    }, []);

    return (
        <div className='TourFrame'>
            <div className='TourSteps'>

                <div className={`Step ${CurrentStep === 1 && 'ShowStep' }`}>
                    <div className='SwipeUpCss SwipeUpDownCss'>
                        <img src='/assets/img/Gif/select.svg' className='mt-2' alt='SwipeUp' />
                    </div>
                    <p className='h5'>Swipe up and swipe down<br /> to explore</p>
                    <Button variant='light' onClick={() => GoToNext(2)}>Next</Button>
                </div>

                <div className={`Step mt-5 ${CurrentStep === 2 && 'ShowStep' }`}>
                    <div className='SwipeLeftCss SwipeLeftrightCss'>
                        <img src='/assets/img/Gif/select.svg' className='mt-2' alt='SwipeUp' />
                    </div>
                    <p className='h5'>Swipe left and swipe right <br />to explore</p>
                    <div  className='d-flex gap-2'>
                        <Button variant='outline-light' onClick={() => GoToNext(1)}>Back</Button>
                        <Button variant='light' onClick={() => GoToNext(3)}>Next</Button>
                    </div>
                </div>

                <div className={`Step mt-5 ${CurrentStep === 3 && 'ShowStep' }`}>
                    <p className='h5'>For More Information <br />Click on Button</p>
                    <div  className='d-flex gap-2'>
                        {/* <Button variant='outline-light' onClick={() => GoToNext(2)}>Back</Button> */}
                        <Button variant='light' onClick={() => GoToNext(0)}>Try</Button>
                    </div>
                    <div className='moredetailsButton'>
                        {/* <div className='Button'>
                            More Details 
                        <ion-icon
                            name="chevron-forward-outline"
                            class="fs-6 me-0"
                        ></ion-icon> 
                        </div> */}
                        <img src='/assets/img/Gif/select.svg' className='mt-2' alt='SwipeUp' />
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default Tour