import React, { Fragment, useEffect } from 'react'
import { connect } from 'react-redux'

import {
  showLoginPopup,
  hideLoginPopup
} from './features/offcanvas/LoginPopupSlice'

import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { setToken } from './features/auth/authSlice'
import Authentication from './components/Pages/Authentication'
import Layout from './components/Pages/Layout'
import Load from './components/Pages/Load'
import AppInfo from './components/Pages/AppInfo'
import jwt_decode from 'jwt-decode'
import Header from './components/layout/Header/Header'
import Footer from './components/layout/Footer/Footer'
import Summary from './components/Pages/Summary'
import Meet from './components/Pages/Meet'
import Categories from './components/Pages/Categories'
import Messages from './components/Pages/Messages'
import Businesses from './components/Pages/Businesses'

import Manage from './components/Pages/Manage'
import Home from './components/Pages/Home'
import Home2 from './components/Pages/Home2'
import Find from './components/Pages/Find'
import QuickMeet from './components/Pages/QuickMeet'
import NanoWebsite from './components/Pages/NanoWebsiteState'
import UserProfile from './components/Pages/UserProfile'
import MoreDetails from './components/Pages/MoreDetails'
import Search from './components/Pages/Search'
import Introduction from './components/Pages/Introduction'
import Register from './components/Pages/Register'
import OTP from './components/Pages/Otp'
import AuthModal from './components/layout/AuthModal/AuthModal'
import MyBusinessModal from './components/layout/Sidebar/MyBusinessModal'

// import { generateToken, messaging } from './notifications/firebase'
// import { onMessage } from 'firebase/messaging'
import EditBusinessOffcanvas from './components/layout/Sidebar/EditBusinessOffcanvas'
import ChatThreadViewOffcanvas from './components/layout/Sidebar/ChatThreadViewOffcanvas'
import MapView from './components/Pages/MapView'
const App = ({ authToken, setToken, showLoginPopup, hideLoginPopup }) => {
  // useEffect(() => {
  //   generateToken()
  //   onMessage(messaging, (payload) => {
  //   })
  // }, [])
  // if ((localStorage.getItem('Orders') === null && localStorage.getItem('Purchase') === null && localStorage.getItem('Wastage') === null) || (localStorage.getItem('Orders').length <= 2 && localStorage.getItem('Purchase').length <= 2 && localStorage.getItem('Wastage').length <= 2)) {
  //   JSON.stringify(localStorage.setItem('Orders', '[]'))
  //   JSON.stringify(localStorage.setItem('Purchase', '[]'))
  //   JSON.stringify(localStorage.setItem('Wastage', '[]'))
  //   JSON.stringify(localStorage.setItem('Carts', '[]'))
  // }
  function ResizeWindow() {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
    document.documentElement.setAttribute('deviceHight', window.innerHeight)
  }

  useEffect(() => {
    // window.addEventListener('resize', () => {
    //   ResizeWindow()
    // })

    ResizeWindow()
    return () => {
      window.removeEventListener('resize', () => {
        ResizeWindow()
      })
    }
  }, [])
  const validateToken = () => {
    if (authToken) {
      return true
    } else {
      const token = localStorage.getItem('token')
      const tokenData = token ? jwt_decode(token) : false
      if (tokenData && new Date(tokenData.exp * 1000) > new Date()) {
        if (tokenData.userInfo.hasOwnProperty('ID')) {
          setToken({ authToken: token })
          return true
        } else {
          localStorage.clear()
          return false
        }
      } else {
        localStorage.clear()
        return false
      }
    }
  }
  const userLoggedIn = validateToken()

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          {/* {!userLoggedIn && */}
          <Fragment>
            <Route path="*" element={<NanoWebsiteView />} />
            {/* <Route path="/" element={<NanoWebsiteView />} /> */}
            <Route path="/:BID" element={<NanoWebsiteView />} />
            <Route path="introduction" element={<IntroductionView />} />
            <Route path="register" element={<RegisterView />} />
            <Route path="otp" element={<OtpView />} />
            <Route path="appinfo" element={<AboutApp />} />
            <Route path="map" element={<MapView />} />
            {/* <Route path="landHome" element={<LandHome />} /> */}

            <Route path="load" element={<SelectWebsiteList />} />
          </Fragment>
          {/* // }
        // {userLoggedIn && */}
          {/* <Fragment>
            <Route path="/" element={<NanoWebsiteView />} />
            <Route path="/:BID" element={<NanoWebsiteView />} />
            <Route path="appinfo" element={<AboutApp />} />
            <Route path="load" element={<SelectWebsiteList />} />
            <Route path="introduction" element={<IntroductionView />} />
            <Route path="register" element={<RegisterView />} />
            <Route path="otp" element={<OtpView />} />
            <Route path="*" element={<Auth />} />
          </Fragment> */}
          {/* // } */}
        </Routes>
      </BrowserRouter>
    </div>
  )
}

function Auth() {
  return (
    <div className="App pt-5">
      <Authentication></Authentication>
    </div>
  )
}

function SelectWebsiteList() {
  return (
    <div className="App">
      <Load></Load>
    </div>
  )
}

function AboutApp() {
  return (
    <div className="App">
      {/* <Layout> */}
      <Header></Header>
      <AppInfo></AppInfo>
      <Footer></Footer>
      {/* </Layout> */}
    </div>
  )
}

function SummaryView() {
  return (
    <div className="App">
      <Layout>
        <Summary></Summary>
      </Layout>
    </div>
  )
}

function MeetView() {
  return (
    <div className="App">
      <Layout>
        <Meet />
      </Layout>
    </div>
  )
}

function CategoriesView() {
  return (
    <div className="App">
      <Layout>
        <Categories />
      </Layout>
    </div>
  )
}
function ManageView() {
  return (
    <div className="App">
      <Layout>
        <Manage></Manage>
      </Layout>
    </div>
  )
}

function MessagesView() {
  return (
    <div className="App">
      <Layout>
        <Messages />
      </Layout>
    </div>
  )
}

function BusinessesView() {
  return (
    <div className="App">
      <Layout>
        <Businesses />
      </Layout>
    </div>
  )
}

function HomeView() {
  return (
    <div className="App">
      <Layout>
        <Home></Home>
      </Layout>
    </div>
  )
}

function Home2View() {
  return (
    <div className="App">
      <Layout>
        <Home2></Home2>
      </Layout>
    </div>
  )
}

function FindView() {
  return (
    <div className="App">
      <Layout>
        <Find></Find>
      </Layout>
    </div>
  )
}

function QuickMeetView() {
  return (
    <div className="App">
      {/* <Layout> */}
      <QuickMeet></QuickMeet>
      {/* </Layout> */}
    </div>
  )
}

function NanoWebsiteView() {
  return (
    <div className="App">
      <NanoWebsite />
      {/* <AuthModal /> */}
      <MyBusinessModal />
      <EditBusinessOffcanvas />
      {/* <ChatThreadViewOffcanvas /> */}
    </div>
  )
}

// function LandHomeView() {
//   return (
//     <div className="App">
//       {/* <AuthModal /> */}
//       <LandHome />

//       {/* <ChatThreadViewOffcanvas /> */}
//     </div>
//   )
// }

function UserProfileView() {
  return (
    <div className="App">
      <Layout>
        <UserProfile />
      </Layout>
    </div>
  )
}

function MoreDetailsView() {
  return (
    <div className="App">
      <Layout>
        <MoreDetails />
      </Layout>
    </div>
  )
}

function SearchView() {
  return (
    <div className="App">
      <Layout>
        <Search />
      </Layout>
    </div>
  )
}

function IntroductionView() {
  return (
    <div className="App">
      <Introduction />
    </div>
  )
}

function RegisterView() {
  return (
    <div className="App">
      <Register />
    </div>
  )
}

function OtpView() {
  return (
    <div className="App">
      <OTP />
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { authToken } = auth

  return {
    authToken
  }
}

const mapDispatchToProps = {
  setToken,
  showLoginPopup,
  hideLoginPopup
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
