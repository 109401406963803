import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Authentication from '../../Pages/Authentication';
import { showAuthModal, hideAuthModal } from '../../../features/auth/authSlice';

const AuthModal = ({ 
  authToken,
  showAuthModal,
  hideAuthModal,
  authModalVisible
}) => {

    useEffect(() => {
      if(authToken == null) {
        showAuthModal({authModalVisible: true})
      } else {
        hideAuthModal()
      }
    }, [authToken])
    useEffect(() => {
      setTimeout(() => {
        if (document.querySelectorAll('.fade.offcanvas-backdrop.show').length > 1) {
          document.querySelectorAll('.offcanvas-backdrop.fade.show')[1].style.display = 'none'
          document.querySelectorAll('.offcanvas-backdrop.fade.show')[1].remove()
        }
      }, 2500);
      // if (authModalVisible) {
      //   // document.querySelectorAll('.offcanvas-backdrop.fade.show').forEach(el => el.style.display = 'block')
      // } else {
      // }
    }, [authModalVisible])
  return (
    <>
      <Offcanvas placement={'bottom'} backdrop={true} show={authModalVisible} style={{transition: '0.2s ease-in-out', height: 'auto', maxWidth: '358px', margin: '0 auto', borderTopLeftRadius: '25px', borderTopRightRadius: '25px'}} onHide={() => hideAuthModal()}>
          <Offcanvas.Body>
              <Authentication />
          </Offcanvas.Body>
      </Offcanvas>
    </>
  )
}
const mapStateToProps = ({ auth }) => {
    const {
      authToken,
      authModalVisible
    } = auth
    return {
      authToken,
      authModalVisible
    }
  }
  
  const mapDispatchToProps = {
    showAuthModal,
    hideAuthModal
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(AuthModal)