import React, { useEffect, useRef, useState } from 'react';

export const getContrastYIQ = (hexColor) => {
  hexColor = hexColor.replace("#", "");
  const r = parseInt(hexColor.substring(0, 2), 16);
  const g = parseInt(hexColor.substring(2, 4), 16);
  const b = parseInt(hexColor.substring(4, 6), 16);
  const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return (yiq >= 128) ? '#000000' : '#FFFFFF';
};

function TextualComp({ text = 'Title', bgColor = "#dbcdf0", onClick = () => {} }) {
  const containerRef = useRef(null);
  const [fontSize, setFontSize] = useState(16);
  const [textColor, setTextColor] = useState('#000');

  useEffect(() => {
    const container = containerRef.current;

    if (container) {
      const { offsetWidth, offsetHeight } = container;

      // Calculate initial font size based on the container size
      let baseFontSize = Math.min(offsetWidth, offsetHeight) / 5;
      
      // Adjust the font size based on the length of the text
      const adjustedFontSize = baseFontSize * (10 / text.length);
      
      // Set the font size, ensuring it doesn't become too small
      if(adjustedFontSize < 13){
          setFontSize(Math.max(adjustedFontSize, 13));
      } else {
          setFontSize(Math.min(adjustedFontSize, 20));
      }

      // Determine the text color based on background color contrast
      const contrastColor = getContrastYIQ(bgColor);
      setTextColor(contrastColor);
    }
  }, [bgColor, text]);

  // Function to calculate contrast color (black or white) based on background color


  return (
    <div ref={containerRef} className='innerTextTextualComp' onClick={() => onClick()} style={{ backgroundColor: bgColor }}>
      <span style={{ fontSize: `${fontSize}px`, color: textColor }}>{text}</span>
    </div>
  );
}

export default TextualComp;
